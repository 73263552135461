import API from "../api";
import AuthService from "./auth";

class ReportsService {
  getQuestionsReport(filters) {
    return API.get("v1/question-report/", {
      headers: AuthService.headers,
      params: filters,
    });
  }
  getTimeReport(filters) {
    return API.get("v1/gametime-report/", {
      headers: AuthService.headers,
      params: filters,
    });
  }
  getUsersReport(filters) {
    return API.get("v1/users-report/", {
      headers: AuthService.headers,
      params: filters,
    });
  }
}

export default new ReportsService();
