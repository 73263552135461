import API from "../api";
import AuthService from "./auth";

class StatsService {
  getStats() {
    return API.get("v1/stats/", { headers: AuthService.headers });
  }
  getRanking() {
    return API.get("v1/rankings/");
  }
}
export default new StatsService();
