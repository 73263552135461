import React from "react";
import FacebookLogin from "react-facebook-login";
import facebookIcon from "../assets/images/facebookIcon.svg";
import GoogleLogo from "../assets/images/logoGoogle.svg";
import TwitterLogo from "../assets/images/twitterLogo.svg";

import GoogleLogin from "react-google-login";
import secrets from "../secrets.json";
import TwitterLogin from "react-twitter-login";
import FocoLogo from "../assets/images/focoLogo.png";

const LoginWithFOCO = (props) => {
  return (
    <button className="foco-button" onClick={props.focoLogin}>
      <img src={FocoLogo} alt="FOCO" height="20"></img>
      Entrar con Keycloak
    </button>
  );
};

const LoginWithSocialMedia = (props) => (
  <div className="social-media-login-container">
    <GoogleLogin
      clientId={secrets.google_client_id}
      onSuccess={props.responseGoogle}
      render={(renderProps) => (
        <button
          className="google-button"
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <img height="20" src={GoogleLogo} alt="google-logo" />
          ENTRAR CON GOOGLE
        </button>
      )}
    />
    <FacebookLogin
      appId={secrets.facebook_client_id}
      // autoLoad={true}
      fields="name,email,picture"
      textButton="ENTRAR CON FACEBOOK"
      cssClass="my-facebook-button-class"
      callback={props.responseFacebook}
      icon={<img height="20" src={facebookIcon} alt="facebook-logo" />}
    />
    {props.responseTwitter && (
      <TwitterLogin
        authCallback={props.responseTwitter}
        consumerKey={secrets.twitter_consumer_key}
        consumerSecret={secrets.twitter_secret_key}
        children={
          <div className="custom-twitter-login ">
            <img src={TwitterLogo} />
            ENTRAR CON TWITTER
          </div>
        }
      />
    )}
    {/*props.showKeycloak ? (
      <LoginWithFOCO focoLogin={props.focoLogin}></LoginWithFOCO>
    ) : null*/}
  </div>
);

export default LoginWithSocialMedia;
