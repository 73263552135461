import React, { Component } from "react";
import Select from "react-select";
// import userData from './userData.json';
import AuthService from "../../services/auth";
import ProfileService from "../../services/profile";
import SoundService from "../../services/sound";
import { CSSTransition } from "react-transition-group";

// images
import profilePicture from "../../assets/images/profile-picture.png";
import settings from "../../assets/images/interface/settings.svg";
import close from "../../assets/images/interface/closeModal.svg";
import shareIcon from "../../assets/images/modulos/share.svg";
import medalBronze from "../../assets/images/medalBronze.svg";
import medalSilver from "../../assets/images/medalSilver.svg";
import medalGold from "../../assets/images/medalGold.svg";
class Profile extends Component {
  state = {
    activeTab: "data",
    firstName: "",
    lastName: "",
    loading: true,
    avatarSelector: false,
    myAvatar: profilePicture,
    settings: false,
    loadingSettings: false,
    medals: [],
    currentDistricts: [],
    currentSchools: [],
    // select inputs
    institution: null,
    district: null,
  };

  userData = {}; // data to showw

  userObject = {}; // data to complete payload

  userRef = {
    first_name: React.createRef(),
    last_name: React.createRef(),
    birth_date: React.createRef(),
    sex: React.createRef(),
    community_role: React.createRef(),
    // institution: null, // uses react-select, it sets on change event handler
    grade: React.createRef(),
    department: React.createRef(),
    // district: null, // uses react-select, it sets on change event handler
  }; // inputs

  avatars = []; // all avatars (to select)

  // arrays of requested data options
  roles;
  schools;
  grades;
  districts;
  departments;

  birthDateMax;

  openTab(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  logout() {
    SoundService.playTab();
    AuthService.logout();
    this.props.history.replace("/");
  }

  openChangePassword() {
    SoundService.playTab();
    this.props.history.push("/changePassword");
  }

  componentDidMount() {
    const date = new Date();
    this.birthDateMax = `${date.getFullYear() - 8}-${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
    ProfileService.getData().then(
      (res) => {
        // creates User Object, just for payload on profile changes to backend
        this.userObject = {
          id: res.data.id,
          email: res.data.email,
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          profile: {
            id: res.data.profile.id,
            birth_date: res.data.profile.birth_date,
            sex: res.data.profile.sex,
            has_accepted_terms: true,
            user: res.data.profile.user,
            community_role: res.data.profile.community_role,
            institution: res.data.profile.institution,
            grade: res.data.profile.grade,
            avatar: res.data.profile.avatar,
            department: res.data.profile.department,
            district: res.data.profile.district,
          },
        };
        // removing serializations
        this.userObject.profile.community_role = this.userObject.profile
          .community_role
          ? this.userObject.profile.community_role.id
          : null;
        this.userObject.profile.institution = this.userObject.profile
          .institution
          ? this.userObject.profile.institution.id
          : null;
        this.userObject.profile.grade = this.userObject.profile.grade
          ? this.userObject.profile.grade.id
          : null;
        this.userObject.profile.avatar = this.userObject.profile.avatar
          ? this.userObject.profile.avatar.id
          : null;
        this.userObject.profile.department = this.userObject.profile.department
          ? this.userObject.profile.department.id
          : null;
        this.userObject.profile.district = this.userObject.profile.district
          ? this.userObject.profile.district.id
          : null;

        this.setState({
          institution: res.data.profile.institution
            ? {
                value: res.data.profile.institution.id,
                label: res.data.profile.institution.name,
                district: res.data.profile.institution.district,
              }
            : null,
          district: res.data.profile.district
            ? {
                value: res.data.profile.district.id,
                label: res.data.profile.district.name,
                department: res.data.profile.district.department,
              }
            : null,
        });

        // getting data from api
        ProfileService.getAchievements().then((a) => {
          this.userData = {
            data: [
              {
                title: "Correo Electrónico",
                body: res.data.email,
              },
              {
                title: "Departamento",
                body: res.data.profile.department
                  ? res.data.profile.department.name
                  : "No seleccionado",
              },
              {
                title: "Distrito",
                body: res.data.profile.district
                  ? res.data.profile.district.name
                  : "No seleccionado",
              },
              {
                title: "Institución Educativa",
                body: res.data.profile.institution
                  ? res.data.profile.institution.name
                  : "Ninguna",
              },
              {
                title: "Rol",
                body: res.data.profile.community_role
                  ? res.data.profile.community_role.name
                  : "Otro",
              },
              {
                title: "Edad",
                body: `${this.getAge(res.data.profile.birth_date)} años`,
              },
              {
                title: "Género",
                body: this.getSex(res.data.profile.sex),
              },
            ],
            achievements: [
              {
                title: "Puntos totales",
                body: a.data.total_points,
              },
              {
                title: "Ranking global",
                body: `${a.data.global_ranking} de ${a.data.total_rankings_users}`,
              },
              {
                title: "Ranking institución",
                body: `${a.data.institution_ranking} de ${a.data.total_rankings_institution}`,
              },
              {
                title: "Módulos superados",
                body: a.data.passed_levels,
              },
            ],
            selectedAvatar: res.data.profile.avatar
              ? res.data.profile.avatar.image
              : profilePicture, // id del avatar
          };

          this.setState({
            firstName: res.data.first_name,
            lastName: res.data.last_name,
            medals: a.data.medals,
          });
          this.setState({ loading: false });
        });
        // data to show
        ProfileService.getAvatars().then((avts) => {
          this.avatars = avts.data;
        });
        ProfileService.getUtils().then((utils) => {
          this.roles = utils.data.community_roles;
          this.grades = utils.data.grades;
          this.departments = utils.data.departments;
          this.districts = utils.data.districts.map((i) => {
            return {
              value: i.id,
              label: i.name,
              department: i.department,
            };
          });
          // getting only districts inside user department
          if (this.userObject.profile.department) {
            this.setState({
              currentDistricts: this.districts.filter(
                (dis) => dis.department === this.userObject.profile.department
              ),
            });
          }
        });
        ProfileService.getInstitutions().then((insts) => {
          this.schools = insts.data.map((i) => {
            return {
              value: i.id,
              label: i.name,
              district: i.district,
            };
          });
          this.schools.push({
            district: 0,
            value: 0,
            label: "Ninguna",
          });
          // getting only schools inside user district
          if (this.userObject.profile.district) {
            this.setState({
              currentSchools: this.schools.filter(
                (sch) =>
                  sch.value === 0 ||
                  sch.district === this.userObject.profile.district
              ),
            });
          } else {
            this.setState({
              currentSchools: this.schools.filter((sch) => sch.value === 0),
            });
          }
        });
      },
      (error) => {
        console.log(error);
        this.setState({ loading: false });
      }
    );
  }

  getAge(date) {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  getSex(sexChar) {
    switch (sexChar) {
      case "m":
        return "Hombre";
      case "f":
        return "Mujer";
      case "x":
        return "Preferí no responder";
      default:
        return "Desconocido";
    }
  }

  triggerAvatars() {
    SoundService.playTab();
    this.setState({ avatarSelector: !this.state.avatarSelector });
    if (!this.state.avatarSelector) {
      ProfileService.getAvatars().then((avtrs) => {
        this.avatars = avtrs.data;
      });
    }
  }

  triggerSettings() {
    SoundService.playTab();
    this.setState({ settings: !this.state.settings });
  }

  updateProfile() {
    SoundService.playTab();
    this.setState({ loadingSettings: true });
    const payload = {
      id: this.userObject.id,
      email: this.userObject.email,
      first_name: this.userRef.first_name.current.value,
      last_name: this.userRef.last_name.current.value,
      profile: {
        id: this.userObject.profile.id,
        birth_date: this.userRef.birth_date.current.value,
        sex: this.userRef.sex.current.value,
        has_accepted_terms: true,
        user: this.userObject.profile.user,
        community_role:
          this.userRef.community_role.current.value !== "0"
            ? this.userRef.community_role.current.value
            : null,
        institution:
          this.state.institution?.value !== 0
            ? this.state.institution.value
            : null,
        grade:
          this.userRef.grade.current.value !== "0"
            ? this.userRef.grade.current.value
            : null,
        avatar: this.userObject.profile.avatar,
        department: this.userRef.department.current.value,
        district: this.state.district ? this.state.district.value : null,
      },
    };
    //console.log(payload);
    ProfileService.putProfile(payload).then((res) => {
      this.userObject = { ...res.data };
      this.setState({ settings: false, loadingSettings: false });
      //console.log(this.userObject);
      // changes data to show
      // departments
      const currDepartment = this.departments.find(
        (d) => d.id === this.userObject.profile.department
      );
      //console.log(currDepartment);
      this.userData.data[1].body = currDepartment.name;
      // districts
      this.userData.data[2].body = this.state.district.label;
      // school
      this.userData.data[3].body = this.state.institution
        ? this.state.institution.label
        : "Ninguna";
      // role
      const currRole = this.roles.find(
        (r) => r.id === this.userObject.profile.community_role
      );
      this.userData.data[4].body = currRole ? currRole.name : "Otro";

      // age
      this.userData.data[5].body = this.getAge(
        this.userObject.profile.birth_date
      );

      // sex
      this.userData.data[6].body = this.getSex(this.userObject.profile.sex);

      // names
      this.setState({
        firstName: this.userObject.first_name,
        lastName: this.userObject.last_name,
      });
    });
  }

  // on department change
  updateDistrictList(event) {
    const value = +event.target.value;

    // getting new districts array
    let disArray = this.districts.slice(0);
    disArray = disArray.filter((dis) => +dis.department === value);

    // getting new schools array
    let schoArray = this.schools.slice(0);
    if (disArray[0]) {
      schoArray = schoArray.filter(
        (sch) => sch.district === 0 || sch.district === disArray[0].value
      );
    } else {
      schoArray = schoArray.filter((sch) => sch.district === 0);
    }

    // changing department, updating district and schools list, setting district input and setting to "ninguna" institution
    this.setState({
      currentDistricts: disArray,
      currentSchools: schoArray,
      district: disArray[0] ? disArray[0] : null,
      institution: schoArray.find((sch) => sch.value === 0),
    });
  }

  // on district change
  handleSelectChangeDistrict(selected) {
    // this trigger even if you select the same district, avoid script in that case
    if (this.state.district.value !== selected.value) {
      // getting new schools array
      let schoArray = this.schools.slice(0);
      schoArray = schoArray.filter(
        (sch) => sch.district === 0 || sch.district === +selected.value
      );

      // changing district, updating schools list and setting institution input
      this.setState({
        currentSchools: schoArray,
        district: selected,
        institution: schoArray.find((sch) => sch.value === 0),
      });
    }
  }

  // on institution (school) change
  handleSelectChange(selected) {
    this.setState({
      institution: selected,
    });
  }

  medals() {
    if (this.state.activeTab === "data" || this.state.medals.length === 0)
      return <React.Fragment />;
    const height_medal = "40px";
    const bronze = (
      <div className="medal-container">
        <img src={medalBronze} height={height_medal} />
        <div className="text-medal bronze">Bronce</div>
      </div>
    );
    const silver = (
      <div className="medal-container">
        <img src={medalSilver} height={height_medal} />
        <div className="text-medal silver">Plata</div>
      </div>
    );
    const gold = (
      <div className="medal-container">
        <img src={medalGold} height={height_medal} />
        <div className="text-medal gold">Oro</div>
      </div>
    );
    return (
      <div className="profile-item">
        <div className={"profile-item-title pit-" + this.state.activeTab}>
          Niveles completados:
        </div>
        <div className="profile-item-body medals-profile">
          {this.state.medals.includes("Básico") && bronze}
          {this.state.medals.includes("Intermedio") && silver}
          {this.state.medals.includes("Avanzado") && gold}
        </div>
      </div>
    );
  }
  tabContent() {
    const items =
      this.state.activeTab === "data"
        ? this.userData.data
        : this.userData.achievements;
    return (
      <div className="profile-content">
        {this.medals()}

        {items.map((i) => (
          <div className="profile-item">
            <div className={"profile-item-title pit-" + this.state.activeTab}>
              {i.title}
            </div>
            <div className="profile-item-body">{i.body}</div>
          </div>
        ))}
        <div>
          {this.state.activeTab !== "data" && (
            <img
              src={shareIcon}
              className="share-button"
              onClick={() => this.props.history.push("/share")}
            />
          )}
        </div>
      </div>
    );
  }

  overlay() {
    return <div className="overlay" onClick={() => this.triggerAvatars()} />;
  }

  overlaySettings() {
    return <div className="overlay" onClick={() => this.triggerSettings()} />;
  }

  spinner(color) {
    if (color === "1") {
      return (
        <div style={{ height: "37px", marginTop: "26px" }}>
          <div style={{ top: "742px" }} className={"spinner1 color-" + color}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      );
    }
    return (
      <div className={"spinner1 color-" + color}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }

  setAvatar(avatarObj) {
    if (this.userObject.profile.avatar === avatarObj.id) {
      console.log("Avatar ya seleccionado");
    } else {
      SoundService.playTab();
      this.userData.selectedAvatar = avatarObj.image;
      this.userObject.profile.avatar = avatarObj.id;
      ProfileService.putProfile(this.userObject).then((res) => {
        console.log("Perfil actualizado: ", res.data);
      });
      this.setState({ avatarSelector: false });
    }
  }

  avatarSelector() {
    return (
      <CSSTransition
        in={this.state.avatarSelector}
        timeout={150}
        classNames="modalTransition"
        unmountOnExit
      >
        <div className="profile-avatars">
          <div className="pa-title">Seleccione un avatar</div>
          <img
            height="25px"
            onClick={() => this.triggerAvatars()}
            className="pa-closeButton"
            src={close}
            alt="x"
          />
          <div className="pa-avatars-scroll">
            {this.avatars.map((a) => (
              <div className="pa-avatar-container">
                <img
                  onClick={() => this.setAvatar(a)}
                  className={
                    "pa-avatar" +
                    (a.image === this.userData.selectedAvatar
                      ? " pa-selected"
                      : "")
                  }
                  src={a.image}
                  alt={a.id}
                />
              </div>
            ))}
          </div>
        </div>
      </CSSTransition>
    );
  }

  settingsModal() {
    return (
      <CSSTransition
        in={this.state.settings}
        timeout={150}
        classNames="modalTransition"
        unmountOnExit
      >
        <div className="profile-settings">
          <div className="pa-title">Editar Perfil</div>
          <img
            height="25px"
            onClick={() => this.triggerSettings()}
            className="pa-closeButton"
            src={close}
            alt="x"
          />
          <div className="ps-scroll profile-settings-margin">
            <span
              onClick={() => this.openChangePassword()}
              className="ps-changePass"
            >
              Cambiar contraseña
            </span>
            <div className="profile-item-title">Nombre</div>
            <input
              ref={this.userRef.first_name}
              defaultValue={this.userObject.first_name}
              className="ps-input"
              placeholder="Nombre"
              spellCheck="false"
            ></input>
            <div className="profile-item-title">Apellido</div>
            <input
              ref={this.userRef.last_name}
              defaultValue={this.userObject.last_name}
              className="ps-input"
              placeholder="Nombre"
              spellCheck="false"
            ></input>
            <div className="profile-item-title">Fecha de Nacimiento</div>
            <input
              ref={this.userRef.birth_date}
              defaultValue={this.userObject.profile.birth_date}
              className="ps-input"
              type="date"
              max={this.birthDateMax}
            ></input>
            <div className="profile-item-title">Género</div>
            <select
              ref={this.userRef.sex}
              defaultValue={this.userObject.profile.sex}
              className="ps-input"
            >
              <option className="input-option" value="m">
                Hombre
              </option>
              <option className="input-option" value="f">
                Mujer
              </option>
              <option className="input-option" value="x">
                Prefiero no responder
              </option>
            </select>

            <div className="profile-item-title">Departamento</div>
            <select
              ref={this.userRef.department}
              defaultValue={this.userObject.profile.department}
              onChange={(event) => this.updateDistrictList(event)}
              className="ps-input"
            >
              {this.departments.map((dep) => (
                <option className="input-option" value={dep.id}>
                  {dep.name}
                </option>
              ))}
            </select>

            <div className="profile-item-title">Distrito</div>
            <Select
              options={this.state.currentDistricts}
              value={this.state.district}
              onChange={(selected) => this.handleSelectChangeDistrict(selected)}
              className="ps-select"
              classNamePrefix="ps-select"
              placeholder="Busca tu distrito"
              noOptionsMessage={() => "Distrito no encontrada"}
            ></Select>

            <div className="profile-item-title">Institución Educativa</div>
            <Select
              options={this.state.currentSchools}
              value={this.state.institution}
              onChange={(selected) => this.handleSelectChange(selected)}
              className="ps-select"
              classNamePrefix="ps-select"
              placeholder="Busca tu institución"
              noOptionsMessage={() => "Institución no encontrada"}
            ></Select>

            <div className="profile-item-title">Rol</div>
            <select
              ref={this.userRef.community_role}
              defaultValue={this.userObject.profile.community_role}
              className="ps-input"
            >
              <option className="input-option" value="0">
                Otro
              </option>
              {this.roles.map((r) => (
                <option className="input-option" value={r.id}>
                  {r.name}
                </option>
              ))}
            </select>
            <div className="profile-item-title">Grado</div>
            <select
              ref={this.userRef.grade}
              defaultValue={this.userObject.profile.grade}
              className="ps-input"
            >
              <option className="input-option" value="0">
                Ninguno
              </option>
              {this.grades.map((r) => (
                <option className="input-option" value={r.id}>
                  {r.name}
                </option>
              ))}
            </select>
          </div>
          <div className="ps-button-container">
            {!this.state.loadingSettings ? (
              <button
                onClick={() => this.updateProfile()}
                className="ps-button"
              >
                Guardar
              </button>
            ) : (
              this.spinner("1")
            )}
          </div>
        </div>
      </CSSTransition>
    );
  }

  render() {
    return (
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
        }}
      >
        <div>
          {!this.state.loading ? (
            <div>
              <div className="profile-header">
                <div className="ph-avatar-container">
                  <img
                    onClick={() => this.triggerAvatars()}
                    className="ph-avatar"
                    src={this.userData.selectedAvatar}
                    alt="profile"
                  ></img>
                </div>
                <div className="ph-details">
                  {this.state.firstName + " " + this.state.lastName}
                  <div className="ph-details-buttons">
                    <div className="ph-button-content">
                      <button
                        onClick={() => this.logout()}
                        className="ph-button"
                      >
                        Cerrar sesión
                      </button>
                    </div>
                    <img
                      onClick={() => this.triggerSettings()}
                      className="ph-settings"
                      src={settings}
                      alt="settings"
                    ></img>
                  </div>
                </div>
              </div>
              <div
                onClick={() => this.openTab("data")}
                className={
                  "profile-tabs " +
                  (this.state.activeTab === "data" ? "pt-active" : "")
                }
              >
                datos
              </div>
              <div
                onClick={() => this.openTab("achievements")}
                className={
                  "profile-tabs " +
                  (this.state.activeTab === "achievements" ? "pt-active" : "")
                }
              >
                logros
              </div>
              {this.tabContent()}
            </div>
          ) : (
            this.spinner("default")
          )}
          {this.avatarSelector()}
          {this.state.avatarSelector ? this.overlay() : null}
          {this.userObject.profile &&
          this.roles &&
          this.schools &&
          this.grades &&
          this.departments &&
          this.districts
            ? this.settingsModal()
            : null}
          {this.state.settings ? this.overlaySettings() : null}
        </div>
      </div>
    );
  }
}
export default Profile;
