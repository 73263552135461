import React, { Component, useState } from "react";
import AuthService from "../../services/auth";
import "./landing.css";
import "../../spinners.css";
import SoundService from "../../services/sound";

import LoginWithSocialMedia from "../../components/loginWithSocialMedia";
import secrets from "../../secrets.json";
// import TwitterLogin from "react-twitter-login";
import "../../transitions.css";

class LoginPageDashboard extends Component {
  state = {
    loadingLogin: false,
    statusMessage: null,
    showPassword: false,
    showFocoModal: false,
    focoEmail: "",
    focoPassword: "",
    focoStatusMessage: null,
  };

  email = React.createRef();
  password = React.createRef();
  goBack = (route) => {
    SoundService.playTab();
    this.props.history.goBack();
  };

  goToRegister = (route) => {
    SoundService.playTab();
    this.props.history.push("/register");
  };

  goToHome = (route) => {
    SoundService.playTab();
    this.props.history.push("/home");
  };

  closeModal() {
    this.setState({ statusMessage: null });
  }

  closeErrorFocoModal() {
    this.setState({ focoStatusMessage: null });
  }
  responseFoco = async () => {
    /*AuthService.focoLogin().then((res) => {
      console.log(res);
      this.setState({
        showFocoModal: ReactHtmlParser(res.data),
      });
    });*/
    //this.setState({ loadingLogin: true });
    const focoRes = await AuthService.loginWithFoco(
      this.state.focoEmail,
      this.state.focoPassword
    ).catch((error) => {
      switch (error.response?.status) {
        case 400:
          this.setState({
            focoStatusMessage: "El usuario y/o la contraseña son incorrectos",
          });
          break;
        case 401:
          this.setState({
            focoStatusMessage: "El usuario y/o la contraseña son incorrectos",
          });
          break;
        case 404:
          this.setState({
            statusMessage:
              "Servidor no encontrado, por favor, intente más adelante",
          });
          break;
        case 500:
          this.setState({
            focoStatusMessage:
              "Error en el servidor, por favor, intente más adelante",
          });
          break;
        default:
          this.setState({
            focoStatusMessage: "Un error ha ocurrido",
          });
      }
    });
    const focoToken = focoRes?.data.access_token;
    await AuthService.doConvertTokenFOCO(focoToken).then((res) => {
      if (res.statusText === "OK") {
        AuthService.setToken(res.data.access_token);
        AuthService.setRefreshToken(res.data.refresh_token);
        this.props.history.push("/stats");
      } else {
        this.setState({
          focoStatusMessage: "Un error ha ocurrido",
        });
      }
    });
  };

  responseGoogle = (googleRes) => {
    this.setState({ loadingLogin: true });
    const googleToken = googleRes.accessToken;
    AuthService.doConvertToken(googleToken).then((res) => {
      if (res.statusText === "OK") {
        AuthService.setToken(res.data.access_token);
        AuthService.setRefreshToken(res.data.refresh_token);
        this.props.history.push("/stats");
      }
    });
  };
  responseTwitter(err, data) {
    if (data) {
      const oauth_token = data.oauth_token;
      const oauth_token_secret = data.oauth_token_secret;
      AuthService.doConvertTokenTW(oauth_token, oauth_token_secret).then(
        (res) => {
          if (res.statusText === "OK") {
            AuthService.setToken(res.data.access_token);
            AuthService.setRefreshToken(res.data.refresh_token);
            // this.props.history.push("/home");
            window.location.href = secrets.url + "/home";
          }
        }
      );
    }
  }
  responseFacebook = (response) => {
    const fbToken = response.accessToken;
    AuthService.doConvertTokenFB(fbToken).then((res) => {
      if (res.statusText === "OK") {
        AuthService.setToken(res.data.access_token);
        AuthService.setRefreshToken(res.data.refresh_token);
        this.props.history.push("/stats");
      }
    });
  };
  login = async (e) => {
    e.preventDefault();
    const focoRes = await AuthService.loginWithFoco(
      this.state.focoEmail,
      this.state.focoPassword
    ).catch((error) => {
      switch (error.response?.status) {
        case 400:
          this.setState({
            focoStatusMessage: "El usuario y/o la contraseña son incorrectos",
          });
          break;
        case 401:
          this.setState({
            focoStatusMessage: "El usuario y/o la contraseña son incorrectos",
          });
          break;
        case 404:
          this.setState({
            statusMessage:
              "Servidor no encontrado, por favor, intente más adelante",
          });
          break;
        case 500:
          this.setState({
            focoStatusMessage:
              "Error en el servidor, por favor, intente más adelante",
          });
          break;
        default:
          this.setState({
            focoStatusMessage: "Un error ha ocurrido",
          });
      }
    });
    const focoToken = focoRes?.data.access_token;
    await AuthService.doConvertTokenFOCO(focoToken).then((res) => {
      if (res.statusText === "OK") {
        AuthService.setToken(res.data.access_token);
        AuthService.setRefreshToken(res.data.refresh_token);
        this.props.history.push("/stats");
      } else {
        this.setState({
          focoStatusMessage:
            "Error desconocido, por favor, intente más adelante",
        });
      }
    });
  };

  spinner() {
    return (
      <div style={{ height: "58px" }}>
        <div style={{ top: "388px" }} className="spinner1 color-2">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  errorModal() {
    return (
      <div className="login-error-modal">
        {this.state.statusMessage}
        <div style={{ marginTop: "20px" }}>
          <button
            onClick={() => this.closeModal()}
            className="outline-button black-button"
          >
            Cerrar
          </button>
        </div>
      </div>
    );
  }

  togglePassword() {
    //console.log("Entre");
    this.setState({ showPassword: !this.state.showPassword });
  }
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "100%",
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            boxSizing: "border-box",
            height: 72,
            width: "100%",
            backgroundColor: "#00a3ff",
            boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.1)",
            //fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: 800,
            fontSize: "27px",
            lineHeight: "32px",
            color: "#ffffff",
            padding: "20px 0px 0px 102px",
          }}
        >
          LaberINFO
        </div>
        <form
          style={{
            maxWidth: 500,
            margin: "auto",
            marginTop: "8%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onSubmit={this.login}
        >
          <div
            style={{
              //fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "30px",
              lineHeight: "35px",
              textAlign: "center",
              color: "#00a3ff",
              marginBottom: 20,
            }}
          >
            Ingresar a la cuenta
          </div>
          <div
            style={{
              margin: "0 auto 20px",
            }}
          >
            <LoginWithSocialMedia
              responseGoogle={this.responseGoogle}
              responseFacebook={this.responseFacebook}
              responseTwitter={null}
              focoLogin={() =>
                this.setState({
                  showFocoModal: true,
                })
              }
              showKeycloak={true}
            />
          </div>
          <input
            style={{
              padding: "0px 27px",
              background: "#eff7fb",
              border: "none",
              borderRadius: "20px",
              width: 469,
              height: 49,
              //fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "17px",
              lineHeight: "22px",
              color: "#51c0ff",
              marginBottom: 14,
            }}
            className="input-login"
            type="username"
            name="username"
            placeholder="Nombre de Cuenta"
            onChange={(e) => this.setState({ focoEmail: e.target.value })}
          />
          <input
            style={{
              padding: "0px 27px",
              background: "#eff7fb",
              border: "none",
              borderRadius: "20px",
              width: 469,
              height: 49,
              //fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "17px",
              lineHeight: "22px",
              color: "#51c0ff",
              marginBottom: 14,
            }}
            className="input-login"
            type="password"
            name="password"
            placeholder="Contraseña"
            onChange={(e) => this.setState({ focoPassword: e.target.value })}
          />

          <div
            style={{
              width: "100%",
              marginTop: "0.25rem",
              fontSize: "80%",
              color: "#dc3545",
              textAlign: "center",
              fontSize: "1rem",
              marginBottom: 5,
            }}
          >
            {this.state.focoStatusMessage ? this.state.focoStatusMessage : null}
          </div>
          <button
            style={{
              outline: "none",
              border: "none",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "14px 46px",
              background: "#ff7236",
              width: 159,
              height: 45,
              boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",

              //fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "18px",
              lineHeight: "21px",

              color: "#ffffff",
            }}
            type="submit"
          >
            Ingresar
          </button>
        </form>
      </div>
    );
  }
}
export default LoginPageDashboard;
