import API from "../api";

class ProfileService {
  getData() {
    return API.get("v1/users/me/");
  }

  getUsers() {
    return API.get("v1/users/");
  }

  getProfiles() {
    return API.get("v1/profiles/");
  }

  createProfile(payload) {
    return API.post("v1/users/", payload);
  }

  updateProfile(payload) {
    // creates user Profile object
    return API.post("v1/profiles/", payload);
  }

  getUtils() {
    // it gets roles, grades,  departments and districts
    return API.get("v1/utils/");
  }

  getInstitutions(text) {
    const search = text ? `?search=${text}` : "";
    return API.get(`v1/institutions/${search}`);
  }

  getAvatars() {
    return API.get(`v1/avatars/`);
  }
  getCertificateRank() {
    return API.get(`v1/myrank/`);
  }
  putProfile(payload) {
    // real update profile
    return API.put("v1/users/me/", payload);
  }

  getAchievements() {
    return API.get("v1/achievements/");
  }
  getUserStats() {
    return API.get("v1/user-certificate/");
  }
}
export default new ProfileService();
