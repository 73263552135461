import React, { Component } from "react";
import "./landing.css";
import Parser from "html-react-parser";

import laberinfoLogo from "../../assets/images/laberinfoLogo.png";
import reaccionLogo from "../../assets/images/reaccionB.svg";
import cross from "../../assets/images/close-x-white.svg";
import checkmark from "../../assets/images/checkmark-white.svg";

import back from "../../assets/images/modulos/backBlue.svg";
import LoginWithSocialMedia from "../../components/loginWithSocialMedia";

import ProfileService from "../../services/profile";
import AuthService from "../../services/auth";
import SoundService from "../../services/sound";
import secrets from "../../secrets.json";

class RegisterPage extends Component {
  state = {
    matchedPasswords: false,
    loading: false,
    errorMessage: null,
  };

  email = React.createRef();
  pass1 = React.createRef();
  pass2 = React.createRef();
  firstName = React.createRef();
  lastName = React.createRef();

  goBack = (route) => {
    SoundService.playTab();
    this.props.history.goBack();
  };

  matchPasswords() {
    // AND CHECK IF ALL INPUTS ARE NOT BLANK
    // All camps are required
    if (
      this.email.current &&
      this.email.current.value &&
      this.pass1.current &&
      this.pass2.current &&
      this.pass1.current.value &&
      this.pass2.current.value &&
      this.firstName.current.value &&
      this.lastName.current.value
    ) {
      this.setState({
        matchedPasswords: this.pass1.current.value === this.pass2.current.value,
      });
    } else {
      this.setState({ matchedPasswords: false });
    }
  }

  closeModal() {
    this.setState({ errorMessage: null });
  }

  submit() {
    SoundService.playTab();
    this.setState({ loading: true });
    // create user Payload
    const payload = {
      email: this.email.current.value,
      first_name: this.firstName.current.value,
      last_name: this.lastName.current.value,
      password: this.pass1.current.value,
    };
    // Create User
    ProfileService.createProfile(payload).then(
      (res) => {
        // Log in with the new profile
        AuthService.login(payload.email, payload.password).then(
          (log) => {
            this.setState({ loadingLogin: false });
            AuthService.setToken(log.data.access_token);
            AuthService.setRefreshToken(log.data.refresh_token);
            this.props.history.push("/home");
          },
          (err) => {
            this.setState({ loading: false });
            console.log(err);
          }
        );
      },
      (errorCreate) => {
        // creates a msg that cocatenates a string/html to render in errorModal
        if (errorCreate.response?.status === 400) {
          let msg =
            '<h4 style="margin-top: 0px">ERROR</h4><ul style="text-align: initial">';
          if (errorCreate.response.data.email) {
            errorCreate.response.data.email.map(
              (m) => (msg = msg + "<li>" + m + "</li>")
            );
          }
          if (errorCreate.response.data.password) {
            errorCreate.response.data.password.map(
              (m) => (msg = msg + "<li>" + m + "</li>")
            );
          }
          msg = msg + "</ul>";
          this.setState({
            loading: false,
            errorMessage: msg,
          });
        } else if (errorCreate.response?.status === 500) {
          this.setState({
            loading: false,
            errorMessage:
              '<h4 style="margin-top: 0px">ERROR</h4>Error del servidor, por favor, intente más tarde',
          });
        } else {
          this.setState({
            loading: false,
            errorMessage:
              '<h4 style="margin-top: 0px">ERROR</h4>Error desconocido, por favor, intente más tarde',
          });
        }

        console.log(errorCreate.response);
      }
    );
  }

  spinner() {
    return (
      <div style={{ height: "58px" }}>
        <div style={{ top: "685px" }} className="spinner1 color-2">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  errorModal() {
    // Parser switch errorMessage string to HTML element
    return (
      <div className="login-error-modal">
        {Parser(this.state.errorMessage)}
        <div style={{ marginTop: "20px" }}>
          <button
            onClick={() => this.closeModal()}
            className="outline-button black-button"
          >
            Cerrar
          </button>
        </div>
      </div>
    );
  }

  responseGoogle = (googleRes) => {
    this.setState({ loadingLogin: true });
    const googleToken = googleRes.accessToken;
    AuthService.doConvertToken(googleToken).then((res) => {
      if (res.statusText === "OK") {
        AuthService.setToken(res.data.access_token);
        AuthService.setRefreshToken(res.data.refresh_token);
        this.props.history.push("/home");
      }
    });
  };

  responseFacebook = (response) => {
    const fbToken = response.accessToken;
    AuthService.doConvertTokenFB(fbToken).then((res) => {
      if (res.statusText === "OK") {
        AuthService.setToken(res.data.access_token);
        AuthService.setRefreshToken(res.data.refresh_token);
        this.props.history.push("/home");
      }
    });
  };
  responseTwitter(err, data) {
    if (data) {
      const oauth_token = data.oauth_token;
      const oauth_token_secret = data.oauth_token_secret;
      AuthService.doConvertTokenTW(oauth_token, oauth_token_secret).then(
        (res) => {
          if (res.statusText === "OK") {
            AuthService.setToken(res.data.access_token);
            AuthService.setRefreshToken(res.data.refresh_token);
            // this.props.history.push("/home");
            window.location.href = secrets.url + "home";
          }
        }
      );
    }
  }

  render() {
    return (
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
        }}
      >
        <div className="RegisterPage">
          <div className="logo-container">
            <img
              src={laberinfoLogo}
              height="100"
              width="auto"
              alt="laberinfo"
            ></img>
          </div>
          <div className="inputs-and-login-container">
            <LoginWithSocialMedia
              responseGoogle={this.responseGoogle}
              responseFacebook={this.responseFacebook}
              responseTwitter={null}
            />
            <img
              className="lp-back back-with-bg"
              onClick={this.goBack}
              src={back}
              alt="back"
            ></img>

            <div
              className="inputs-container"
              style={{ marginLeft: 50, marginRight: 50 }}
            >
              <input
                ref={this.email}
                onChange={() => this.matchPasswords()}
                className="landing-input input-register"
                placeholder="CORREO ELECTRONICO"
                spellCheck="false"
              ></input>
              <input
                ref={this.pass1}
                onChange={() => this.matchPasswords()}
                className="landing-input input-register"
                placeholder="CONTRASEÑA"
                type="password"
                spellCheck="false"
              ></input>
              <div style={{ display: "flex" }}>
                <input
                  ref={this.pass2}
                  onChange={() => this.matchPasswords()}
                  className="landing-input input-register"
                  placeholder="REPETIR CONTRASEÑA"
                  type="password"
                  spellCheck="false"
                ></input>
                {this.pass1.current?.value && this.pass2.current?.value && (
                  <div style={{ marginLeft: 5 }}>
                    <img
                      src={
                        this.pass1.current.value === this.pass2.current.value
                          ? checkmark
                          : cross
                      }
                      alt={
                        this.pass1.current.value === this.pass2.current.value
                          ? "y"
                          : "x"
                      }
                      height={28}
                    />
                  </div>
                )}
              </div>
              <input
                ref={this.firstName}
                onChange={() => this.matchPasswords()}
                className="landing-input input-register"
                placeholder="NOMBRES"
                spellCheck="false"
              ></input>
              <input
                ref={this.lastName}
                onChange={() => this.matchPasswords()}
                className="landing-input input-register"
                placeholder="APELLIDOS"
                spellCheck="false"
              ></input>
            </div>

            {!this.state.loading ? (
              <button
                disabled={!this.state.matchedPasswords}
                onClick={() => this.submit()}
                className={
                  "landing-button" +
                  (this.state.matchedPasswords ? "" : " lb-disabled")
                }
              >
                registrarse
              </button>
            ) : (
              this.spinner()
            )}
            <div>
              <img
                src={reaccionLogo}
                className="register-reaccion-logo"
                alt="reaccion"
              ></img>
            </div>
            {this.state.errorMessage ? this.errorModal() : null}
          </div>
        </div>
      </div>
    );
  }
}
export default RegisterPage;
