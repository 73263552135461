import React, { useEffect, useState } from "react";
import back from "../assets/images/backButton.svg";
import { useHistory } from "react-router-dom";
import "./shareCertificate.css";
import shareSocialIcon from "../assets/images/shareSocial.svg";
import downloadIcon from "../assets/images/downloadIcon.png";
import ProfileService from "../services/profile";
import medalBronze from "../assets/images/medalBronze.svg";
import medalSilver from "../assets/images/medalSilver.svg";
import medalGold from "../assets/images/medalGold.svg";
import happyMonster from "../assets/images/newHappy.svg";
import ShareSocialMediaModal from "./shareModal";
const title =
  "“¡Mirá todo lo que logré jugando LaberINFO sobre FONACIDE para infraestructura y alimentación escolar Paraguay!”";
const ShareCertificateScreen = () => {
  let history = useHistory();
  const [stats, setStats] = useState({});
  const [data, setData] = useState({});
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    setLoading(true);
    ProfileService.getUserStats().then((res) => {
      setLoading(false);

      setStats(res.data);
    });
    ProfileService.getData().then((res) => {
      setData(res.data);
    });
  }, []);
  const height_medal = "40px";
  const bronze = (
    <div className="medal-container">
      <img src={medalBronze} height={height_medal} />
      <div className="text-medal bronze">Nivel Bronce</div>
    </div>
  );
  const silver = (
    <div className="medal-container">
      <img src={medalSilver} height={height_medal} />
      <div className="text-medal silver">Nivel Plata</div>
    </div>
  );
  const gold = (
    <div className="medal-container">
      <img src={medalGold} height={height_medal} />
      <div className="text-medal gold">Nivel Oro</div>
    </div>
  );
  if (loading)
    return (
      <div className="spinner1 color-default">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  return (
    <React.Fragment>
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
          backgroundColor: "#e9f3ff",
        }}
      >
        <img
          className="lp-back2"
          onClick={() => history.goBack()}
          src={back}
          alt="back"
        />
        <div className="header-share-certificate">
          <img
            src={data?.profile?.avatar?.image}
            height="50px"
            alt="profile-pic"
          />
          <div id="title-container">
            <div id="name">{`${data.first_name} ${data.last_name}`}</div>
            <div id="subtitle">Comparti tu certificado</div>
          </div>
        </div>

        <div className="blue-line-separator" />
        <div className="center-monstruito">
          <img src={happyMonster} height="200px" width="200px" />
        </div>
        <div className="box-green">
          <div className="certificate-table">
            {stats?.user_stats?.medals.length ? (
              <div className="body-share-certificate">
                <div className="finished-level">
                  <div id="title-container">Niveles completados:</div>
                  <div id="medals">
                    {stats?.user_stats?.medals.includes("Básico") && bronze}
                    {stats?.user_stats?.medals.includes("Intermedio") && silver}
                    {stats?.user_stats?.medals.includes("Avanzado") && gold}
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment />
            )}
            <div id="row">
              <div id="title-row">Punto totales</div>
              {`${stats?.user_stats?.total_points}`}
            </div>
            <div className="blue-line-separator-row" />

            <div id="row">
              <div id="title-row">Ranking Global</div>
              {`${stats?.user_stats?.global_ranking} de ${stats?.user_stats?.total_ranks}`}
            </div>
            <div className="blue-line-separator-row" />

            <div id="row">
              <div id="title-row">Ranking Institución</div>
              {`${stats?.user_stats?.institution_ranking} de ${stats?.user_stats?.total_ranks_institution}`}
            </div>
            <div className="blue-line-separator-row" />
          </div>
          <div className="button-share-certificate" onClick={toggleModal}>
            Compartir
            <img src={shareSocialIcon} />
          </div>
          <a
            href={stats.cert_url}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div className="button-download-certificate">
              <img src={downloadIcon} />
            </div>
          </a>
        </div>
        <div className="landing-footer bottom-height"></div>
        <ShareSocialMediaModal
          open={modal}
          title={title}
          url={stats?.cert_url}
          hashtag="#FONACIDE"
        />
      </div>
    </React.Fragment>
  );
};
export default ShareCertificateScreen;
