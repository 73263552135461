/*Sounds*/
import soundFF from '../assets/sounds/funFact.mp3';
import soundCorrect from '../assets/sounds/correct.mp3';
import soundIncorrect from '../assets/sounds/incorrect.mp3';
import soundFail from '../assets/sounds/levelFail.mp3';
import soundSuccess from '../assets/sounds/levelSuccess.mp3';
import soundTab from '../assets/sounds/tabGeneral.mp3';
import soundStartLvl from '../assets/sounds/startLevel.mp3';
import soundStartBonus from '../assets/sounds/startBonus.mp3';
import soundCorrectBonus from '../assets/sounds/correctBonus.mp3';
import soundIncorrectBonus from '../assets/sounds/incorrectBonus.mp3';
import soundBonusFinished from '../assets/sounds/bonusFinished.mp3';
import soundExitLevel from '../assets/sounds/exitLevel.mp3';

class SoundService {

    // preload sounds
    sounds = {
        funFact: new Audio(soundFF),
        correct: new Audio(soundCorrect),
        incorrect: new Audio(soundIncorrect),
        fail: new Audio(soundFail),
        success: new Audio(soundSuccess),
        tab: new Audio(soundTab),
        startLvl: new Audio(soundStartLvl),
        bonusStart: new Audio(soundStartBonus),
        bonusCorrect: new Audio(soundCorrectBonus),
        bonusIncorrect: new Audio(soundIncorrectBonus),
        bonusFinished: new Audio(soundBonusFinished),
        exitLevel: new Audio(soundExitLevel)
    } // Con esta metodología, no puedo reproducir varias instancias del mismo sonido al mismo tiempo, pero si puedo reproducir varios sonidos distino a la vez
    // Lo ideal seria crear un nuevo objecto Audio dentro de cada funcion y reproducirlo, pero podría ser un poco mas lenta esta implementacion
    // Tampoco no necesitamos varios sonidos iguales a la vez, por ahora

    playFF() {
        if (localStorage.getItem('laberinfo_sound') === 'on') {
            this.sounds.funFact.play();
        }
        
    }

    playCorrect() {
        if (localStorage.getItem('laberinfo_sound') === 'on') {
            this.sounds.correct.currentTime = 0;
            this.sounds.correct.play();
        }
    }

    playIncorrect() {
        if (localStorage.getItem('laberinfo_sound') === 'on') {
            this.sounds.incorrect.currentTime = 0;
            this.sounds.incorrect.play();
        }
    }

    playFail() {
        if (localStorage.getItem('laberinfo_sound') === 'on') {
            this.sounds.fail.play();
        }
    }

    playSuccess() {
        if (localStorage.getItem('laberinfo_sound') === 'on') {
            this.sounds.success.play();
        }
    }

    playTab() {
        if (localStorage.getItem('laberinfo_sound') === 'on') {
            this.sounds.tab.currentTime = 0;
            this.sounds.tab.play();
        }
    }

    playStartLevel() {
        if (localStorage.getItem('laberinfo_sound') === 'on') {
            this.sounds.startLvl.play();
        }
    }

    playBonusStart() {
        if (localStorage.getItem('laberinfo_sound') === 'on') {
            this.sounds.bonusStart.play();
        }
    }

    playBonusCorrect() {
        if (localStorage.getItem('laberinfo_sound') === 'on') {
            this.sounds.bonusCorrect.currentTime = 0;
            this.sounds.bonusCorrect.play();
        }
    }

    playBonusIncorrect() {
        if (localStorage.getItem('laberinfo_sound') === 'on') {
            this.sounds.bonusIncorrect.currentTime = 0;
            this.sounds.bonusIncorrect.play();
        }
    }

    playBonusFinished() {
        if (localStorage.getItem('laberinfo_sound') === 'on') {
            this.sounds.bonusFinished.play();
        }
    }

    playExitLevel() {
        if (localStorage.getItem('laberinfo_sound') === 'on') {
            this.sounds.exitLevel.play();
        }
    }
}
export default new SoundService();