import React, { useEffect, useState } from "react";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import secrets from "../secrets.json";
import Modal from "react-modal";
import closeButton from "../assets/images/closeButton.svg";
import facebookIcon from "../assets/images/facebookIcon.svg";
import whatsappIcon from "../assets/images/whatsappIcon.svg";
import twitterIcon from "../assets/images/twitterIcon.svg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
    width: "89%",
    borderRadius: "29px",
    padding: "10px",
    fontFamily: "Poppins",
    boxShadow: "1px 1px 15px rgba(0, 0, 0, 0.25)",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    letterSpacing: "0.1em",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
};
const default_title =
  "¡Jugá LaberINFO y aprendé sobre el FONACIDE y educación!  Datos perturbadores, inversión, monitoreo y más sobre los billones de dólares transferidos a gobernaciones y municipalidades de Paraguay. ";

const ShareSocialMediaModal = (props) => {
  // props.open=true or false to open modal
  const [open, setOpen] = useState(false);
  const title = props.title ? props.title : default_title;
  const url = props.url ? props.url : secrets.url;
  const hashtag = props.hashtag ? props.hashtag : "#Laberinfo";

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onClose = () => {
    setOpen(false);
    if (props.onClose) props.onClose();
  };
  return (
    <Modal isOpen={open} onRequestClose={onClose} style={customStyles}>
      <div
        style={{
          color: " #D9DAD8",
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
          width: "100%",
          marginTop: "6px",
        }}
        onClick={onClose}
      >
        <img src={closeButton} alt="close-button" />
      </div>
      <div style={{ margin: "9px 20px 37px" }}>
        ¡Comparte esta aplicación en redes sociales para que todos tus amigos la
        conozcan!
      </div>
      <div className="container-social-media-share-button">
        <FacebookShareButton
          id="facebook-share"
          quote={title}
          hashtag={hashtag}
          url={url}
        >
          <img src={facebookIcon} alt="facebook-logo" />
        </FacebookShareButton>
        <TwitterShareButton id="twitter-share" url={url} title={title}>
          <img src={twitterIcon} alt="twitterIcon" />
        </TwitterShareButton>
        <WhatsappShareButton id="whatsapp-share" url={url} title={title}>
          <img src={whatsappIcon} alt="whatsappIcon" />
        </WhatsappShareButton>
      </div>
    </Modal>
  );
};
export default ShareSocialMediaModal;
