import React, { useState, useEffect, Component } from "react";
import download from "../QuestionsReport/icons/download.png";
import LevelsService from "../../services/levels";
import ProfileService from "../../services/profile";
import ReportsService from "../../services/reports";
import SoundsService from "../../services/sound";
import "./UsersReport.css";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  HorizontalBarSeries,
  VerticalBarSeries,
  VerticalGridLines,
  LineSeries,
  RadialChart,
  Hint,
  Crosshair,
} from "react-vis";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";
import institutionicon from "../../assets/images/institutionicon.svg";
import usericon from "../../assets/images/personCircle.svg";
import "../../App.css";
import { CSSTransition } from "react-transition-group";
import close from "../../assets/images/interface/closeModal.svg";
import "../../transitions.css";
import levels from "../../services/levels";

let citiesDb = [];
let institutionsDb = [];
let usersDb = [];

const legendLabelContainerStyle = {
  display: "flex",
  alignItems: "center",
};

const legendLabelStyle = {
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "20px",
  color: "#3C3C3C",
};

const StyledXAxis = function (tickFormat) {
  return (
    <XAxis
      tickSizeInner={0}
      tickPadding={5}
      tickFormat={(f) => (tickFormat ? tickFormat(f) : f)}
      style={{
        ticks: { stroke: "#111827" },
        text: {
          fontSize: 10,
          stroke: "none",
          fontWeight: 400,
        },
      }}
    />
  );
};

const StyledYAxis = function () {
  return (
    <YAxis
      tickSizeInner={0}
      tickPadding={5}
      style={{
        ticks: { stroke: "#111827" },
        text: {
          fontSize: 10,
          stroke: "none",

          fontWeight: 400,
        },
      }}
    />
  );
};

const Square = function ({ color }) {
  return (
    <div
      style={{
        height: 12,
        width: 12,
        borderRadius: 4,
        backgroundColor: color,
        marginRight: 9,
      }}
    />
  );
};

const RadialLegend = function ({ title, items, colors }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: 10,
      }}
    >
      <div
        style={{
          color: "#111827",
          marginBottom: 30,
        }}
      >
        {title}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 8,
        }}
      >
        {!!items &&
          items.map((item, indx) => (
            <div style={legendLabelContainerStyle}>
              <Square color={colors[indx % 4]} />
              <div style={legendLabelStyle}>{item}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

// recomended using up to 4 items
const GenericRadialChart = function ({
  itemsData,
  pluralName,
  horizontalAlign,
  colors,
}) {
  // itemsData: { title: string , value: number }

  const hAlign = horizontalAlign ? horizontalAlign : "auto";

  // hovered
  //const [radialHover, setRadialHover] = useState(null);

  let aux = 0;
  itemsData.forEach((id) => {
    aux += id.value;
  });

  const totalItemsData = aux;

  const data = itemsData.map((itemData, indx) => {
    return {
      angle: itemData.value,
      label: itemData.value,
      /*subLabel:
        !isNaN(((100 * itemData.value) / totalItemsData).toFixed(1)) &&
        `${+((100 * itemData.value) / totalItemsData).toFixed(1)}%`,*/
      title: itemData.title,
      quantity: itemData.value,
      color: colors[indx % 4],
    };
  });

  return (
    <RadialChart
      colorType="literal"
      showLabels={true}
      labelsRadiusMultiplier={0.8}
      labelsStyle={{
        fontSize: 12,
        fontWeight: 500,
      }}
      data={data}
      //onValueMouseOver={(value) => setRadialHover(value)}
      //onValueMouseOut={() => setRadialHover(null)}
      width={165}
      height={165}
    >
      {/*radialHover && (
        <Hint
          value={radialHover}
          align={{ horizontal: hAlign, vertical: "auto" }}
        >
          <div className={hintStyle}>
            <div>
              {radialHover.title}: {radialHover.quantity}{" "}
              {pluralName ? pluralName : "pacientes"}{" "}
              {!!radialHover.subLabel && `(${radialHover.subLabel})`}
            </div>
          </div>
        </Hint>
      )*/}
    </RadialChart>
  );
};

const GraphContainer = function (props) {
  return (
    <div
      style={{
        background: "#FFFFFF",
        boxShadow: "1px 1px 30px rgba(0, 0, 0, 0.07)",
        borderRadius: 8,
        padding: "16px 20px",
        boxSizing: "border-box",
        width: props.width ? props.width : "auto",
        height: props.height ? props.height : "auto",
      }}
    >
      {props.children}
    </div>
  );
};

const BarChartLegend = function ({ items, colors }) {
  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 30,
    gap: "16px 27px",
  };

  return (
    <div style={containerStyle}>
      {items.map(
        (i, indx) =>
          !!i && (
            <div style={legendLabelContainerStyle}>
              <Square color={colors[indx % colors.length]} />
              <div className={legendLabelStyle}>{i}</div>
            </div>
          )
      )}
    </div>
  );
};

const LinealGraphic = function (props) {
  return (
    <XYPlot xType="ordinal" width={props.width} height={props.height}>
      <HorizontalGridLines />
      <VerticalGridLines />
      {StyledXAxis()}
      {StyledYAxis()}

      <LineSeries color={props.color} data={props.data} />
    </XYPlot>
  );
};

const HorizontalBarGraphic = function (props) {
  return (
    <XYPlot
      yType="ordinal"
      width={props.width}
      height={props.height}
      margin={{ left: props.labelWidth ? props.labelWidth : 40 }}
    >
      <HorizontalGridLines />
      <VerticalGridLines />
      {StyledXAxis(props.tickFormat)}
      {StyledYAxis()}
      <HorizontalBarSeries
        colorType="literal"
        color={props.color}
        data={props.data}
      />
    </XYPlot>
  );
};

const VerticalBarGraphic = function (props) {
  return (
    <XYPlot xType="ordinal" width={props.width} height={props.height}>
      <HorizontalGridLines />
      <VerticalGridLines />
      {StyledXAxis()}
      {StyledYAxis()}
      <VerticalBarSeries
        colorType="literal"
        data={props.data}
        color={props.color}
      />
    </XYPlot>
  );
};

let endpointFlag = false;
let districtFlag = false;

let communityRolesColors = [
  "#7593FF",
  "#FFA026",
  "#FC8593",
  "#7DCE30",
  "#58CDF6",
];

export const UsersReport = () => {
  const [level, setLevel] = useState(-1);
  const [department, setDepartment] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [institution, setInstitution] = useState(null);
  const [institutions, setInstitutions] = useState([]);
  const [rol, setRol] = useState(null);
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState();
  const [users, setUsers] = useState([]);
  const [modul, setModule] = useState();
  const [modules, setModules] = useState([]);
  const [range, setRange] = useState();
  const [ranges, setRanges] = useState([]);
  const [usersDate, setUsersDate] = useState([]);
  const [ages, setAges] = useState(null);
  const [genders, setGenders] = useState(null);
  const [grades, setGrades] = useState(null);
  const [finishedLevels, setFinishedLevels] = useState(null);
  const [communityRoles, setComunityRoles] = useState(null);
  const [avgTimes, setAvgTimes] = useState([]);
  const [institutionsRank, setInstitutionsRank] = useState([]);
  const [usersRank, setUsersRank] = useState([]);
  const screenWidth = useWindowWidth();
  const [loading, setLoading] = useState(true);
  const [showInstitutionModal, setShowInstitutionModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [initialDate, setInitialDate] = useState([]);
  const [finalDate, setFinalDate] = useState([]);

  const openInstitutionModal = function () {
    SoundsService.playTab();
    setShowInstitutionModal(true);
  };

  const closeInstitutionModal = function () {
    SoundsService.playTab();
    setShowInstitutionModal(false);
  };

  const RankInstitutionModal = () => {
    return (
      <CSSTransition
        in={showInstitutionModal}
        timeout={150}
        classNames="modalTransition"
        unmountOnExit
      >
        <div
          className="ranking-modal"
          style={{
            position: "fixed",
          }}
        >
          <div className="pa-title">Ranking</div>
          <img
            height="25px"
            onClick={() => closeInstitutionModal()}
            className="pa-closeButton"
            src={close}
            alt="x"
          />
          <div className="ps-scroll">
            {institutionsRank.map((i) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="d-ranking-position">{i.rank}</div>
                <img
                  height="20"
                  src={institutionicon}
                  style={{ marginRight: "26px" }}
                />

                <div className="d-ranking-description">{i.institution}</div>
                <div className="d-ranking-points">
                  {Math.round(i.avg_points * 100) / 100}
                </div>
              </div>
            ))}
          </div>
          <div className="ps-button-container">
            <button
              onClick={() => closeInstitutionModal()}
              className="ps-button"
            >
              Aceptar
            </button>
          </div>
        </div>
      </CSSTransition>
    );
  };

  const openUserModal = function () {
    SoundsService.playTab();
    setShowUserModal(true);
  };

  const closeUserModal = function () {
    SoundsService.playTab();
    setShowUserModal(false);
  };

  const RankUserModal = () => {
    return (
      <CSSTransition
        in={showUserModal}
        timeout={150}
        classNames="modalTransition"
        unmountOnExit
      >
        <div
          className="ranking-modal"
          style={{
            position: "fixed",
          }}
        >
          <div className="pa-title">Ranking</div>
          <img
            height="25px"
            onClick={() => closeUserModal()}
            className="pa-closeButton"
            src={close}
            alt="x"
          />
          <div className="ps-scroll">
            {usersRank.map((u) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="d-ranking-position">{u.rank}</div>
                <img
                  height="20"
                  src={u.avatar ? u.avatar : usericon}
                  style={{ marginRight: "26px" }}
                />

                <div className="d-ranking-description">{u.user}</div>
                <div className="d-ranking-points">{u.points}</div>
              </div>
            ))}
          </div>
          <div className="ps-button-container">
            <button onClick={() => closeUserModal()} className="ps-button">
              Aceptar
            </button>
          </div>
        </div>
      </CSSTransition>
    );
  };

  useEffect(() => {
    if (!endpointFlag && !districtFlag) {
      setLoading(true);
      let filtersAux = {};
      if (initialDate) {
        filtersAux.date_min = initialDate;
      }
      if (finalDate) {
        filtersAux.date_max = finalDate;
      }
      if (department && !isNaN(department)) {
        filtersAux.department = +department;
      }
      if (city && !isNaN(city)) {
        filtersAux.district = +city;
      }
      if (rol && !isNaN(rol)) {
        filtersAux.community_role = +rol;
      }
      if (institution && !isNaN(+institution)) {
        filtersAux.institution = +institution;
      }
      ReportsService.getUsersReport(filtersAux).then((res) => {
        let community_rolesAux = [];
        for (let c in res.data.community_role) {
          community_rolesAux.push({
            name: c,
            value: res.data.community_role[c],
          });
        }
        setUsersDate(res.data.new_users_by_date);
        setAges(res.data.age);
        setGenders(res.data.gender);
        setGrades(res.data.grade);
        setFinishedLevels(res.data.finished_levels);
        setComunityRoles(community_rolesAux);
        setAvgTimes(res.data.average_time);
        setInstitutionsRank(res.data.institution_ranking);
        setUsersRank(res.data.users_ranking);
        setLoading(false);
      });
    }
  }, [initialDate, finalDate, department, city, rol, institution]);

  useEffect(() => {
    ProfileService.getUtils().then((res) => {
      //console.log(res.data);
      setDepartments(res.data.departments); //id ,dep_code, name
      setRoles(res.data.community_roles); // id, name
      setCities(res.data.districts); // id, name, department
      citiesDb = res.data.districts;
      //console.log(citiesDb);
    });
    ProfileService.getInstitutions().then((res) => {
      const dataSort = res.data.sort((a, b) =>
        a.name < b.name ? -1 : +(a.name > b.name)
      );
      setInstitutions(dataSort); //id, name, district
      institutionsDb = dataSort;
    });

    ProfileService.getUsers().then((res) => {
      setUsers(res.data);
      usersDb = res.data;
      //console.log(res.data);
    });

    LevelsService.getLevels().then((res) => {
      //console.log(res.data);
      setModules(
        res.data.map((l) => {
          return {
            id: l.id,
            name: l.name,
            order: l.order,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    //console.log(department);
    endpointFlag = false;
    setCity(null);
    if (department && !isNaN(+department)) {
      let citiesAux = citiesDb.filter((c) => +department === c.department);
      setCities(citiesAux);
    } else if (department == "Departamento") {
      ProfileService.getUtils().then((res) => {
        setCities(res.data.districts); // id, name, department
      });
    }
  }, [department]);

  useEffect(() => {
    districtFlag = false;
    setInstitution(null);
    if (city && !isNaN(+city)) {
      let institutionsAux = institutionsDb.filter((i) => +city === i.district);
      setInstitutions(institutionsAux);
      /*let usersAux = usersDb.filter((u) => +city === u.profile?.district?.id);
      setUsers(usersAux);*/
    } else if (city == "Municipio") {
      ProfileService.getInstitutions().then((res) => {
        setInstitutions(institutionsDb); //id, name, district
      });
    }
  }, [city]);

  /*useEffect(() => {
    console.log(level);
  }, [level]);*/

  const lookup = { true: "Available", false: "Unavailable" };
  /*const useStyles = makeStyles({
    "@global tbody tr:nth-child(odd)": {
      background: "lightgray",
    },
    "@global tbody tr:nth-child(even)": {
      background: "white",
    },
    "@global tbody title": {
      background: "white",
    },
  });
  const classes = useStyles();*/

  return (
    <div
      style={{
        width: "100%",
        boxSizing: "border-box",
        paddingLeft: 120,
        paddingRight: 120,
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          height: 93,
        }}
      >
        <h1 className="title-time">Reportes de usuario</h1>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
          marginBottom: 50,
        }}
      >
        {/*<span className="sub-title-time">Filtrar por</span>*/}
        <div>
          <label>Fecha inicio</label>
          <input
            className="selectuser-date"
            type="date"
            onChange={(event) => {
              setInitialDate(event.target.value);
            }}
          />
        </div>
        <div>
          <label>Fecha fin</label>
          <input
            className="selectuser-date"
            type="date"
            onChange={(event) => {
              setFinalDate(event.target.value);
            }}
          />
        </div>

        <select
          className="selectuser"
          onChange={(event) => {
            endpointFlag = !!city;
            setDepartment(event.target.value);
          }}
        >
          <option>Departamento</option>
          {departments.map((d) => {
            return <option value={d.id}>{d.name}</option>;
          })}
        </select>
        <select
          className="selectuser"
          onChange={(event) => {
            districtFlag = !!institution;
            setCity(event.target.value);
          }}
        >
          <option selected={!city}>Municipio</option>
          {cities.map((c) => {
            return (
              <option value={c.id} selected={+city === +c.id}>
                {c.name}
              </option>
            );
          })}
        </select>
        <select
          className="selectuser"
          onChange={(event) => {
            setInstitution(event.target.value);
          }}
        >
          <option selected={!institution}>Institución</option>
          {institutions.map((i) => {
            return (
              <option value={i.id} selected={+institution === +i.id}>
                {i.name}
              </option>
            );
          })}
        </select>
        <select
          className="selectuser"
          onChange={(event) => {
            setRol(event.target.value);
          }}
        >
          <option>Rol en la comunidad</option>
          {roles.map((r) => {
            return <option value={r.id}>{r.name}</option>;
          })}
        </select>
      </div>
      {/*<div
        style={{
          width: 1440,
          height: 50,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <a href="#" className="downloadLink">
          Descargar Tabla
          <img src={download} className="downloadLinkIcon" />
        </a>
    </div>*/}
      {loading ? (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Cargando...
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginBottom: 25,
            }}
          >
            <div
              style={{
                width: "calc(60% - 10px)",
                marginRight: 20,
              }}
            >
              <h3 className="chart-title">Crecimiento de usuarios</h3>
              <GraphContainer>
                <BarChartLegend items={["Usuarios"]} colors={["#58CDF6"]} />
                <LinealGraphic
                  height={160}
                  width={(screenWidth - 240) * 0.6 - 60}
                  color={"#58CDF6"}
                  data={usersDate.map((u) => {
                    for (let i in u) {
                      return {
                        x: i,
                        y: u[i],
                      };
                    }
                  })}
                />
              </GraphContainer>
            </div>
            <div
              style={{
                width: "calc(40% - 10px)",
              }}
            >
              <h3 className="chart-title">Usuarios por edad</h3>
              <GraphContainer>
                <BarChartLegend items={["Usuarios"]} colors={["#58CDF6"]} />
                <HorizontalBarGraphic
                  height={160}
                  width={(screenWidth - 240) * 0.4 - 60}
                  color={"#58CDF6"}
                  data={[
                    { x: ages.fourteen_or_less_than_eighteen, y: "14-18" },
                    { x: ages.nineteen_or_less_than_twenty_three, y: "19-23" },
                    {
                      x: ages.twenty_four_or_less_than_twenty_eight,
                      y: "24-28",
                    },
                    {
                      x: ages.twenty_nine_or_less_than_thirty_three,
                      y: "29-33",
                    },
                    {
                      x: ages.thirty_four_or_less_than_thirty_eight,
                      y: "34-38",
                    },
                    {
                      x: ages.thirty_nine_or_less_than_forty_three,
                      y: "39-43",
                    },
                    { x: ages.forty_four_or_more, y: "44+" },
                  ]}
                />
              </GraphContainer>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginBottom: 25,
            }}
          >
            <div
              style={{
                width: "calc(33.3% - 14px)",
                marginRight: 20,
              }}
            >
              <h3 className="chart-title">Usuarios</h3>
              <GraphContainer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <RadialLegend
                    title={"POR SEXO"}
                    items={["mujeres", "hombres", "otros"]}
                    colors={["#FC8593", "#00A3DD", "#7DCE30"]}
                  />
                  <GenericRadialChart
                    itemsData={[
                      { value: genders.female_users, title: "mujeres" },
                      { value: genders.male_users, title: "hombres" },
                      { value: genders.others_users, title: "otros" },
                    ]}
                    colors={["#FC8593", "#00A3DD", "#7DCE30"]}
                  />
                </div>
              </GraphContainer>
            </div>
            <div
              style={{
                width: "calc(33.3% - 13px)",
                marginRight: 20,
              }}
            >
              <h3 className="chart-title">Usuarios por nivel educativo</h3>
              <GraphContainer>
                <BarChartLegend items={["Usuarios"]} colors={["#58CDF6"]} />
                <HorizontalBarGraphic
                  labelWidth={60}
                  height={160}
                  width={(screenWidth - 240) * 0.33 - 60}
                  color={"#58CDF6"}
                  data={[
                    //{ x: grades["1"], y: "1er ciclo" },
                    //{ x: grades["2"], y: "2do ciclo" },
                    { x: grades["Noveno Grado"], y: "9° grado" },
                    { x: grades["Primer curso"], y: "1er curso" },
                    { x: grades["Segundo curso"], y: "2do curso" },
                    { x: grades["Tercer curso"], y: "3er curso" },
                  ]}
                />
              </GraphContainer>
            </div>
            <div
              style={{
                width: "calc(33.4% - 13px)",
              }}
            >
              <h3 className="chart-title">Usuarios por niveles superados</h3>
              <GraphContainer>
                <BarChartLegend items={["Usuarios"]} colors={["#58CDF6"]} />
                <HorizontalBarGraphic
                  labelWidth={65}
                  height={160}
                  width={(screenWidth - 240) * 0.33 - 60}
                  color={"#58CDF6"}
                  data={[
                    { x: finishedLevels.basic_level, y: "Básico" },
                    { x: finishedLevels.intermediate_level, y: "Intermedio" },
                    { x: finishedLevels.advanced_level, y: "Avanzado" },
                  ]}
                />
              </GraphContainer>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "calc(50% - 10px)",
                marginRight: 20,
              }}
            >
              <h3 className="chart-title">Usuarios por roles</h3>
              <GraphContainer>
                <BarChartLegend
                  items={
                    /*[
                    "Estudiantes",
                    "Docentes",
                    "Supervisor/a",
                    "Madre/Padre/Tutor",
                  ]*/
                    communityRoles.map((c) => {
                      return c.name;
                    })
                  }
                  colors={communityRolesColors}
                />
                <VerticalBarGraphic
                  width={(screenWidth - 240) * 0.5 - 60}
                  height={170}
                  data={
                    /*[
                    {
                      color: "#7593FF",
                      x: "Estudiantes",
                      y: communityRoles.Estudiante,
                    },
                    {
                      color: "#FFA026",
                      x: "Docente",
                      y: communityRoles.Docente,
                    },
                    {
                      color: "#FC8593",
                      x: "Supervisor/a",
                      y: communityRoles.Supervisor,
                    },
                    {
                      color: "#7DCE30",
                      x: "Madre/Padre/Tutor",
                      y: communityRoles["Madre/Padre"],
                    },
                  ]*/
                    communityRoles.map((r, i) => {
                      return {
                        color:
                          communityRolesColors[i % communityRolesColors.length],
                        x: r.name,
                        y: r.value,
                      };
                    })
                  }
                />
              </GraphContainer>
            </div>
            <div
              style={{
                width: "calc(50% - 10px)",
              }}
            >
              <h3 className="chart-title">Tiempo promedio</h3>
              <GraphContainer>
                <select
                  className="select-module"
                  onChange={(event) => {
                    setLevel(event.target.value);
                  }}
                >
                  <option value={-1}>NIVEL</option>
                  <option value={0}>Básico</option>
                  <option value={1}>Intermedio</option>
                  <option value={2}>Avanzado</option>
                </select>
                <HorizontalBarGraphic
                  tickFormat={function (number) {
                    return `${number} s`;
                  }}
                  labelWidth={155}
                  height={140}
                  width={(screenWidth - 240) * 0.5 - 60}
                  color={"#00A3DD"}
                  data={avgTimes
                    .map((a) => {
                      return {
                        x: a.value,
                        y: a.name,
                        difficulty: a.difficulty,
                      };
                    })
                    .filter((a) => {
                      return +level === -1 || +level === +a.difficulty;
                    })}
                />
              </GraphContainer>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginBottom: 40,
            }}
          >
            <div
              style={{
                width: "calc(50% - 10px)",
                marginRight: 20,
              }}
            >
              <h3 className="chart-title">Ranking de instituciones</h3>
              <GraphContainer>
                <div>
                  {institutionsRank.slice(0, 7).map((i) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="d-ranking-position">{i.rank}</div>
                      <img
                        height="20"
                        src={institutionicon}
                        style={{ marginRight: "26px" }}
                      />

                      <div className="d-ranking-description">
                        {i.institution}
                      </div>
                      <div className="d-ranking-points">
                        {Math.round(i.avg_points * 100) / 100}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="dashboard-link-container">
                  <span
                    onClick={() => openInstitutionModal()}
                    className="dashboard-link"
                  >
                    ver completo
                  </span>
                </div>
              </GraphContainer>
            </div>
            <div
              style={{
                width: "calc(50% - 10px)",
              }}
            >
              <h3 className="chart-title">Ranking de usuarios</h3>
              <GraphContainer>
                <div>
                  {usersRank.slice(0, 7).map((u) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="d-ranking-position">{u.rank}</div>
                      <img
                        height="20"
                        src={usericon}
                        style={{ marginRight: "26px" }}
                      />
                      <div className="d-ranking-description">{u.user}</div>
                      <div className="d-ranking-points">{u.points}</div>
                    </div>
                  ))}
                </div>
                <div className="dashboard-link-container">
                  <span
                    onClick={() => openUserModal()}
                    className="dashboard-link"
                  >
                    ver completo
                  </span>
                </div>
              </GraphContainer>
            </div>
          </div>
        </div>
      )}
      {RankInstitutionModal()}
      {RankUserModal()}
    </div>
  );
};
