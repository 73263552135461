import React from "react";
import {
  BrowserRouter as Router,
  // Route,
  Switch,
} from "react-router-dom";

import { GuardProvider, GuardedRoute } from "react-router-guards";

import "./App.css";
import "./transitions.css";
import Home from "./pages/TabPages/home";
import Profile from "./pages/TabPages/profile";
import Dashboard from "./pages/TabPages/dashboard";
import Tab from "./components/tab";
import LevelPage from "./pages/levelPage";
import ChangePassword from "./pages/changePassword";
import LevelQuestions from "./pages/LevelQuestions/LevelQuestions";
import LandingPage from "./pages/Landing/landingPage";
import LoginPage from "./pages/Landing/login";
import RegisterPage from "./pages/Landing/register";
import ShareCertificateScreen from "./components/shareCertificate";
import { ReportsOptions } from "./components/ReportsOptions";
import LoginPageDashboard from "./pages/Landing/loginDashboard";

const requireLogin = (to, from, next) => {
  /*console.log(to);
  console.log(from);
  console.log(next);*/
  if (to.meta.auth) {
    if (localStorage.getItem("laberinfo_user_token")) {
      next();
    }
    if (to.match.path === "/stats") {
      next.redirect("/loginDashboard");
    }
    next.redirect("/login");
  } else {
    next();
  }
};

function App() {
  return (
    <div className="App">
      <Router>
        <GuardProvider guards={[requireLogin]}>
          <Switch>
            <GuardedRoute path="/home" meta={{ auth: true }} component={Home} />
            <GuardedRoute
              path="/profile"
              meta={{ auth: true }}
              component={Profile}
            />
            <GuardedRoute
              path="/dashboard"
              meta={{ auth: true }}
              component={Dashboard}
            />
            <GuardedRoute
              path="/modulo/:id"
              meta={{ auth: true }}
              component={LevelPage}
            />
            <GuardedRoute
              path="/preguntas/:id"
              meta={{ auth: true }}
              component={LevelQuestions}
            />
            <GuardedRoute
              path="/changePassword"
              meta={{ auth: true }}
              component={ChangePassword}
            />
            <GuardedRoute
              path="/share"
              meta={{ auth: true }}
              component={ShareCertificateScreen}
            />
            <GuardedRoute exact path="/" component={LandingPage} />
            <GuardedRoute path="/login" component={LoginPage} />
            <GuardedRoute path="/register" component={RegisterPage} />
            <GuardedRoute
              path="/loginDashboard"
              component={LoginPageDashboard}
            />
            <GuardedRoute
              path="/stats"
              meta={{ auth: true }}
              component={ReportsOptions}
            />
            <GuardedRoute
              path="/stats/questionReport"
              meta={{ auth: true }}
              component={ReportsOptions}
            />
            <GuardedRoute
              path="/stats/timeReport"
              meta={{ auth: true }}
              component={ReportsOptions}
            />
            <GuardedRoute
              path="/stats/userReport"
              meta={{ auth: true }}
              component={ReportsOptions}
            />
          </Switch>
        </GuardProvider>
        <Tab />
      </Router>
    </div>
  );
}

export default App;
