export const dinamicSVGPathColor = (num, color = false) => {
  const paths = {
    moduloR1:
      '<svg width="730" height="350" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="352" width="732" y="-1" x="-1"/> </g> <g fill="%23afe2f9">  <title>Layer 1</title>  <path id="svg_2" d="m285.71,312.64l442.09,36.65c-11,-22.67 -110.68,-106.15 -134.42,-122.29c-88.74,-60.44 -178.91,-149.67 -178.91,-149.67l-412.63,-76.33s28.57,102.86 124.16,187.9c97.17,86.38 144.21,107.15 159.71,123.74z"/> </g></svg>',
    moduloR2:
      '<svg width="475" height="390" xmlns="http://www.w3.org/2000/svg">  <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="392" width="477" y="-1" x="-1"/> </g> <g fill="%23d5eaa2">  <title>Layer 1</title>  <path id="svg_3" d="m334.63,389.1c9.26,-8 18.22,-16.12 26.73,-24.63c85.33,-85.33 139.88,-212.71 97.64,-327.47l-431,-35.75c99.84,116.53 69.53,170.35 -2.22,259.34a203.82,203.82 0 0 1 -24.39,24.7l333.24,103.81z" /> </g></svg>',
    moduloR3:
      '<svg width="605" height="335" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="337" width="607" y="-1" x="-1"/> </g> <g fill="%23fece91">  <title>Layer 1</title>  <path id="svg_1" d="m418.09,332.94c23.05,-96.38 93.94,-155 186.35,-228l-333.28,-103.8c-85.94,53.1 -211.01,93.86 -270.16,255.61l417.09,76.19z" /> </g></svg>    ',
    moduloR4:
      '<svg width="730" height="350" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="352" width="732" y="-1" x="-1"/> </g> <g fill="%23ffdf94">  <title>Layer 1</title>  <path id="svg_2" d="m285.71,312.64l442.09,36.65c-11,-22.67 -110.68,-106.15 -134.42,-122.29c-88.74,-60.44 -178.91,-149.67 -178.91,-149.67l-412.63,-76.33s28.57,102.86 124.16,187.9c97.17,86.38 144.21,107.15 159.71,123.74z" /> </g></svg>',
    moduloR5:
      '<svg width="475" height="390" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="392" width="477" y="-1" x="-1"/> </g> <g fill="%23a6d9c8">  <title>Layer 1</title>  <path id="svg_3" d="m334.63,389.1c9.26,-8 18.22,-16.12 26.73,-24.63c85.33,-85.33 139.88,-212.71 97.64,-327.47l-431,-35.75c99.84,116.53 69.53,170.35 -2.22,259.34a203.82,203.82 0 0 1 -24.39,24.7l333.24,103.81z" /> </g></svg>',
    moduloR6:
      '<svg width="605" height="335" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="337" width="607" y="-1" x="-1"/> </g> <g fill="%23f8b8bb">  <title>Layer 1</title>  <path id="svg_1" d="m418.09,332.94c23.05,-96.38 93.94,-155 186.35,-228l-333.28,-103.8c-85.94,53.1 -211.01,93.86 -270.16,255.61l417.09,76.19z" /> </g></svg>',
    moduloR7:
      '<svg width="730" height="350" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="352" width="732" y="-1" x="-1"/> </g> <g fill="%238caed0">  <title>Layer 1</title>  <path id="svg_2" d="m285.71,312.64l442.09,36.65c-11,-22.67 -110.68,-106.15 -134.42,-122.29c-88.74,-60.44 -178.91,-149.67 -178.91,-149.67l-412.63,-76.33s28.57,102.86 124.16,187.9c97.17,86.38 144.21,107.15 159.71,123.74z" /> </g></svg>',
    moduloR8:
      '<svg width="475" height="390" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="392" width="477" y="-1" x="-1"/> </g> <g fill="%23bbdc9d">  <title>Layer 1</title>  <path id="svg_3" d="m334.63,389.1c9.26,-8 18.22,-16.12 26.73,-24.63c85.33,-85.33 139.88,-212.71 97.64,-327.47l-431,-35.75c99.84,116.53 69.53,170.35 -2.22,259.34a203.82,203.82 0 0 1 -24.39,24.7l333.24,103.81z"/> </g></svg>',
    moduloR9:
      '<svg width="605" height="335" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="337" width="607" y="-1" x="-1"/> </g> <g fill="%23f5886c">  <title>Layer 1</title>  <path id="svg_1" d="m418.09,332.94c23.05,-96.38 93.94,-155 186.35,-228l-333.28,-103.8c-85.94,53.1 -211.01,93.86 -270.16,255.61l417.09,76.19z" /> </g></svg>',
    moduloR10:
      '<svg width="730" height="350" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="352" width="732" y="-1" x="-1"/> </g> <g fill="%23caaaa2">  <title>Layer 1</title>  <path id="svg_2" d="m285.71,312.64l442.09,36.65c-11,-22.67 -110.68,-106.15 -134.42,-122.29c-88.74,-60.44 -178.91,-149.67 -178.91,-149.67l-412.63,-76.33s28.57,102.86 124.16,187.9c97.17,86.38 144.21,107.15 159.71,123.74z" /> </g></svg>',
    moduloR11:
      '<svg width="475" height="390" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="392" width="477" y="-1" x="-1"/> </g> <g fill="%23a6d9c8"> <title>Layer 1</title>  <path id="svg_3" d="m334.63,389.1c9.26,-8 18.22,-16.12 26.73,-24.63c85.33,-85.33 139.88,-212.71 97.64,-327.47l-431,-35.75c99.84,116.53 69.53,170.35 -2.22,259.34a203.82,203.82 0 0 1 -24.39,24.7l333.24,103.81z" /> </g></svg>',
    moduloR12:
      '<svg width="605" height="335" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="337" width="607" y="-1" x="-1"/></g> <g fill="%23ffdf94">  <title>Layer 1</title>  <path id="svg_1" d="m418.09,332.94c23.05,-96.38 93.94,-155 186.35,-228l-333.28,-103.8c-85.94,53.1 -211.01,93.86 -270.16,255.61l417.09,76.19z" /> </g></svg>',
    moduloR13:
      '<svg width="730" height="350" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="352" width="732" y="-1" x="-1"/> </g> <g fill="%23adbfff">  <title>Layer 1</title>  <path id="svg_2" d="m285.71,312.64l442.09,36.65c-11,-22.67 -110.68,-106.15 -134.42,-122.29c-88.74,-60.44 -178.91,-149.67 -178.91,-149.67l-412.63,-76.33s28.57,102.86 124.16,187.9c97.17,86.38 144.21,107.15 159.71,123.74z" /> </g></svg>',
    moduloR14:
      '<svg width="475" height="390" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="392" width="477" y="-1" x="-1"/> </g> <g fill="%233ac3f3">  <title>Layer 1</title>  <path id="svg_3" d="m334.63,389.1c9.26,-8 18.22,-16.12 26.73,-24.63c85.33,-85.33 139.88,-212.71 97.64,-327.47l-431,-35.75c99.84,116.53 69.53,170.35 -2.22,259.34a203.82,203.82 0 0 1 -24.39,24.7l333.24,103.81z" /> </g></svg>',
    moduloR15:
      '<svg width="605" height="335" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="337" width="607" y="-1" x="-1"/> </g> <g fill="%23f8a3a4">  <title>Layer 1</title>  <path id="svg_1" d="m418.09,332.94c23.05,-96.38 93.94,-155 186.35,-228l-333.28,-103.8c-85.94,53.1 -211.01,93.86 -270.16,255.61l417.09,76.19z"/> </g></svg>',
    moduloR16:
      '<svg width="730" height="350" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="352" width="732" y="-1" x="-1"/> </g> <g fill="%237DCE30"> <title>Layer 1</title>  <path id="svg_2" d="m285.71,312.64l442.09,36.65c-11,-22.67 -110.68,-106.15 -134.42,-122.29c-88.74,-60.44 -178.91,-149.67 -178.91,-149.67l-412.63,-76.33s28.57,102.86 124.16,187.9c97.17,86.38 144.21,107.15 159.71,123.74z"/> </g></svg>',
    moduloR17:
      '<svg width="475" height="390" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="392" width="477" y="-1" x="-1"/> </g> <g fill="%23e4b0d0">  <title>Layer 1</title>  <path id="svg_3" d="m334.63,389.1c9.26,-8 18.22,-16.12 26.73,-24.63c85.33,-85.33 139.88,-212.71 97.64,-327.47l-431,-35.75c99.84,116.53 69.53,170.35 -2.22,259.34a203.82,203.82 0 0 1 -24.39,24.7l333.24,103.81z" class="cls-1"/></g></svg>',
    moduloR18:
      '<svg width="605" height="335" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title> <rect fill="none" id="canvas_background" height="337" width="607" y="-1" x="-1"/> </g> <g fill="%2399cb9c">  <title>Layer 1</title>  <path id="svg_1" d="m418.09,332.94c23.05,-96.38 93.94,-155 186.35,-228l-333.28,-103.8c-85.94,53.1 -211.01,93.86 -270.16,255.61l417.09,76.19z" /> </g></svg>',
    moduloR19:
      '<svg width="730" height="350" xmlns="http://www.w3.org/2000/svg"><title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="352" width="732" y="-1" x="-1"/> </g> <g fill="%23ffdf94">  <title>Layer 1</title>  <path id="svg_2" d="m285.71,312.64l442.09,36.65c-11,-22.67 -110.68,-106.15 -134.42,-122.29c-88.74,-60.44 -178.91,-149.67 -178.91,-149.67l-412.63,-76.33s28.57,102.86 124.16,187.9c97.17,86.38 144.21,107.15 159.71,123.74z" /></g></svg>',
    moduloR20:
      '<svg width="475" height="390" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="392" width="477" y="-1" x="-1"/> </g> <g fill="%23caaaa2">  <title>Layer 1</title>  <path id="svg_3" d="m334.63,389.1c9.26,-8 18.22,-16.12 26.73,-24.63c85.33,-85.33 139.88,-212.71 97.64,-327.47l-431,-35.75c99.84,116.53 69.53,170.35 -2.22,259.34a203.82,203.82 0 0 1 -24.39,24.7l333.24,103.81z" /> </g></svg>',
    moduloR21:
      '<svg width="605" height="335" xmlns="http://www.w3.org/2000/svg"> <title>modulos</title> <g>  <title>background</title>  <rect fill="none" id="canvas_background" height="337" width="607" y="-1" x="-1"/> </g> <g fill="%23e4b0d0">  <title>Layer 1</title>  <path id="svg_1" d="m418.09,332.94c23.05,-96.38 93.94,-155 186.35,-228l-333.28,-103.8c-85.94,53.1 -211.01,93.86 -270.16,255.61l417.09,76.19z" /></g></svg>',
  };
  if (color) {
    color = color.replace("#", "");
    const svg = paths[`moduloR${num}`];
    const n = svg.search('fill="%23') + 9;
    const old_color = svg.substring(n, n + 6);
    paths[`moduloR${num}`] = svg.replace(
      `fill="%23${old_color}`,
      `fill="%23${color}`
    );
  }
  return paths[`moduloR${num}`];
};
export const dinamicCircleSvgPathColor = (num, color = false) => {
  const paths = {
    moduloR1:
      '<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d)"><circle cx="36.5" cy="36.5" r="21.5" fill="url(%23paint0_linear)"/><circle cx="36.5" cy="36.5" r="21.5" stroke="%23FFF8EF" stroke-width="2"/></g><defs><filter id="filter0_d" x="0" y="0" width="75" height="75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dx="1" dy="1"/><feGaussianBlur stdDeviation="7.5"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter><linearGradient id="paint0_linear" x1="36.5" y1="15" x2="36.5" y2="58" gradientUnits="userSpaceOnUse"><stop stop-color="%2358CDF7"/><stop offset="1" stop-color="%234BD0FF"/></linearGradient></defs></svg>',
    moduloR2:
      '<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d)"><circle cx="36.5" cy="36.5" r="21.5" fill="url(%23paint0_linear)" stroke="%23FFF8EF" stroke-width="2"/></g><defs><filter id="filter0_d" x="0" y="0" width="75" height="75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dx="1" dy="1"/><feGaussianBlur stdDeviation="7.5"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter><linearGradient id="paint0_linear" x1="36.5" y1="15" x2="36.5" y2="58" gradientUnits="userSpaceOnUse"><stop stop-color="%237DCE30"/><stop offset="1" stop-color="%2372D01A"/></linearGradient></defs></svg>',
    moduloR3:
      '<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d)"><circle cx="36.5" cy="36.5" r="21.5" fill="url(%23paint0_linear)"/><circle cx="36.5" cy="36.5" r="21.5" stroke="%23FFF8EF" stroke-width="2"/></g><defs><filter id="filter0_d" x="0" y="0" width="75" height="75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dx="1" dy="1"/><feGaussianBlur stdDeviation="7.5"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter><linearGradient id="paint0_linear" x1="36.5" y1="15" x2="36.5" y2="58" gradientUnits="userSpaceOnUse"><stop stop-color="%23FFA026"/><stop offset="1" stop-color="%23FF9D21"/></linearGradient></defs></svg>',
    moduloR4:
      '<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d)"><circle cx="36.5" cy="36.5" r="21.5" fill="url(%23paint0_linear)" stroke="%23FFF8EF" stroke-width="2"/></g><defs><filter id="filter0_d" x="0" y="0" width="75" height="75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dx="1" dy="1"/><feGaussianBlur stdDeviation="7.5"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter><linearGradient id="paint0_linear" x1="36.5" y1="15" x2="36.5" y2="58" gradientUnits="userSpaceOnUse"><stop stop-color="%23FFCB11"/><stop offset="1" stop-color="%23FFBA35"/></linearGradient></defs></svg>',
    moduloR5:
      '<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d)"><circle cx="36.5" cy="36.5" r="21.5" fill="url(%23paint0_linear)"/><circle cx="36.5" cy="36.5" r="21.5" stroke="%23FFF8EF" stroke-width="2"/></g><defs><filter id="filter0_d" x="0" y="0" width="75" height="75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dx="1" dy="1"/><feGaussianBlur stdDeviation="7.5"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter><linearGradient id="paint0_linear" x1="36.5" y1="15" x2="36.5" y2="58" gradientUnits="userSpaceOnUse"><stop stop-color="%2310CFA1"/><stop offset="1" stop-color="%2300D8CB"/></linearGradient></defs></svg>',
    moduloR6:
      '<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d)"><circle cx="36.5" cy="36.5" r="21.5" fill="url(%23paint0_linear)"/><circle cx="36.5" cy="36.5" r="21.5" stroke="%23FFF8EF" stroke-width="2"/></g><defs><filter id="filter0_d" x="0" y="0" width="75" height="75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dx="1" dy="1"/><feGaussianBlur stdDeviation="7.5"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter><linearGradient id="paint0_linear" x1="36.5" y1="15" x2="36.5" y2="58" gradientUnits="userSpaceOnUse"><stop stop-color="%23FC8593"/><stop offset="1" stop-color="%23FF6A7C"/></linearGradient></defs></svg>',
    moduloR7:
      '<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d)"><circle cx="36.5" cy="36.5" r="21.5" fill="%237593FF"/><circle cx="36.5" cy="36.5" r="21.5" fill="url(%23paint0_linear)"/><circle cx="36.5" cy="36.5" r="21.5" stroke="%23FFF8EF" stroke-width="2"/></g><defs><filter id="filter0_d" x="0" y="0" width="75" height="75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dx="1" dy="1"/><feGaussianBlur stdDeviation="7.5"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter><linearGradient id="paint0_linear" x1="36.5" y1="15" x2="36.5" y2="58" gradientUnits="userSpaceOnUse"><stop stop-color="%235479FF"/><stop offset="1" stop-color="%237593FF" stop-opacity="0"/></linearGradient></defs></svg>',
  };
  if (color) {
    color = color.replace("#", "");
    let svg = paths[`moduloR${num}`];
    const n1 = svg.indexOf('stop-color="%23') + 15;
    const n2 = svg.lastIndexOf('stop-color="%23') + 15;

    const old_color1 = svg.substring(n1, n1 + 6);
    const old_color2 = svg.substring(n2, n2 + 6);
    svg = svg.replace(
      `stop-color="%23${old_color1}`,
      `stop-color="%23${color}`
    );
    paths[`moduloR${num}`] = svg.replace(
      `stop-color="%23${old_color2}`,
      `stop-color="%23${color}`
    );
    if (num === 7) {
      paths[`moduloR${num}`] = paths[`moduloR${num}`].replace(
        'fill="%237593FF',
        `fill="%23${color}`
      );
    }
  }
  return paths[`moduloR${num}`];
};

export const dinamicCloseModalSvgColor = (color) => {
  const closeSvg =
    '<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.0246 14.5L20.2922 10.2325C20.4947 10.0303 20.6087 9.75592 20.6089 9.46974C20.6092 9.18356 20.4957 8.909 20.2936 8.70646C20.0914 8.50393 19.817 8.39 19.5308 8.38975C19.2447 8.38949 18.9701 8.50294 18.7676 8.70512L14.5 12.9727L10.2324 8.70512C10.0299 8.50258 9.75516 8.38879 9.46873 8.38879C9.1823 8.38879 8.90759 8.50258 8.70506 8.70512C8.50252 8.90766 8.38873 9.18236 8.38873 9.46879C8.38873 9.75522 8.50252 10.0299 8.70506 10.2325L12.9726 14.5L8.70506 18.7676C8.50252 18.9702 8.38873 19.2449 8.38873 19.5313C8.38873 19.8177 8.50252 20.0924 8.70506 20.295C8.90759 20.4975 9.1823 20.6113 9.46873 20.6113C9.75516 20.6113 10.0299 20.4975 10.2324 20.295L14.5 16.0274L18.7676 20.295C18.9701 20.4975 19.2448 20.6113 19.5312 20.6113C19.8177 20.6113 20.0924 20.4975 20.2949 20.295C20.4974 20.0924 20.6112 19.8177 20.6112 19.5313C20.6112 19.2449 20.4974 18.9702 20.2949 18.7676L16.0246 14.5Z" fill="url(%23paint0_linear)"/><circle cx="14.5" cy="14.5" r="13.5" stroke="url(%23paint1_linear)" stroke-width="2"/><defs><linearGradient id="paint0_linear" x1="14.5" y1="8.38879" x2="14.5" y2="20.6113" gradientUnits="userSpaceOnUse"><stop stop-color="%2358CDF7"/><stop offset="1" stop-color="%2358CDF7"/></linearGradient><linearGradient id="paint1_linear" x1="14.5" y1="0" x2="14.5" y2="29" gradientUnits="userSpaceOnUse"><stop stop-color="%2358CDF7"/><stop offset="1" stop-color="%2358CDF7"/></linearGradient></defs></svg>';
  color = color.replace("#", "");
  return (
    "data:image/svg+xml;utf8," +
    closeSvg.replaceAll('stop-color="%2358CDF7', `stop-color="%23${color}`)
  );
};
