import React, { Component, useState } from "react";
import AuthService from "../../services/auth";
import "./landing.css";
import "../../spinners.css";
import SoundService from "../../services/sound";

import laberinfoLogo from "../../assets/images/laberinfoLogo.png";
import reaccionLogo from "../../assets/images/reaccionB.svg";
import back from "../../assets/images/modulos/backBlue.svg";
import LoginWithSocialMedia from "../../components/loginWithSocialMedia";
import secrets from "../../secrets.json";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import TwitterLogin from "react-twitter-login";
import ReactHtmlParser from "react-html-parser";
import close from "../../assets/images/interface/CLOSE.png";
import { CSSTransition } from "react-transition-group";
import "../../transitions.css";

class LoginPage extends Component {
  state = {
    loadingLogin: false,
    statusMessage: null,
    showPassword: false,
    showFocoModal: false,
    focoEmail: "",
    focoPassword: "",
    focoStatusMessage: null,
  };

  email = React.createRef();
  password = React.createRef();
  goBack = (route) => {
    SoundService.playTab();
    this.props.history.goBack();
  };

  goToRegister = (route) => {
    SoundService.playTab();
    this.props.history.push("/register");
  };

  goToHome = (route) => {
    SoundService.playTab();
    this.props.history.push("/home");
  };

  closeModal() {
    this.setState({ statusMessage: null });
  }

  closeErrorFocoModal() {
    this.setState({ focoStatusMessage: null });
  }
  responseFoco = async () => {
    /*AuthService.focoLogin().then((res) => {
      console.log(res);
      this.setState({
        showFocoModal: ReactHtmlParser(res.data),
      });
    });*/
    //this.setState({ loadingLogin: true });
    const focoRes = await AuthService.loginWithFoco(
      this.state.focoEmail,
      this.state.focoPassword
    ).catch((error) => {
      switch (error.response?.status) {
        case 400:
          this.setState({
            focoStatusMessage: "El usuario y/o la contraseña son incorrectos",
          });
          break;
        case 401:
          this.setState({
            focoStatusMessage: "El usuario y/o la contraseña son incorrectos",
          });
          break;
        case 404:
          this.setState({
            statusMessage:
              "Servidor no encontrado, por favor, intente más adelante",
          });
          break;
        case 500:
          this.setState({
            focoStatusMessage:
              "Error en el servidor, por favor, intente más adelante",
          });
          break;
        default:
          this.setState({
            focoStatusMessage:
              "Error desconocido, por favor, intente más adelante",
          });
      }
    });
    const focoToken = focoRes?.data.access_token;
    await AuthService.doConvertTokenFOCO(focoToken).then((res) => {
      if (res.statusText === "OK") {
        AuthService.setToken(res.data.access_token);
        AuthService.setRefreshToken(res.data.refresh_token);
        this.props.history.push("/home");
      } else {
        this.setState({
          focoStatusMessage:
            "Error desconocido, por favor, intente más adelante",
        });
      }
    });
  };

  responseGoogle = (googleRes) => {
    this.setState({ loadingLogin: true });
    const googleToken = googleRes.accessToken;
    AuthService.doConvertToken(googleToken).then((res) => {
      if (res.statusText === "OK") {
        AuthService.setToken(res.data.access_token);
        AuthService.setRefreshToken(res.data.refresh_token);
        this.props.history.push("/home");
      }
    });
  };
  responseTwitter(err, data) {
    if (data) {
      const oauth_token = data.oauth_token;
      const oauth_token_secret = data.oauth_token_secret;
      AuthService.doConvertTokenTW(oauth_token, oauth_token_secret).then(
        (res) => {
          if (res.statusText === "OK") {
            AuthService.setToken(res.data.access_token);
            AuthService.setRefreshToken(res.data.refresh_token);
            // this.props.history.push("/home");
            window.location.href = secrets.url + "/home";
          }
        }
      );
    }
  }
  responseFacebook = (response) => {
    const fbToken = response.accessToken;
    AuthService.doConvertTokenFB(fbToken).then((res) => {
      if (res.statusText === "OK") {
        AuthService.setToken(res.data.access_token);
        AuthService.setRefreshToken(res.data.refresh_token);
        this.props.history.push("/home");
      }
    });
  };
  login() {
    SoundService.playTab();
    this.setState({ loadingLogin: true });
    AuthService.login(
      this.email.current.value,
      this.password.current.value
    ).then(
      (res) => {
        this.setState({ loadingLogin: false });
        if (res.statusText === "OK") {
          AuthService.setToken(res.data.access_token);
          AuthService.setRefreshToken(res.data.refresh_token);
          localStorage.setItem("laberinfo_sound", "on");
          this.props.history.push("/home");
        }
      },
      (error) => {
        this.setState({ loadingLogin: false });
        switch (error.response?.status) {
          case 400:
            this.setState({
              statusMessage: "El correo y/o la contraseña son incorrectos",
            });
            break;
          case 404:
            this.setState({
              statusMessage:
                "Servidor no encontrado, por favor, intente más adelante",
            });
            break;
          case 500:
            this.setState({
              statusMessage:
                "Error en el servidor, por favor, intente más adelante",
            });
            break;
          default:
            this.setState({
              statusMessage:
                "Error desconocido, por favor, intente más adelante",
            });
        }
      }
    );
  }

  spinner() {
    return (
      <div style={{ height: "58px" }}>
        <div style={{ top: "388px" }} className="spinner1 color-2">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  errorModal() {
    return (
      <div className="login-error-modal">
        {this.state.statusMessage}
        <div style={{ marginTop: "20px" }}>
          <button
            onClick={() => this.closeModal()}
            className="outline-button black-button"
          >
            Cerrar
          </button>
        </div>
      </div>
    );
  }

  errorFocoModal() {
    return (
      <div className="login-error-focomodal">
        {this.state.focoStatusMessage}
        <div style={{ marginTop: "20px" }}>
          <button
            onClick={() => this.closeErrorFocoModal()}
            className="outline-button white-button"
          >
            Cerrar
          </button>
        </div>
      </div>
    );
  }

  closeFocoModal() {
    this.setState({ showFocoModal: false });
  }

  focoModal = () => {
    return (
      <CSSTransition
        in={this.state.showFocoModal}
        timeout={250}
        classNames="modalTransition"
        unmountOnExit
      >
        <div
          style={{
            position: "absolute",
            //height: "70%",
            //minHeight: 500,
            height: 400,
            width: 600,
            //padding: 20,
            borderRadius: 10,
            backgroundColor: "#fff",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            onClick={() => this.closeFocoModal()}
            height="25px"
            style={{
              float: "right",
              marginTop: 10,
              marginRight: 10,
            }}
            src={close}
            alt="x"
          />
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap: "30px",
            }}
          >
            <h3
              style={{
                color: "#04A8FA",
              }}
            >
              FOCO
            </h3>
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <label
                for="user"
                style={{
                  marginRight: 20,
                  color: "#51C0FF",
                }}
              >
                Usuario:
              </label>
              <input
                id="user"
                type="text"
                required
                style={{
                  width: "50%",
                  padding: 10,
                  borderRadius: 10,
                  backgroundColor: "#EBF8FF",
                  border: "none",
                  color: "#51C0FF",
                }}
                onChange={(e) => this.setState({ focoEmail: e.target.value })}
              ></input>
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <label
                for="password"
                style={{
                  marginRight: 5,
                  color: "#51C0FF",
                }}
              >
                Password:
              </label>
              <input
                id="password"
                type="password"
                required
                style={{
                  width: "50%",
                  padding: 10,
                  borderRadius: 10,
                  backgroundColor: "#EBF8FF",
                  border: "none",
                  color: "#51C0FF",
                }}
                onChange={(e) =>
                  this.setState({ focoPassword: e.target.value })
                }
              ></input>
            </div>

            <button
              type="button"
              style={{
                width: "70%",
                border: "none",
                padding: 10,
                borderRadius: 10,
                backgroundColor: "#FF9D00",
                color: "#fff",
              }}
              onClick={() => this.responseFoco()}
            >
              Sign in
            </button>
          </form>
          {this.state.focoStatusMessage ? this.errorFocoModal() : null}
        </div>
      </CSSTransition>
    );
  };

  togglePassword() {
    //console.log("Entre");
    this.setState({ showPassword: !this.state.showPassword });
  }
  render() {
    return (
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
        }}
      >
        <div className="LoginPage">
          <div className="logo-container">
            <img src={laberinfoLogo} height="93" alt="laberinfo"></img>
          </div>
          <img
            className="lp-back back-with-bg"
            onClick={() => this.goBack()}
            src={back}
            alt="back"
          />
          <div className="inputs-and-login-container">
            <LoginWithSocialMedia
              responseGoogle={this.responseGoogle}
              responseFacebook={this.responseFacebook}
              responseTwitter={null}
              focoLogin={() =>
                this.setState({
                  showFocoModal: true,
                })
              }
              showKeycloak={false}
            />

            <div
              className="inputs-container"
              style={{ marginLeft: 50, marginRight: 50 }}
            >
              <input
                ref={this.email}
                className="landing-input"
                placeholder="CORREO ELECTRÓNICO"
                spellCheck="false"
              ></input>
              <div className="input-password-container">
                <input
                  ref={this.password}
                  className="landing-input input-password"
                  placeholder="CONTRASEÑA"
                  type={this.state.showPassword ? "text" : "password"}
                  spellCheck="false"
                ></input>
                <div onClick={() => this.togglePassword()}>
                  {this.state.showPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </div>
              </div>
            </div>
            {!this.state.loadingLogin ? (
              <button className="landing-button" onClick={() => this.login()}>
                ingresar
              </button>
            ) : (
              this.spinner()
            )}
            <div className="register-link-container">
              <span onClick={this.goToRegister} className="register-link">
                o registrarse
              </span>
              <span className="register-link-separator"></span>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`${secrets.baseURL}${
                  secrets.baseURL.charAt(secrets.baseURL.length - 1) === "/"
                    ? ""
                    : "/"
                }password_reset/`}
                className="register-link"
              >
                ¿Olvidaste tu contraseña?
              </a>
            </div>
            <img
              src={reaccionLogo}
              // className="login-reaccion-logo"
              alt="reaccion"
            />
            {this.state.statusMessage ? this.errorModal() : null}
          </div>
          <div className="login-footer" />
        </div>
        {this.focoModal()}
      </div>
    );
  }
}
export default LoginPage;
