import React, { Component } from "react";
import "../../spinners.css";
import "./LevelQuestions.css";
import LevelsService from "../../services/levels";
import SoundService from "../../services/sound";
import { CSSTransition } from "react-transition-group";

/*Images*/
import correctPNG from "../../assets/images/questions/newCorrect.png";
import incorrectPNG from "../../assets/images/questions/incorrect.png";
import incorrectAnswer from "../../assets/images/questions/incorrectAnswer.png";
import pet1 from "../../assets/images/questions/questionPet1.png";
import pet2 from "../../assets/images/questions/questionPet2.png";
import pet3 from "../../assets/images/questions/questionPet4.png";
import pet4 from "../../assets/images/questions/questionPet6.png";
import funFactPet from "../../assets/images/questions/newFunFact.png";
import ShareSocialMediaModal from "../../components/shareModal";
import girlGlasses from "../../assets/images/questions/girlGlasses.png";

class LevelQuestions extends Component {
  state = {
    error: null,
    loading: true,
    level: this.props.match.params.id,
    style:
      "lq-lvl" +
      (this.props.match.params.id % 7 === 0
        ? 7
        : this.props.match.params.id % 7),
    answers: 0,
    corrects: 0,
    score: 0,
    modal: false,
    screen: false,
    isCorrect: false,
    finished: false,
    bonus: false,
    funFact: false,
    progressBar: false,
    petPosition: Math.floor(Math.random() * 4),
    current_point_given: 0,
    timer: 0,
  };

  text;

  // object with questions, answers, ff, etc.
  level = null;
  // round id (to backend)
  round = null;
  // bad answered questions stocks here, to the end, user have to answer those questions again, only if wrongQuestions <= 3
  wrongQuestions = [];
  // boolean, true if user is re answering the wrong questions
  reAnswer = false;

  close = (route) => {
    SoundService.playTab();
    if (
      this.state.finished &&
      (this.state.corrects * 100) / this.level.questions.length >= 70
    ) {
      this.props.history.go(-2);
    } else {
      this.props.history.goBack();
    }
  };

  closeFF() {
    SoundService.playStartLevel();
    this.watch = setInterval(this.inTimeOut, 100);
    this.setState({
      funFact: false,
      progressBar: true,
    });
  }

  bonus() {
    SoundService.playBonusStart();
    this.watch = setInterval(this.inTimeOut, 100);
    this.setState({
      bonus: true,
      answers: 0,
      finished: false,
    });
  }

  // this method is called only after knowing answer IS correct
  addScore() {
    if (this.state.finished) return 0;
    const question = !this.state.bonus
      ? this.reAnswer
        ? this.wrongQuestions[this.state.answers]
        : this.level.questions[this.state.answers]
      : this.level.bonus[this.state.answers];
    let number = 0;
    const divisor = this.state.bonus ? 2 : 1;
    if (question.answered_correctly === true) {
      number = question.points_given;
    } else {
      switch (
        question.attempts + (this.reAnswer && !this.state.bonus ? 1 : 0)
      ) {
        case 0:
          number = 10 / divisor;
          break;
        case 1:
          number = 8 / divisor;
          break;
        case 2:
          number = 6 / divisor;
          break;
        case 3:
          number = 4 / divisor;
          break;
        default:
          number = 0;
      }
    }
    //console.log(number);
    return number;
  }

  answerIllustration() {
    if (this.state.isCorrect) {
      return correctPNG;
    } else {
      return incorrectAnswer;
    }
  }

  continue() {
    const questionAmount = !this.state.bonus
      ? this.reAnswer
        ? this.wrongQuestions.length
        : this.level.questions.length
      : this.level.bonus.length;
    SoundService.playTab();
    // too many mistakes, end level
    if (this.wrongQuestions.length > 3) {
      this.setState({
        screen: false,
        progressBar: false,
        answers: this.level.questions.length,
        finished: true,
      });
      // all questions answered and user has miss answeres 1 to 3 times
    } else if (
      this.state.answers + 1 === this.level.questions.length &&
      this.wrongQuestions.length > 0 &&
      this.reAnswer === false
    ) {
      this.watch = setInterval(this.inTimeOut, 100);
      this.setState({
        screen: false,
        progressBar: true,
        answers: 0,
        corrects: this.state.isCorrect
          ? this.state.corrects + 1
          : this.state.corrects,
        // score: this.state.isCorrect
        //   ? this.state.score + this.addScore()
        //   : this.state.score,
        finished: false,
        petPosition: Math.floor(Math.random() * 4),
      });
      this.reAnswer = true;
    } else {
      if (this.state.answers + 1 < questionAmount) {
        this.watch = setInterval(this.inTimeOut, 100);
      }
      this.setState({
        screen: false,
        progressBar: this.state.answers + 1 === questionAmount ? false : true,
        answers: this.state.answers + 1,
        corrects: this.state.isCorrect
          ? this.state.corrects + 1
          : this.state.corrects,
        // score: this.state.isCorrect
        //   ? this.state.score + this.addScore()
        //   : this.state.score,
        finished: this.state.answers + 1 === questionAmount ? true : false,
        petPosition: Math.floor(Math.random() * 4),
      });
    }
  }

  answer(awn, correct) {
    clearInterval(this.watch);
    const time = Math.round(this.state.timer * 10) / 10;
    //console.log(time);
    const corr = JSON.parse(window.atob(correct));
    this.setState({
      screen: true,
      progressBar: false,
      isCorrect: awn.text === corr.text,
      loading: true,
      timer: 0,
    });

    const payload = {
      round: this.round,
      question: !this.state.bonus
        ? this.reAnswer
          ? this.wrongQuestions[this.state.answers].id
          : this.level.questions[this.state.answers].id
        : this.level.bonus[this.state.answers].id,
      selected_answer: awn.id,
      time_elapsed: time,
    };
    // stores answer on backend
    //console.log(payload);
    LevelsService.attempt(payload).then(
      (at) => {
        //console.log(at.data);
        const points_given = at.data.points_given;
        const score = this.state.score;

        this.setState({
          current_point_given: at.data.points_given,
          score: this.state.isCorrect ? score + points_given : score,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          error: "ERROR AL GUARDAR RESPUESTA DE USUARIO!",
          loading: false,
        });
      }
    );

    // if answer is wrong, and user didnt reAnswer yet, saves question in array
    if (!(awn.text === corr.text) && this.reAnswer === false) {
      this.wrongQuestions.push(this.level.questions[this.state.answers]);
      //console.log(this.wrongQuestions);
    }
  }

  triggerModal() {
    if (this.state.modal === false) SoundService.playExitLevel();
    this.setState({ modal: !this.state.modal });
  }

  componentDidMount() {
    LevelsService.getLevels().then((response) => {
      const data = response.data[this.state.level - 1];
      // creates an instance of Round on backend
      LevelsService.newRound(data.id).then(
        (round) => {
          this.round = round.data.id;
        },
        (err) => {
          this.setState({
            error:
              "ERROR: No pudo crearse una nueva instancia de ronda! Quizas hayas intentado acceder a un nivel bloqueado",
          });
        }
      );
      if (this.state.level <= response.data) {
        this.level = {
          name: data.name,
          funFact: data.disturbing_fact,
          questions: data.questions.filter((q) => q.is_bonus === false),
          bonus: data.questions.filter((q) => q.is_bonus === true),
          playedBonus: data.bonus_played,
          difficulty: data.difficulty,
        };
        //console.log("asdasd", this.state);
        //console.log("this.level", this.level);
        this.setState({
          funFact: true,
          loading: false,
        });
      }
    });
    //console.log(this.state.level);
  }

  inTimeOut = () => {
    this.setState({ timer: this.state.timer + 0.1 });
  };

  screen() {
    if (!this.state.finished) {
      const questText = !this.state.bonus
        ? this.reAnswer
          ? this.wrongQuestions[this.state.answers]
          : this.level.questions[this.state.answers]
        : this.level.bonus[this.state.answers];
      if (!questText.correct_feedback)
        questText.correct_feedback = "¡Respuesta correcta!";
      if (!questText.incorrect_feedback)
        questText.incorrect_feedback = "Respuesta incorrecta :(";
      this.text = this.state.isCorrect
        ? questText.correct_feedback
        : questText.incorrect_feedback;
      if (this.state.screen)
        this.state.isCorrect
          ? this.state.bonus
            ? SoundService.playBonusCorrect()
            : SoundService.playCorrect()
          : this.state.bonus
          ? SoundService.playBonusIncorrect()
          : SoundService.playIncorrect();
    }
    return (
      <CSSTransition
        in={this.state.screen}
        timeout={250}
        classNames="lqModalTransition"
        unmountOnExit
      >
        <div
          className={
            "lq-screenAnswer lq-" +
            (this.state.isCorrect ? "correct" : "incorrect")
          }
        >
          <div className="lq-sa-background">
            <div onClick={() => this.continue()} className="lq-close"></div>
            <div className="lq-title">{this.level.name}</div>
            <div className="lq-sa-image-holder">
              <div className="lq-sa-answer">
                {this.state.isCorrect
                  ? "CORRECTO +" + this.state.current_point_given
                  : "Buen intento!"}
              </div>
              <img
                className="lq-sa-image"
                src={this.answerIllustration()}
                alt="pyo"
              ></img>
            </div>
          </div>
          <div className="lq-sa-text">
            <span>{this.text}</span>
          </div>
          <button onClick={() => this.continue()} className="lq-sa-button">
            continuar
          </button>
        </div>
      </CSSTransition>
    );
  }

  funFact() {
    if (this.state.funFact) SoundService.playFF();
    return (
      <CSSTransition
        in={this.state.funFact}
        timeout={250}
        classNames="funFactTransition"
        unmountOnExit
      >
        <div className="lq-screenAnswer lq-fun-fact">
          <div className="lq-sa-background">
            <div onClick={() => this.closeFF()} className="lq-close"></div>
            <div className="lq-title">{this.level.name}</div>
            <div className="lq-sa-answer">¿SABÍAS QUE?</div>
            <div className="lq-sa-image-holder">
              <img className="lq-sa-image-ff" src={funFactPet} alt="pyo"></img>
            </div>
          </div>
          <div className="lq-sa-text">
            <span>{this.level.funFact}</span>
          </div>
          <button onClick={() => this.closeFF()} className="lq-sa-button">
            continuar
          </button>
        </div>
      </CSSTransition>
    );
  }

  overlay() {
    return <div className="overlay" onClick={() => this.triggerModal()} />;
  }

  modal() {
    return (
      <CSSTransition
        in={this.state.modal}
        timeout={150}
        classNames="exitModalTransition"
        unmountOnExit
      >
        <div className="lq-modal">
          <div className="lq-modal-title">¿Abandonar partida?</div>
          <div
            className="lq-modal-close"
            onClick={() => this.triggerModal()}
          ></div>
          <div className="lq-modal-body">
            Todo tu progreso en la partida se perderá. ¿Estás seguro?
          </div>
          <button onClick={() => this.triggerModal()} className="lq-button">
            Seguir Jugando
          </button>
          <button onClick={this.close} className="lq-button secondary">
            Salir del juego
          </button>
        </div>
      </CSSTransition>
    );
  }

  showPet(answersAmount) {
    /*move down pet if only are 2 answers instead of 3*/
    /*const onlyTwo = answersAmount < 3;
    switch (this.state.petPosition) {
      case 0:*/
    return (
      <img
        alt="pet"
        className={
          /*"lq-pet-" + this.state.petPosition + (onlyTwo ? " move-pet" : "")*/
          "lq-girl"
        }
        src={girlGlasses}
      ></img>
    );
    /*case 1:
        return (
          <img
            alt="pet"
            className={"lq-pet-" + this.state.petPosition}
            src={girlGlasses}
          ></img>
        );
      case 2:
        return (
          <img
            alt="pet"
            className={"lq-pet-" + this.state.petPosition}
            src={girlGlasses}
          ></img>
        );*/
    //default:
    /*3*/
    /*return (
          <img
            alt="pet"
            className={
              "lq-pet-" + this.state.petPosition + (onlyTwo ? " move-pet" : "")
            }
            src={girlGlasses}
          ></img>
        );
    }*/
  }

  progressB() {
    return (
      <div className="lq-progress">
        <div
          className="lq-bar"
          style={{
            width:
              +(
                (this.state.answers /
                  (!this.state.bonus
                    ? this.reAnswer
                      ? this.wrongQuestions.length
                      : this.level.questions.length
                    : this.level.bonus.length)) *
                100
              ) + "%",
          }}
        ></div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            maxWidth: 800,
            margin: "auto",
          }}
        >
          <div className="lp-content">
            <div className={"spinner1 color-" + (this.state.level % 7)}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      );
    } else if (this.state.error === null) {
      const levelsColors =
        this.level.difficulty === 0
          ? "#D78951"
          : this.level.difficulty === 1
          ? "#8B8888"
          : "#FFBA35";
      return (
        <div
          style={{
            maxWidth: 800,
            margin: "auto",
          }}
        >
          <div className={"lq-content " + this.state.style}>
            <ShareSocialMediaModal
              open={this.state.finished && Math.floor(Math.random() * 2) === 0}
            />
            <div
              onClick={() => {
                this.state.finished ? this.close() : this.triggerModal();
              }}
              className="lq-close"
            ></div>
            <div className="lq-title">{this.level.name}</div>
            <div
              style={{
                display: "block",
                textAlign: "center",
                width: 126,
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 10,
                padding: 3,
                backgroundColor: levelsColors,
                justifyContent: "center",
                fontSize: 15,
                color: "#fff",
              }}
            >
              Nivel{" "}
              {this.level.difficulty === 0
                ? "Bronce"
                : this.level.difficulty === 1
                ? "Plata"
                : "Oro"}
            </div>
            <div className="lq-timer">
              {!this.state.finished && Math.floor(this.state.timer)}
            </div>
            {!this.state.finished && this.state.petPosition !== null
              ? this.showPet(
                  !this.state.bonus
                    ? this.reAnswer
                      ? this.wrongQuestions[this.state.answers].answers.length
                      : this.level.questions[this.state.answers].answers.length
                    : this.level.bonus[this.state.answers].answers.length
                )
              : null}
            {!this.state.finished ? (
              <div className="lq-question">
                <span>
                  {!this.state.bonus
                    ? this.reAnswer
                      ? this.wrongQuestions[this.state.answers].text
                      : this.level.questions[this.state.answers].text
                    : this.level.bonus[this.state.answers].text}
                </span>
              </div>
            ) : (
              <div className="lq-finish-container">
                <div className="lq-finish-title">
                  {this.state.bonus
                    ? SoundService.playBonusFinished()
                    : (this.state.corrects * 100) /
                        this.level.questions.length >=
                      70
                    ? SoundService.playSuccess()
                    : SoundService.playFail()}
                  <h1>
                    {(this.state.corrects * 100) /
                      this.level.questions.length >=
                    70
                      ? "¡Felicidades!"
                      : "¡Buena pasada!"}
                  </h1>
                  {!this.state.bonus
                    ? (this.state.corrects * 100) /
                        this.level.questions.length >=
                      70
                      ? `Pasaste el ${
                          this.state.level === "8" || this.state.level === "15"
                            ? "nivel"
                            : "módulo"
                        }`
                      : "Intenta de nuevo"
                    : "¡Bonus Completado!"}
                  {(this.state.corrects * 100) / this.level.questions.length >=
                    70 &&
                  !this.state.bonus &&
                  this.level.bonus.length &&
                  !this.level.playedBonus ? (
                    <button onClick={() => this.bonus()} className="lq-bonus">
                      bonus
                    </button>
                  ) : null}
                </div>
              </div>
            )}
            {/*<div className="lq-body">*/}
            <div className="lq-answer-progress">
              {!this.state.finished ? (
                <div className="lq-answer-content">
                  {!this.state.bonus
                    ? this.reAnswer
                      ? this.wrongQuestions[this.state.answers].answers.map(
                          (ans) => (
                            <div
                              onClick={() =>
                                this.answer(
                                  ans,
                                  this.wrongQuestions[this.state.answers]
                                    .correct
                                )
                              }
                              className="lq-answer"
                            >
                              {ans.text}
                            </div>
                          )
                        )
                      : this.level.questions[this.state.answers].answers.map(
                          (ans) => (
                            <div
                              onClick={() =>
                                this.answer(
                                  ans,
                                  this.level.questions[this.state.answers]
                                    .correct
                                )
                              }
                              className="lq-answer"
                            >
                              {ans.text}
                            </div>
                          )
                        )
                    : this.level.bonus[this.state.answers].answers.map(
                        (ans) => (
                          <div
                            onClick={() =>
                              this.answer(
                                ans,
                                this.level.bonus[this.state.answers].correct
                              )
                            }
                            className="lq-answer"
                          >
                            {ans.text}
                          </div>
                        )
                      )}
                </div>
              ) : (
                <div className="flexBox">
                  <div className="lq-finish-stats">
                    <p>
                      Respuestas correctas: {this.state.corrects} /{" "}
                      {this.state.bonus
                        ? this.level.questions.length + this.level.bonus.length
                        : this.level.questions.length}
                    </p>
                    <p>Puntuación: {this.state.score}</p>
                    <button onClick={() => this.close()} className="lqf-button">
                      Continuar
                    </button>
                  </div>
                </div>
              )}
              {this.state.progressBar ? this.progressB() : null}
            </div>
            {/*</div>*/}
            {this.modal()}
            {this.state.modal ? this.overlay() : null}
            {this.level ? this.screen() : null}
            {this.funFact()}
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            maxWidth: 800,
            margin: "auto",
          }}
        >
          <div style={{ color: "#A00000" }}>
            {this.state.error}
            <button onClick={() => this.close()} className="lq-sa-button">
              Salir
            </button>
          </div>
        </div>
      );
    }
  }
}
export default LevelQuestions;
