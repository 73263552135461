import axios from "axios";
import AuthService from "./services/auth";
import secrets from "./secrets.json";
const api = axios.create({
  // baseURL: 'https://laberapi.girolabs.com/',
  baseURL: secrets.baseURL,
  headers: {
    get: {
      "Content-Type": "application/json",
    },
    post: {
      "Content-Type": "application/json",
    },
    put: {
      "Content-Type": "application/json",
    },
    patch: {
      "Content-Type": "application/json",
    },
    common: {
      "Content-Type": "application/json",
    },
  },
});

// Autentica a todas las consultas a la API que no sean del login mismo
api.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("laberinfo_user_token");
    if (config.url.substr(0, 2) === "v1" && userToken !== null) {
      config.headers["Authorization"] = "Bearer " + userToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    // el retry impide que siga probando infinitamente de renovar el token si eso aun no autoriza al usuario de obtener la respuesta
    if (
      error.response?.data?.detail ===
        "Invalid token header. No credentials provided." &&
      localStorage.getItem("laberinfo_refresh_token") &&
      !originalRequest.retry
    ) {
      originalRequest._retry = true;
      return AuthService.doRefreshToken().then(
        (res) => {
          if (res.statusText === "OK") {
            AuthService.setToken(res.data.access_token);
            AuthService.setRefreshToken(res.data.refresh_token);
            originalRequest.headers["Authorization"] =
              "Bearer " + res.data.access_token;
            console.log("NUEVA CONF: ");
            console.log(originalRequest);
            return axios(originalRequest);
          }
        },
        (error) => {
          console.log("Error al renovar token");
          // En un futuro, aca se mostrará un modal avisando que hubo un error al renovar token y luego se le manda al login
        }
      );
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
