import React from "react";
import "../App.css";
import { Link, useLocation } from "react-router-dom";

// logos
import gameController from "../assets/images/gameController.svg";
import personCircle from "../assets/images/personCircle.svg";
import trophie from "../assets/images/trophie.svg";

// active logos
import gameControllerActive from "../assets/images/gameControllerActive.png";
import personCircleActive from "../assets/images/personCircleActive.png";
import trophieActive from "../assets/images/trophieActive.png";

function Tab() {
  let location = useLocation();
  if (
    location.pathname === "/dashboard" ||
    location.pathname === "/profile" ||
    location.pathname === "/home"
  ) {
    return (
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
        }}
      >
        <div className="Tab">
          {location.pathname !== "/dashboard" ? (
            <Link to="/dashboard" className="tab-link">
              <img height="35px" src={trophie} alt=""></img>
              <div>Rankings</div>
            </Link>
          ) : (
            <div className="tab-link tb-active">
              <img height="35px" src={trophieActive} alt=""></img>
              <div>Rankings</div>
            </div>
          )}
          {location.pathname !== "/home" ? (
            <Link to="/home" className="tab-link">
              <img height="35px" src={gameController} alt=""></img>
              <div>Jugar</div>
            </Link>
          ) : (
            <div className="tab-link tb-active">
              <img height="35px" src={gameControllerActive} alt=""></img>
              <div>Jugar</div>
            </div>
          )}
          {location.pathname !== "/profile" ? (
            <Link to="/profile" className="tab-link">
              <img height="35px" src={personCircle} alt=""></img>
              <div>Perfil</div>
            </Link>
          ) : (
            <div to="/profile" className="tab-link tb-active">
              <img height="35px" src={personCircleActive} alt=""></img>
              <div>Perfil</div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
export default Tab;
