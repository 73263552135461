import React, { Component } from "react";
import back1 from "../assets/images/modulos/back1.svg";
import "../spinners.css";
import LevelsService from "../services/levels";
import SoundService from "../services/sound";

import paraguayoite1 from "../assets/images/modulos/paraguayoite1.svg";
import paraguayoite2 from "../assets/images/modulos/paraguayoite2.svg";
import paraguayoite3 from "../assets/images/modulos/paraguayoite3.svg";
import paraguayoite4 from "../assets/images/modulos/paraguayoite4.svg";
import paraguayoite5 from "../assets/images/modulos/paraguayoite5.svg";
import paraguayoite6 from "../assets/images/modulos/paraguayoite6.svg";
import paraguayoite7 from "../assets/images/modulos/paraguayoite7.svg";
import avatar1 from "../assets/images/modulos/avatar1.png";
import avatar2 from "../assets/images/modulos/avatar2.png";
import avatar3 from "../assets/images/modulos/avatar3.png";
import avatar4 from "../assets/images/modulos/avatar4.png";
import avatar5 from "../assets/images/modulos/avatar5.png";
import avatar6 from "../assets/images/modulos/avatar6.png";
import avatar7 from "../assets/images/modulos/avatar7.png";
import avatar8 from "../assets/images/modulos/avatar8.png";
import profilePicture from "../assets/images/profile-picture.png";

class LevelPage extends Component {
  state = {
    lvl: this.props.match.params.id,
    loading: true,
  };

  level = null;

  goBack = (route) => {
    SoundService.playTab();
    this.props.history.goBack();
  };

  goToQuestions = (route) => {
    SoundService.playTab();
    this.props.history.push("/preguntas/" + this.state.lvl);
  };

  componentDidMount() {
    LevelsService.getLevels().then((response) => {
      if (this.state.lvl <= response.data) {
        this.level = response.data[this.state.lvl - 1];
        this.setState({ loading: false });
      }
    });
  }

  avatar() {
    switch (this.state.lvl) {
      case "1":
        return avatar1;
      case "2":
        return avatar2;
      case "3":
        return avatar3;
      case "4":
        return avatar4;
      case "5":
        return avatar5;
      case "6":
        return avatar6;
      case "7":
        return avatar7;
      case "8":
        return avatar8;
      case "9":
        return avatar1;
      case "10":
        return avatar2;
      case "11":
        return avatar3;
      case "12":
        return avatar4;
      case "13":
        return avatar5;
      case "14":
        return avatar6;
      case "15":
        return avatar7;
      case "16":
        return avatar8;
      case "17":
        return avatar1;
      case "18":
        return avatar2;
      case "19":
        return avatar3;
      case "20":
        return avatar4;
      case "21":
        return avatar5;
      default:
        return avatar1;
    }
  }

  paraguayoite() {
    switch (this.state.lvl % 7) {
      case 1:
        return paraguayoite1;
      case 2:
        return paraguayoite2;
      case 3:
        return paraguayoite3;
      case 4:
        return paraguayoite4;
      case 5:
        return paraguayoite5;
      case 6:
        return paraguayoite6;
      case 0:
        return paraguayoite7;
      /*case 7:
        return paraguayoite1;
      case 8:
        return paraguayoite2;
      case 9:
        return paraguayoite3;
      case 10:
        return paraguayoite4;
      case 11:
        return paraguayoite5;
      case 12:
        return paraguayoite6;
      case 13:
        return paraguayoite7;
      case 14:
        return paraguayoite1;
      case 15:
        return paraguayoite2;
      case 16:
        return paraguayoite3;
      case 17:
        return paraguayoite4;
      case 18:
        return paraguayoite5;
      case 19:
        return paraguayoite6;
      case 20:
        return paraguayoite7;
      case 21:
        return paraguayoite7;*/
      default:
        return null;
    }
  }

  showContent() {
    if (this.state.loading) {
      return (
        <div className="lp-content">
          <div className={"spinner1 color-" + (this.state.lvl % 7)}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      );
    } else {
      const levelsColors =
        this.level.difficulty === 0
          ? "#D78951"
          : this.level.difficulty === 1
          ? "#8B8888"
          : "#FFBA35";
      return (
        <div className={`lp-content lp-bg${this.state.lvl % 7}`}>
          <img
            className={"lp-back"}
            onClick={this.goBack}
            src={back1}
            alt="back"
          ></img>
          <div className="lp-title">{this.level.name}</div>
          <div
            style={{
              display: "block",
              textAlign: "center",
              width: "126px",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: 10,
              padding: 3,
              backgroundColor: levelsColors,
              justifyContent: "center",
              fontSize: 15,
              color: "#fff",
            }}
          >
            Nivel{" "}
            {this.level.difficulty === 0
              ? "Bronce"
              : this.level.difficulty === 1
              ? "Plata"
              : "Oro"}
          </div>
          <img className="lp-levelAvatar" src={this.avatar()} alt="pyite"></img>
          <div className="lp-spaceForImage">
            <button onClick={this.goToQuestions} className="lp-startButton">
              Empezar
            </button>
          </div>
          <div className="lp-friends">
            Mira quienes ya pasaron de nivel
            <div className="lpf-list">
              {this.level.users_who_passed.users.map((i) => (
                <div className="lpf-item">
                  <img
                    class="lpf-avatar"
                    src={i.avatar ? i.avatar : profilePicture}
                    alt={i.user}
                  ></img>
                  <div className="lpf-name">{i.user}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    switch (+this.state.lvl % 7) {
      case 1:
        return (
          <div
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <div className="LevelPage lp-level1"></div>
            {this.showContent()}
          </div>
        );
      case 2:
        return (
          <div
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <div className="LevelPage lp-level2">{this.showContent()}</div>
          </div>
        );
      case 3:
        return (
          <div
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <div className="LevelPage lp-level3">{this.showContent()}</div>
          </div>
        );
      case 4:
        return (
          <div
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <div className="LevelPage lp-level4">{this.showContent()}</div>
          </div>
        );
      case 5:
        return (
          <div
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <div className="LevelPage lp-level5">{this.showContent()}</div>
          </div>
        );
      case 6:
        return (
          <div
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <div className="LevelPage lp-level6">{this.showContent()}</div>
          </div>
        );
      case 0:
        return (
          <div
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <div className="LevelPage lp-level7">{this.showContent()}</div>
          </div>
        );
      default:
        //console.log("asdasasd");
        return (
          <div
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <div className="LevelPage lp-level1">{this.showContent()}</div>
          </div>
        );
    }
  }
}
export default LevelPage;
