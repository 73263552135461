import React, { useEffect } from "react";
import "./ReportsOptions.css";
import { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  Redirect,
} from "react-router-dom";
import { QuestionsReport } from "../pages/QuestionsReport/QuestionsReport";
import "../pages/QuestionsReport/QuestionsReport.css";
import { TimeReport } from "../pages/TimeReport/TimeReport";
import { UsersReport } from "../pages/UsersReport/UsersReport";
import "../App.css";
import AuthService from "../services/auth";

export const ReportsOptions = (props) => {
  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let currentReport = location.pathname.slice(1);
    //console.log(currentReport);
    if (
      currentReport === "stats/questionReport" ||
      currentReport === "stats" ||
      currentReport === "stats/"
    ) {
      setActive(true);
    } else if (currentReport === "stats/timeReport") {
      setActive2(true);
    } else if (currentReport === "stats/userReport") {
      setActive3(true);
    }
  }, []);

  const logout = () => {
    AuthService.logout();
    props.history.replace("/loginDashboard");
  };

  return (
    <>
      <div
        className="container"
        /*sytle={{
        height: 1186,
        width: 1440,
        backgroundColor: "#fff",
      }}*/
      >
        <Router>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              height: 90,
              width: "100%",
              paddingLeft: 120,
              paddingRight: 120,
              boxSizing: "border-box",
              justifyContent: "space-between",
            }}
          >
            <div>
              <button
                className={active ? "button button-active" : "button"}
                onClick={() => {
                  setActive(true);
                  setActive2(false);
                  setActive3(false);
                }}
              >
                <Link
                  to="/stats/questionReport"
                  className={active ? "link-active" : "link"}
                >
                  Preguntas
                </Link>
              </button>
              <button
                className={
                  active2 ? "button button2 button-active" : "button button2"
                }
                onClick={() => {
                  setActive2(true);
                  setActive(false);
                  setActive3(false);
                }}
              >
                <Link
                  to="/stats/timeReport"
                  className={active2 ? "link-active" : "link"}
                >
                  Tiempo de juego
                </Link>
              </button>
              <button
                className={active3 ? "button button-active" : "button"}
                onClick={() => {
                  setActive3(true);
                  setActive(false);
                  setActive2(false);
                }}
              >
                <Link
                  to="/stats/userReport"
                  className={active3 ? "link-active" : "link"}
                >
                  Usuarios
                </Link>
              </button>
            </div>

            <button
              onClick={logout}
              className="button"
              style={{
                color: "black",
                borderColor: "black",
              }}
            >
              Cerrar sesión
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <hr
              style={{
                width: "100%",
                border: "1px solid #f2f2f2",
                margin: 0,
              }}
            />
          </div>
          <Route exact path="/stats">
            <Redirect to="/stats/questionReport" />
          </Route>
          <Route exact path="/stats/questionReport">
            <QuestionsReport />
          </Route>
          <Route exact path="/stats/timeReport">
            <TimeReport />
          </Route>
          <Route exact path="/stats/userReport">
            <UsersReport />
          </Route>
        </Router>
      </div>
    </>
  );
};
