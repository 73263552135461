import React, { Component } from "react";
import "./landing.css";
import SoundService from "../../services/sound";
import { CSSTransition } from "react-transition-group";
import exclamationMark from "../../assets/images/exclamationMark.svg";
import laberinfoLogo from "../../assets/images/laberinfoLogo.png";
import reaccionLogo from "../../assets/images/reaccionW.svg";
import closeModal from "../../assets/images/interface/closeModal.svg";
import arrowPrevious from "../../assets/images/interface/arrowPrevious.svg";
import arrowNext from "../../assets/images/interface/arrowNext.svg";
import institutionicon from "../../assets/images/institutionicon.svg";
import screen1 from "../../assets/images/screen1.png";
import screen2 from "../../assets/images/screen2.png";
import screen3 from "../../assets/images/screen3.png";
import screen4 from "../../assets/images/screen4.png";
import screen5 from "../../assets/images/screen5.png";
import screen6 from "../../assets/images/screen6.png";
import screen7 from "../../assets/images/screen7.png";
import screen8 from "../../assets/images/screen8.png";
import screen9 from "../../assets/images/screen9.png";
import StatsService from "../../services/stats";
import close from "../../assets/images/interface/closeModal.svg";

class LandingPage extends Component {
  state = {
    howToPlayPage: 1,
    modal: false,
    description: false,
    topInstitution: [],
    showModalRanking: false,
  };

  screens = {
    s1: screen1,
    s2: screen2,
    s3: screen3,
    s4: screen4,
    s5: screen5,
    s6: screen6,
    s7: screen7,
    s8: screen8,
    s9: screen9,
  };

  closeRankingModal() {
    this.setState({
      showModalRanking: false,
    });
  }
  openRankModal() {
    this.setState({
      showModalRanking: true,
    });
  }
  componentDidMount() {
    StatsService.getRanking().then((stats) => {
      //console.log(stats);
      const topInstitution = stats.data.institution_ranking.slice(0, 3);
      this.setState({ topInstitution: topInstitution });
    });
  }
  goToApp = (route) => {
    SoundService.playTab();
    this.props.history.push("/home");
  };

  triggerModal() {
    SoundService.playTab();
    this.setState({ modal: !this.state.modal });
  }

  triggerDescription() {
    SoundService.playTab();
    this.setState({ description: !this.state.description });
  }

  previousPage() {
    SoundService.playTab();
    this.setState({ howToPlayPage: this.state.howToPlayPage - 1 });
  }

  nextPage() {
    SoundService.playTab();
    this.setState({ howToPlayPage: this.state.howToPlayPage + 1 });
  }

  overlay() {
    return <div className="overlay" onClick={() => this.triggerModal()} />;
  }

  overlayDescription() {
    return (
      <div className="overlay" onClick={() => this.triggerDescription()} />
    );
  }

  rankModal() {
    return (
      <CSSTransition
        in={this.state.showModalRanking}
        timeout={150}
        classNames="modalTransition"
        unmountOnExit
      >
        <div className="ranking-modal">
          <div className="pa-title">Top Ranking</div>
          <img
            height="25px"
            onClick={() => this.closeRankingModal()}
            className="pa-closeButton"
            src={close}
            alt="x"
          />
          <div className="ps-scroll">
            {this.state.topInstitution.map((r, index) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="d-ranking-position">{index + 1}</div>
                <img
                  height="20"
                  src={institutionicon}
                  style={{ marginRight: "26px" }}
                />

                <div
                  className="d-ranking-description"
                  style={{ textAlign: "left" }}
                >
                  {r.institution}
                </div>
                <div className="d-ranking-points">
                  {Math.round(r.avg_points)}
                </div>
              </div>
            ))}
          </div>
          <div className="ps-button-container">
            <button
              onClick={() => this.closeRankingModal()}
              className="ps-button"
            >
              Aceptar
            </button>
          </div>
        </div>
      </CSSTransition>
    );
  }

  descriptionModal() {
    return (
      <CSSTransition
        in={this.state.description}
        timeout={150}
        classNames="modalTransition"
        unmountOnExit
      >
        <div className="home-modal">
          <div className="modal-header">
            ¿Qué es LaberINFO?
            <img
              onClick={() => this.triggerDescription()}
              className="modal-closeButton"
              src={closeModal}
              alt="x"
            />
          </div>
          <div className="mld-content">
            <div className="modal-laberinfo-description">
              El juego LaberINFO permite a las comunidades educativas aprender
              de una manera divertida y ágil todos los complejos procesos
              administrativos del FONACIDE. Respondiendo preguntas y compitiendo
              con otras escuelas y colegios, los usuarios obtienen información
              relevante para comprender el manejo del fondo nacional y cómo
              podrían monitorearlo.
            </div>
          </div>
          <div className="ps-button-container">
            <button
              onClick={() => this.triggerDescription()}
              className="ps-button"
            >
              Aceptar
            </button>
          </div>
        </div>
      </CSSTransition>
    );
  }

  howToPlayContent() {
    let text;
    let image;
    switch (this.state.howToPlayPage) {
      case 1:
        text = "Elegí el primer nivel y dale “Empezar”.";
        image = this.screens.s1;
        break;
      case 2:
        text = "Leé la pregunta sin apuros y elegí la respuesta correcta.";
        image = this.screens.s2;
        break;
      case 3:
        text = "Dale “Continuar” y respondé todas las preguntas del nivel.";
        image = this.screens.s3;
        break;
      case 4:
        text =
          "Si fallás 4 preguntas, tenés que repetir el nivel completo ¡sí o sí!";
        image = this.screens.s4;
        break;
      case 5:
        text =
          "¡Respondé bien las preguntas en las que te equivocaste y aumenta tu puntaje!";
        image = this.screens.s5;
        break;
      case 6:
        text =
          "Ahora que ya dominás este nivel del LaberINFO, ¡podés jugar el “Bonus” para ganar más puntos!";
        image = this.screens.s6;
        break;
      case 7:
        text =
          "Podés recuperar la mitad de tus puntos perdidos, si en una repetición del nivel, respondés bien al 100% de las preguntas";
        image = this.screens.s7;
        break;
      case 8:
        text = "¡Desbloquea todos los niveles, ganá y accedé a tu certificado!";
        image = this.screens.s8;
        break;
      case 9:
        text =
          "Cuando repetís niveles, solo suman los puntos de las respuestas en las que antes te equivocaste. ¡Y solo hasta el tercer intento!";
        image = this.screens.s9;
        break;
      default:
        text = "";
        image = screen1;
    }
    return (
      <div className="modal-content">
        <h1 className="modal-content-page">{this.state.howToPlayPage}</h1>
        <span className="modal-description">{text}</span>
        <div className="modal-image-container">
          <img className="modal-image" src={image} alt="screen1"></img>
        </div>
      </div>
    );
  }

  modal() {
    return (
      <CSSTransition
        in={this.state.modal}
        timeout={150}
        classNames="modalTransition"
        unmountOnExit
      >
        <div className="home-modal">
          <div className="modal-header">
            ¿CÓMO JUGAR?
            <img
              onClick={() => this.triggerModal()}
              className="modal-closeButton"
              src={closeModal}
              alt="x"
            />
          </div>
          {this.howToPlayContent()}
          <div className="modal-footer" style={{ marginTop: "10px" }}>
            <span className="arrow-container">
              {this.state.howToPlayPage !== 1 ? (
                <img
                  onClick={() => this.previousPage()}
                  src={arrowPrevious}
                  alt="previous"
                ></img>
              ) : null}
            </span>
            {this.state.howToPlayPage === 9 ? (
              <button
                onClick={() => this.triggerModal()}
                className="modal-okButton"
              >
                OK
              </button>
            ) : (
              <div className="empty-button" />
            )}
            <span className="arrow-container">
              {this.state.howToPlayPage !== 9 ? (
                <img
                  onClick={() => this.nextPage()}
                  src={arrowNext}
                  alt="next"
                ></img>
              ) : null}
            </span>
            <div className="modal-pages">
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 1 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 2 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 3 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 4 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 5 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 6 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 7 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 8 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 9 ? "mp-enabled" : "")
                }
              ></div>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }

  render() {
    return (
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
        }}
      >
        <div className="LandingPage">
          <div
            className="exclamationMark-container"
            onClick={() => this.triggerDescription()}
          >
            <img src={exclamationMark} alt="exclamationMark" />
          </div>
          <div>
            <img
              src={laberinfoLogo}
              alt="laberinfo"
              height="90"
              width="auto"
            ></img>
          </div>
          {/* <div className="brief-description-container">
          Dolor sit amet, consectetur adipiscing elit. Id placerat maecenas
          bibendum.
        </div> */}
          <div>
            <button
              onClick={() => this.goToApp()}
              // className="landing-button-play"
              className="landing-htpButton landing-button-play"
            >
              Jugar
            </button>
          </div>
          <div>
            <button
              onClick={() => this.triggerModal()}
              className="landing-htpButton"
            >
              Cómo Jugar
            </button>
          </div>

          <div className="landing-footer center-button">
            <div
              className="button-ranking"
              onClick={() => this.openRankModal()}
            >
              Ranking Competidores
            </div>
          </div>
          <img
            src={reaccionLogo}
            className="reaccion-logo"
            alt="reaccion"
          ></img>
          {this.modal()}
          {this.rankModal()}

          {this.state.modal ? this.overlay() : null}
          {this.descriptionModal()}
          {this.state.description ? this.overlayDescription() : null}
        </div>
      </div>
    );
  }
}
export default LandingPage;
