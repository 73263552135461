import React, { Component } from "react";
/*import LevelsService from '../../services/levels';*/
import StatsService from "../../services/stats";
import SoundService from "../../services/sound";
// import { Pie, PieChart, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell } from 'recharts';
import { CSSTransition } from "react-transition-group";
import institutionicon from "../../assets/images/institutionicon.svg";
import usericon from "../../assets/images/personCircle.svg";
import townshipicon from "../../assets/images/location.svg";
import close from "../../assets/images/interface/closeModal.svg";
class Dashboard extends Component {
  state = {
    /*activeTab: 'general',*/
    loading: true,
    level: 1,
    pointsRankTab: "users",
    rankingModal: null,
    showModal: false,
    /*genderData: [],
        ageData: [],*/
    currentLevelStats: {},
  };

  stats = {};

  /*levels = [];*/

  colors = [
    "#58CDF6",
    "#7DCE30",
    "#FFA026",
    "#FFBA35",
    "#00D8CB",
    "#FF6A7C",
    "#7593FF",
  ];

  componentDidMount() {
    /*LevelsService.getLevels().then((res) => {
            this.levels = res.data.map(l => {
                return {
                    title: l.name,
                    id: l.order
                };
            });*/
    StatsService.getStats().then((stats) => {
      //console.log(stats.data);
      let i1 = 0,
        i2 = 0,
        i3 = 0;
      this.stats = {
        general: {
          usersAmount: stats.data.user_qty,
          males: stats.data.males,
          females: stats.data.females,
          ageRange: stats.data.user_qty_by_age_rage,
          scoreRanking: stats.data.user_ranking.map((r) => {
            i1++;
            return {
              rank: i1,
              name: r.user,
              avatar: r.avatar,
              points: Math.round(r.points),
            };
          }),
          institutionRanking: stats.data.institution_ranking.map((r) => {
            i2++;
            return {
              rank: i2,
              name: r.institution,
              points: Math.round(r.avg_points) + " p/u",
            };
          }),
          townshipRanking: stats.data.district_ranking.map((r) => {
            i3++;
            return {
              rank: i3,
              name: r.district,
              points: Math.round(r.avg_points) + " p/u",
            };
          }),
        },
        /*levels: stats.data.levels,
                    correctsPerSchool: stats.data.correct_answers_percentage_per_institution.map(l => {
                        i3 = 0;
                        return {
                            lvl: l.level_no,
                            ranking: l.institutions.map(r => {
                                i3++;
                                return {
                                    rank: i3,
                                    name: r.institution,
                                    points: Math.round(r.correct_answers_percentage) + '%'
                                }
                            })
                        };
                    })*/
      };
      //console.log(this.stats);
      // update graphics
      this.setState({
        /*genderData: [
                        { name: 'Mujeres', value: this.stats.general.females, fill: "#FF6A7C" },
                        { name: 'Hombres', value: this.stats.general.males, fill: "#7593FF" },
                        { name: 'Sin responder', value: stats.data.user_qty - stats.data.males - stats.data.females, fill: "#FFA026" },
                        { name: 'Prefirieron no responder', value: notGender, fill: "#FFA026" }
                    ],
                    ageData: [ // waiting until we have this stats from backend
                        { name: 'menos de 10 años', cantidad: this.stats.general.ageRange.less_10 },
                        { name: '10-12 años', cantidad: this.stats.general.ageRange.ten_twelve },
                        { name: '13-15 años', cantidad: this.stats.general.ageRange.thirteen_fifteen },
                        { name: '16-18 años', cantidad: this.stats.general.ageRange.sixteen_eighteen },
                        { name: '19-30 años', cantidad: this.stats.general.ageRange.nineteen_thirty },
                        { name: 'más de 30 años', cantidad: this.stats.general.ageRange.more_30 },
                    ],*/
        /*currentLevelStats: this.stats.levels[1],*/ // set first lvl as default
        rank: this.stats.general.scoreRanking, // set a default rank to show in modal, this is just for animation to work
      });
      this.setState({ loading: false });
    });

    /*});*/
  }

  /*openTab(tab) {
        SoundService.playTab();
        if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
    }*/

  openPointsRankTab(tab) {
    if (this.state.pointsRankTab !== tab) this.setState({ pointsRankTab: tab });
  }

  closeRankingModal() {
    SoundService.playTab();
    this.setState({
      showModal: false,
    });
  }

  openRankModal(whichRanking) {
    SoundService.playTab();
    let rank = null;
    switch (whichRanking) {
      case "score-users":
        rank = this.stats.general.scoreRanking;
        break;
      case "score-schools":
        rank = this.stats.general.institutionRanking;
        break;
      case "correctsPerSchool":
        rank = this.stats.correctsPerSchool[this.state.level - 1].ranking;
        break;
      case "score-township":
        rank = this.stats.general.townshipRanking;
        break;
      default:
        rank = this.stats.general.scoreRanking;
    }
    this.setState({
      rankingModal: rank,
      showModal: true,
    });
  }

  setCurrentLevelStats(event) {
    this.setState({
      currentLevelStats: this.stats.levels[event.target.value],
      level: event.target.value,
    });
  }

  getPercentage() {
    return (
      Math.round(this.state.currentLevelStats.correct_answers_percentage) + "%"
    );
  }

  rankModal() {
    return (
      <CSSTransition
        in={this.state.showModal}
        timeout={150}
        classNames="modalTransition"
        unmountOnExit
      >
        <div className="ranking-modal">
          <div className="pa-title">Ranking</div>
          <img
            height="25px"
            onClick={() => this.closeRankingModal()}
            className="pa-closeButton"
            src={close}
            alt="x"
          />
          <div className="ps-scroll">
            {this.state.rankingModal.map((r) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="d-ranking-position">{r.rank}</div>
                {this.state.pointsRankTab === "users" ? (
                  <img
                    height="20"
                    src={r.avatar ? r.avatar : usericon}
                    style={{ marginRight: "26px" }}
                  />
                ) : this.state.pointsRankTab === "schools" ? (
                  <img
                    height="20"
                    src={r.avatar ? r.avatar : institutionicon}
                    style={{ marginRight: "26px" }}
                  />
                ) : (
                  <img
                    height="20"
                    src={townshipicon}
                    style={{ marginRight: "26px" }}
                  />
                )}

                <div className="d-ranking-description">{r.name}</div>
                <div className="d-ranking-points">{r.points}</div>
              </div>
            ))}
          </div>
          <div className="ps-button-container">
            <button
              onClick={() => this.closeRankingModal()}
              className="ps-button"
            >
              Aceptar
            </button>
          </div>
        </div>
      </CSSTransition>
    );
  }

  dashboardContent() {
    /*if (this.state.activeTab === 'general') {*/
    return this.generalContent();
    /*} else if (this.state.activeTab === 'perLevel') {
            return this.perLevelContent();
        } else {
            return (<div>Error</div>);
        }*/
  }

  overlay() {
    return <div className="overlay" onClick={() => this.closeRankingModal()} />;
  }

  rankingPoints() {
    if (this.state.pointsRankTab === "users") {
      return (
        <div>
          {this.stats.general.scoreRanking.slice(0, 7).map((r) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="d-ranking-position">{r.rank}</div>
              <img
                height="20"
                src={r.avatar ? r.avatar : usericon}
                style={{ marginRight: "26px" }}
              />
              <div className="d-ranking-description">{r.name}</div>
              <div className="d-ranking-points">{r.points}</div>
            </div>
          ))}
        </div>
      );
    } else if (this.state.pointsRankTab === "schools") {
      return (
        <div>
          {this.stats.general.institutionRanking.slice(0, 7).map((r) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="d-ranking-position">{r.rank}</div>
              <img
                height="20"
                src={institutionicon}
                style={{ marginRight: "26px" }}
              />

              <div className="d-ranking-description">{r.name}</div>
              <div className="d-ranking-points">{r.points}</div>
            </div>
          ))}
        </div>
      );
    } else if (this.state.pointsRankTab === "township") {
      return (
        <div>
          {this.stats.general.townshipRanking.slice(0, 7).map((r) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="d-ranking-position">{r.rank}</div>
              <img
                height="20"
                src={townshipicon}
                style={{ marginRight: "26px" }}
              />

              <div className="d-ranking-description">{r.name}</div>
              <div className="d-ranking-points">{r.points}</div>
            </div>
          ))}
        </div>
      );
    }
  }

  generalContent() {
    return (
      <div>
        <div className="dashboard-title">
          Cantidad de usuarios: {this.stats.general.usersAmount}
        </div>
        <div className="dashboard-subtitle">Rankings</div>
        <div className="division-line" />
        <div className="division-line" />
        <div className="d-tab-container">
          <div
            onClick={() => this.openPointsRankTab("users")}
            className={
              "d-ranking-tab" +
              (this.state.pointsRankTab === "users" ? " d-tab-active" : "")
            }
          >
            Usuarios
          </div>
          <div
            onClick={() => this.openPointsRankTab("schools")}
            className={
              "d-ranking-tab" +
              (this.state.pointsRankTab === "schools" ? " d-tab-active" : "")
            }
          >
            Instituciones
          </div>
          <div
            onClick={() => this.openPointsRankTab("township")}
            className={
              "d-ranking-tab" +
              (this.state.pointsRankTab === "township" ? " d-tab-active" : "")
            }
          >
            Municipios
          </div>
        </div>

        {this.rankingPoints()}
        <div className="dashboard-link-container">
          <span
            onClick={() =>
              this.openRankModal("score-" + this.state.pointsRankTab)
            }
            className="dashboard-link"
          >
            ver completo
          </span>
        </div>
      </div>
    );
    // graphs
    /*<div className="dashboard-subtitle d-s-graphics">Gráficos <span style={{ fontSize: '11px' }}> (desliza para ver)</span></div>
            <div className="d-graphics-container">
                <div className="d-graphics-card">
                    <div className="d-graphic-title">Género</div>
                    <div className="d-graphic">
                        <PieChart width={225} height={170}>
                            <Pie data={this.state.genderData} dataKey="value" cx={110} cy={82} innerRadius={35} outerRadius={50} label />
                        </PieChart>
                    </div>
                    <div className="d-graphic-description">
                        <p className="d-girls">Mujeres</p>
                        <p className="d-boys">Hombres</p>
                        <p className="d-notGender">Sin responder</p>
                    </div>
                </div>
                <div className="d-graphics-card">
                    <div className="d-graphic-title">Edad</div>
                    <div className="d-graphic">
                        <BarChart
                            width={305}
                            height={170}
                            data={this.state.ageData}
                            margin={{
                                left: -15,
                            }}
                        >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="cantidad" barSize={20} fill="#58CDF6" barlabel={{ position: 'top' }}>
                                {
                                    this.state.ageData.map((entry, index) => (
                                        <Cell fill={this.colors[index % 7]} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </div>
                </div>
            </div>*/
  }

  /*perLevelContent() {
        return (<div>
            <select onChange={(event) => this.setCurrentLevelStats(event)} className="d-dropdown">
                {this.levels.map(l =>
                    <option value={l.id}>{l.title}</option>
                )}
            </select>
            <div className="d-data-number">{this.state.currentLevelStats.users_won}</div>
            <div className="d-data-description">Usuarios superaron este nivel</div>
            <div className="d-data-number">0</div>
            <div className="d-data-description">Usuarios superaron el bonus</div>
            <div className="d-data-number">{this.state.currentLevelStats.correct_answers_percentage ? this.getPercentage() : '0%'}</div>
            <div className="d-data-description">De las respuestas fueron correctas</div>
            <div className="d-ranking-title">respuestas correctas por institución</div>
            {this.stats.correctsPerSchool[this.state.level - 1].ranking.slice(0,3).map((r) => 
                <div>
                    <div className="d-ranking-position">{r.rank}</div>
                    <div className="d-ranking-description">{r.name}</div>
                    <div className="d-ranking-points">{r.points}</div>
                </div>
            )}
            
            <div className="dashboard-link-container">
                <span onClick={() => this.openRankModal('correctsPerSchool')} className="dashboard-link">ver completo</span>
            </div>
        </div>);
    }*/

  spinner() {
    return (
      <div className="spinner1 color-default">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }

  render() {
    /*<div className="dashboard-tabs">
            <div onClick={() => this.openTab('general')} className={'dashboard-tab ' + (this.state.activeTab === 'general' ? 'd-tab-active' : '')}>General</div>
            <div onClick={() => this.openTab('perLevel')} className={'dashboard-tab ' + (this.state.activeTab === 'perLevel' ? 'd-tab-active' : '')}>Por nivel</div>
        </div>*/
    return (
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
        }}
      >
        <div className="Dashboard">
          {this.state.loading ? this.spinner() : this.dashboardContent()}
          {this.state.showModal ? this.overlay() : null}
          {this.state.rankingModal ? this.rankModal() : null}
        </div>
      </div>
    );
  }
}
export default Dashboard;
