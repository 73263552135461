import React, { useState, useEffect } from "react";
import MaterialTable, { Column } from "@material-table/core";
//import { makeStyles } from "@material-ui/core/styles";
import html2canvas from "html2canvas";
import download from "../QuestionsReport/icons/download.png";
import "./TimeReport.css";
import LevelsService from "../../services/levels";
import ProfileService from "../../services/profile";
import ReportsService from "../../services/reports";

let citiesDb = [];
let institutionsDb = [];
let usersDb = [];

let departmentFlag = false;
let districtFlag = false;

export const TimeReport = () => {
  const [data, setData] = useState([]);
  const [department, setDepartment] = useState();
  const [departments, setDepartments] = useState([]);
  const [city, setCity] = useState(); // city = district
  const [cities, setCities] = useState([]);
  const [institution, setInstitution] = useState();
  const [institutions, setInstitutions] = useState([]);
  const [rol, setRol] = useState();
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState();
  const [users, setUsers] = useState([]);
  const [modul, setModule] = useState();
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!departmentFlag && !districtFlag) {
      //setLoading(true);
      let filtersAux = {};
      if (modul && !isNaN(modul)) {
        filtersAux.module = +modul;
      }
      if (department && !isNaN(department)) {
        filtersAux.department = +department;
      }
      if (city && !isNaN(city)) {
        filtersAux.district = +city;
      }
      if (rol && !isNaN(rol)) {
        filtersAux.community_role = +rol;
      }
      if (institution && !isNaN(+institution)) {
        filtersAux.institution = +institution;
      }
      if (user && !isNaN(+user)) {
        filtersAux.user = +user;
      }
      ReportsService.getTimeReport(filtersAux).then((res) => {
        setData(
          res.data.map((r) => {
            return {
              id: r.id,
              text: r.text,
              min_time: `${Math.round(r.min_time * 100) / 100} seg.`,
              mid_time: `${Math.round(r.mid_time * 100) / 100} seg.`,
              max_time: `${Math.round(r.max_time * 100) / 100} seg.`,
            };
          })
        );
        setLoading(false);
      });
      console.log(data);
    }
  }, [modul, department, city, rol, institution, user]);

  useEffect(() => {
    ProfileService.getUtils().then((res) => {
      console.log(res.data);
      setDepartments(res.data.departments); //id ,dep_code, name
      setRoles(res.data.community_roles); // id, name
      setCities(res.data.districts); // id, name, department
      citiesDb = res.data.districts;
      console.log(citiesDb);
    });
    ProfileService.getInstitutions().then((res) => {
      const dataSort = res.data.sort((a, b) =>
        a.name < b.name ? -1 : +(a.name > b.name)
      );
      setInstitutions(dataSort); //id, name, district
      institutionsDb = dataSort;
    });

    ProfileService.getProfiles().then((res) => {
      setUsers(res.data);
      usersDb = res.data;
      console.log(res.data);
    });

    LevelsService.getLevels().then((res) => {
      console.log(res.data);
      setModules(
        res.data.map((l) => {
          return {
            id: l.id,
            name: l.name,
            order: l.order,
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    console.log(department);
    departmentFlag = false;
    setCity(null);
    if (department && !isNaN(+department)) {
      let citiesAux = citiesDb.filter((c) => +department === c.department);
      setCities(citiesAux);
    } else if (department == "Departamento") {
      /*ProfileService.getUtils().then((res) => {
        setCities(res.data.districts); // id, name, department
      });*/
      setCities(citiesDb);
    }
  }, [department]);

  useEffect(() => {
    districtFlag = false;
    setInstitution(null);
    setUser(null);
    if (city && !isNaN(+city)) {
      let institutionsAux = institutionsDb.filter((i) => +city === i.district);
      setInstitutions(institutionsAux);
      let usersAux = usersDb.filter((u) => +city === u.district);
      setUsers(usersAux);
    } else if (city == "Municipio") {
      /*ProfileService.getInstitutions().then((res) => {
        setInstitutions(res.data); //id, name, district
      });
      ProfileService.getProfiles().then((res) => {
        setUsers(res.data);
      });*/
      setInstitutions(institutionsDb);
      setUsers(usersDb);
    }
  }, [city]);

  const downloadPngTable = () => {
    const tableContainer = document.getElementById("table-time");
    html2canvas(tableContainer).then(function (canvas) {
      let link = document.createElement("a");
      document.body.appendChild(link);
      link.download = "time-report.png";
      link.href = canvas.toDataURL("image/png");
      link.target = "_blank";
      link.click();
    });
  };

  const downloadCsvTable = () => {
    let csvContent = "data:text/csv;charset=utf-8,".concat(
      "Pregunta",
      ",",
      "Tiempo mínimo de respuesta",
      ",",
      "Tiempo promedio",
      ",",
      "Tiempo máximo",
      "\n"
    );
    for (let e of data) {
      csvContent = csvContent.concat(
        e.text.replace("#", "hashtag"),
        ",",
        e.min_time,
        ",",
        e.mid_time,
        ",",
        e.max_time,
        "\n"
      );
    }
    /*let specialChart = "#";
    let setEncode = encodeURIComponent(specialChart);
    console.log(specialChart);*/
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "TimeReport.csv");
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "my_data.csv".
  };

  const lookup = { true: "Available", false: "Unavailable" };
  /*const useStyles = makeStyles({
    "@global tbody tr:nth-child(odd)": {
      background: "lightgray",
    },
    "@global tbody tr:nth-child(even)": {
      background: "white",
    },
    "@global tbody title": {
      background: "white",
    },
  });
  const classes = useStyles();*/

  return (
    <div
      id="table-time"
      style={{
        width: "100%",
        boxSizing: "border-box",
        paddingLeft: 120,
        paddingRight: 120,
      }}
    >
      <div
        style={{
          display: "flex",
          height: 93,
        }}
      >
        <h1 className="title-time">Tiempo de juego</h1>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          rowGap: 10,
        }}
      >
        <span className="sub-title-time">Filtrar por</span>
        <select
          className="selecttime"
          onChange={(event) => {
            departmentFlag = !!city;
            setDepartment(event.target.value);
          }}
        >
          <option>Departamento</option>
          {departments.map((d) => {
            return <option value={d.id}>{d.name}</option>;
          })}
        </select>
        <select
          className="selecttime"
          onChange={(event) => {
            districtFlag = !!institution || !!user;
            setCity(event.target.value);
          }}
        >
          <option selected={!city}>Municipio</option>
          {cities.map((c) => {
            return (
              <option value={c.id} selected={+city === +c.id}>
                {c.name}
              </option>
            );
          })}
        </select>
        <select
          className="selecttime"
          onChange={(event) => {
            setInstitution(event.target.value);
          }}
        >
          <option selected={!institution}>Institución</option>
          {institutions.map((i) => {
            return (
              <option value={i.id} selected={+institution === +i.id}>
                {i.name /*+ " " + i.id*/}
              </option>
            );
          })}
        </select>
        <select
          className="selectcomu"
          onChange={(event) => {
            setRol(event.target.value);
          }}
        >
          <option>Rol en la comunidad</option>
          {roles.map((r) => {
            return <option value={r.id}>{r.name}</option>;
          })}
        </select>
        <select
          className="selecttime"
          onChange={(event) => {
            setUser(event.target.value);
          }}
        >
          <option selected={!user}>Usuario</option>
          {users.map((u) => {
            return (
              <option value={u.id} selected={+user === +u.id}>
                {u.user.email}
              </option>
            );
          })}
        </select>
        <select
          className="selecttime"
          onChange={(event) => {
            setModule(event.target.value);
          }}
        >
          <option>Módulos</option>
          {modules.map((m) => {
            return <option value={m.id}>{m.name}</option>;
          })}
        </select>
      </div>
      <div
        style={{
          height: 50,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <a href="#" className="downloadLink" onClick={downloadCsvTable}>
          Descargar Tabla
          <img src={download} className="downloadLinkIcon" />
        </a>
      </div>
      <div className="container-table">
        {loading ? (
          <div>Cargando...</div>
        ) : (
          <MaterialTable
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h2
                    style={{
                      textAlign: "center",
                    }}
                  >
                    No hay registros que mostrar
                  </h2>
                ),
              },
            }}
            columns={[
              {
                title: "Pregunta",
                field: "text",
                headerStyle: {
                  color: "#00A3DD",
                },
                cellStyle: {
                  minWidth: 444,
                  maxWidth: 450,
                },
                width: 800,
              },
              {
                title: "Tiempo mínimo de respuesta",
                field: "min_time",
                headerStyle: {
                  color: "#00A3DD",
                },
                width: 280,
              },
              {
                title: "Tiempo promedio",
                field: "mid_time",
                headerStyle: {
                  color: "#00A3DD",
                },
                width: 180,
              },
              {
                title: "Tiempo máximo",
                field: "max_time",
                headerStyle: {
                  color: "#00A3DD",
                },
                width: 180,
              },
            ]}
            data={data}
            options={{
              paging: false,
              toolbar: false,
              showTitle: false,
              search: false,
              headerStyle: {
                color: "#00A3DD",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 14,
                maxWidth: 230,
              },
              cellStyle: {
                maxWidth: 230,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 14,
              },
            }}
          />
        )}
      </div>
    </div>
  );
};
