import React, { useEffect, useState } from "react";
import MaterialTable, { Column } from "@material-table/core";
//import { makeStyles } from "@material-ui/core/styles";
import "./QuestionsReport.css";
import Dropdown from "react-bootstrap/Dropdown";
import html2canvas from "html2canvas";
import download from "./icons/download.png";
import LevelsService from "../../services/levels";
import ProfileService from "../../services/profile";
import ReportsService from "../../services/reports";

let municipalitiesDb = [];
let institutionsDb = [];

let departmentFlag = false;
let districtFlag = false;

export const QuestionsReport = () => {
  const [level, setLevel] = useState(0);
  const [levels, setLevels] = useState([]);
  const [modul, setModule] = useState(null);
  const [modules, setModules] = useState([]);
  const [department, setDepartment] = useState();
  const [departments, setDepartments] = useState([]);
  const [municipality, setMunicipality] = useState(); //municipality = district
  const [municipalities, setMunicipalities] = useState([]);
  const [institution, setInstitution] = useState();
  const [institutions, setInstitutions] = useState([]);
  const [rol, setRol] = useState();
  const [roles, setRoles] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    LevelsService.getLevels().then((res) => {
      //console.log(res.data);
      setModules(
        res.data.map((l) => {
          return {
            id: l.id,
            name: l.name,
            order: l.order,
            difficulty: l.difficulty,
          };
        })
      );
      const firstModule = res.data[0];
      setModule(firstModule.id);
    });
    ProfileService.getUtils().then((res) => {
      //console.log(res.data);
      setDepartments(res.data.departments); //id ,dep_code, name
      setRoles(res.data.community_roles); // id, name
      setMunicipalities(res.data.districts); // id, name, department
      municipalitiesDb = res.data.districts;
      //console.log(municipalitiesDb);
    });
    ProfileService.getInstitutions().then((res) => {
      const dataSort = res.data.sort((a, b) =>
        a.name < b.name ? -1 : +(a.name > b.name)
      );
      setInstitutions(dataSort); //id, name, district
      institutionsDb = dataSort;
    });
  }, []);

  useEffect(() => {
    /*console.log("filtros: ", {
      level: level,
      module: modul,
      department: department,
      municipality: municipality,
      rol: rol,
      institution: institution,
    });*/
    if (!departmentFlag && !districtFlag && modules?.length > 0) {
      let filtersAux = {};
      if (!isNaN(level)) {
        filtersAux.level = +level;
      }
      //console.log(modul);
      if (modul && !isNaN(modul)) {
        filtersAux.module = +modul;
      }
      if (department && !isNaN(department)) {
        filtersAux.department = +department;
      }
      if (municipality && !isNaN(municipality)) {
        filtersAux.district = +municipality;
      }
      if (rol && !isNaN(rol)) {
        filtersAux.community_role = +rol;
      }
      if (institution && !isNaN(+institution)) {
        filtersAux.institution = +institution;
      }
      ReportsService.getQuestionsReport(filtersAux).then((res) => {
        //console.log(res.data);
        setData(
          res.data.map((r) => {
            let incA1 = 0;
            let incA2 = 0;
            let flag = 1;
            for (let incA in r.incorrect_answers.answers) {
              if (flag == 1) {
                incA1 = r.incorrect_answers.answers[incA];
              } else if (flag == 2) {
                incA2 = r.incorrect_answers.answers[incA];
              }
              flag++;
            }
            return {
              id: r.id,
              correct_answers: (
                <div className="correctAnswer">{r.correct_answers}</div>
              ),
              text: r.text,
              level:
                modules && modules.length > 0
                  ? modules.find((m) => m.id === r.level)?.name
                  : r.level,
              incorrect_answers_1: <div className="wrongAnswer">{incA1}</div>,
              incorrect_answers_2: <div className="wrongAnswer">{incA2}</div>,
            };
          })
        );
        setLoading(false);
      });
    }
  }, [modules, modul, department, municipality, rol, institution]);

  useEffect(() => {
    const levelInt = +level;
    if (levelInt >= 0 && levelInt <= 2) {
      let modulesAux = modules.slice(0); //copying modules
      modulesAux = modulesAux.filter((m) => {
        return +level === m.difficulty;
      });
      //console.log(modulesAux);
      if (modulesAux && modulesAux.length > 0) {
        setModule(modulesAux[0].id);
      }
    }
  }, [level]);

  useEffect(() => {
    //console.log(department);
    departmentFlag = false;
    setMunicipality(null);
    if (department && !isNaN(+department)) {
      let municipalitiesAux = municipalitiesDb.filter(
        (m) => +department === m.department
      );
      setMunicipalities(municipalitiesAux);
    } else if (department == "Departamento") {
      /*ProfileService.getUtils().then((res) => {
        setMunicipalities(res.data.districts); // id, name, department
      });*/
      setMunicipalities(municipalitiesDb);
    }
  }, [department]);

  useEffect(() => {
    //console.log(municipality);
    districtFlag = false;
    setInstitution(null);
    if (municipality && !isNaN(+municipality)) {
      let institutionsAux = institutionsDb.filter(
        (i) => +municipality === i.district
      );
      setInstitutions(institutionsAux);
    } else if (municipality == "Municipio") {
      /*ProfileService.getInstitutions().then((res) => {
        setInstitutions(res.data); //id, name, district
      });*/
      setInstitutions(institutionsDb);
    }
  }, [municipality]);

  const downloadPngTable = () => {
    const tableContainer = document.getElementById("table-question");
    html2canvas(tableContainer).then(function (canvas) {
      let link = document.createElement("a");
      document.body.appendChild(link);
      link.download = "question-report.png";
      link.href = canvas.toDataURL("image/png");
      link.target = "_blank";
      link.click();
    });
  };

  const downloadCsvTable = () => {
    let csvContent = "data:text/csv;charset=utf-8,".concat(
      "Pregunta",
      ",",
      "Módulo",
      ",",
      "Respuestas Correctas",
      ",",
      "Respuesta Incorrecta 1",
      ",",
      "Respuesta Incorrecta 2",
      "\n"
    );
    for (let e of data) {
      csvContent = csvContent.concat(
        e.text.replace("#", "hashtag"),
        ",",
        e.level,
        ",",
        e.correct_answers.props.children,
        ",",
        e.incorrect_answers_1.props.children,
        ",",
        e.incorrect_answers_2.props.children,
        "\n"
      );
    }
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "QuestionReport.csv");
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "my_data.csv".
  };

  const lookup = { true: "Available", false: "Unavailable" };
  /*const useStyles = makeStyles({
    "@global tbody tr:nth-child(odd)": {
      background: "lightgray",
    },
    "@global tbody tr:nth-child(even)": {
      background: "white",
    },
    "@global tbody title": {
      background: "white",
    },
  });
  const classes = useStyles();*/
  return (
    <div
      id="table-question"
      style={{
        width: "100%",
        boxSizing: "border-box",
        paddingLeft: 120,
        paddingRight: 120,
      }}
    >
      <div
        style={{
          display: "flex",
          height: 93,
        }}
      >
        <h1 className="title">Preguntas</h1>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 20,
          flexWrap: "wrap",
          rowGap: 10,
        }}
      >
        <span className="sub-title">Filtrar por</span>
        <label>NIVEL*</label>
        <select
          className="select"
          onChange={(event) => {
            setLevel(event.target.value);
          }}
        >
          <option value="0">Básico</option>
          <option value="1">Intermedio</option>
          <option value="2">Avanzado</option>
        </select>
        <label>MÓDULO*</label>
        <select
          className="select"
          onChange={(event) => {
            setModule(event.target.value);
          }}
        >
          {modules
            .filter((m) => {
              return +m.difficulty === +level;
            })
            .map((m) => {
              return (
                <option value={m.id} selected={+modul === m.id}>
                  {m.name}
                </option>
              );
            })}
        </select>
        <span className="mandatory">*Obligatorio</span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          rowGap: 10,
        }}
      >
        <select
          className="select2"
          onChange={(event) => {
            departmentFlag = !!municipality;
            setDepartment(event.target.value);
          }}
        >
          <option>Departamento</option>
          {departments.map((d) => {
            return <option value={d.id}>{d.name}</option>;
          })}
        </select>
        <select
          className="select2"
          onChange={(event) => {
            districtFlag = !!institution;
            setMunicipality(event.target.value);
          }}
        >
          <option selected={!municipality}>Municipio</option>
          {municipalities.map((m) => {
            return (
              <option value={m.id} selected={+municipality === m.id}>
                {m.name}
              </option>
            );
          })}
        </select>
        <select
          className="select2"
          onChange={(event) => {
            setInstitution(event.target.value);
          }}
        >
          <option selected={!institution}>Institución educativa</option>
          {institutions.map((i) => {
            return (
              <option value={i.id} selected={+institution === +i.id}>
                {i.name}
              </option>
            );
          })}
        </select>
        <select
          className="select2 select3"
          onChange={(event) => {
            setRol(event.target.value);
          }}
        >
          <option>Rol en la comunidad educativa</option>
          {roles.map((r) => {
            return <option value={r.id}>{r.name}</option>;
          })}
        </select>
      </div>
      <div
        style={{
          height: 80,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {
          <a href="#" className="downloadLink" onClick={downloadCsvTable}>
            Descargar Tabla
            <img src={download} className="downloadLinkIcon" />
          </a>
        }
        {/*
          <Dropdown style={{ border: "none" }}>
            <Dropdown.Toggle show={false} childBsPrefix="dropdown-button">
              <button>
                <span className="downloadLink">Descargar Tabla</span>
                <img src={download} className="downloadLinkIcon" />
              </button>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                bsPrefix="dropdown-container"
                as={"button"}
                onClick={downloadPngTable}
              >
                PNG
              </Dropdown.Item>
              <Dropdown.Item
                bsPrefix="dropdown-container"
                as={"button"}
                onClick={downloadCsvTable}
              >
                CSV
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
    */}
      </div>
      <div className="container-table">
        {loading ? (
          <div>Cargando...</div>
        ) : (
          <MaterialTable
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h2
                    style={{
                      textAlign: "center",
                    }}
                  >
                    No hay registros que mostrar
                  </h2>
                ),
              },
            }}
            columns={[
              {
                title: "Pregunta",
                field: "text",
                headerStyle: {
                  color: "#00A3DD",
                },
                cellStyle: {
                  minWidth: 300,
                  maxWidth: 450,
                },
              },
              {
                title: "Módulo",
                field: "level",
                type: "numeric",
                headerStyle: {
                  color: "#00A3DD",
                },
                width: "30px",
              },
              {
                title: "Respuestas correctas",
                field: "correct_answers",
                type: "numeric",
                headerStyle: {
                  color: "#00A3DD",
                },
                width: "30px",
                customSort: (a, b) =>
                  +a.correct_answers.props.children -
                  +b.correct_answers.props.children,
              },
              {
                title: "Respuesta incorrecta 1",
                field: "incorrect_answers_1",
                headerStyle: {
                  color: "#00A3DD",
                },
                width: "30px",
                customSort: (a, b) =>
                  +a.incorrect_answers_1.props.children -
                  +b.incorrect_answers_1.props.children,
              },
              {
                title: "Respuesta incorrecta 2",
                field: "incorrect_answers_2",
                headerStyle: {
                  color: "#00A3DD",
                },
                width: "30px",
                customSort: (a, b) =>
                  +a.incorrect_answers_2.props.children -
                  +b.incorrect_answers_2.props.children,
              },
            ]}
            data={data}
            options={{
              paging: false,
              toolbar: false,
              showTitle: false,
              search: false,
              headerStyle: {
                color: "#00A3DD",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 14,
                maxWidth: 300,
              },
              cellStyle: {
                maxWidth: 300,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 14,
              },
              rowStyle: {
                padding: "0px 20px",
              },
            }}
          />
        )}
      </div>
    </div>
  );
};
