import React, { Component } from "react";
import SoundService from "../services/sound";
import AuthService from "../services/auth";

import Parser from "html-react-parser";
import "../spinners.css";
import "./Landing/landing.css";

import back from "../assets/images/modulos/backBlue.svg";
import cross from "../assets/images/close-x.svg";
import checkmark from "../assets/images/checkmark.svg";

class ChangePassword extends Component {
  state = {
    matchedPasswords: false,
    loading: false,
    errorMessage: null,
  };

  pass1 = React.createRef();
  pass2 = React.createRef();

  matchPasswords() {
    if (
      this.pass1.current &&
      this.pass2.current &&
      this.pass1.current.value &&
      this.pass2.current.value
    ) {
      this.setState({
        matchedPasswords: this.pass1.current.value === this.pass2.current.value,
      });
    } else {
      this.setState({ matchPasswords: false });
    }
  }

  goBack = (route) => {
    SoundService.playTab();
    this.props.history.goBack();
  };

  closeModal() {
    this.setState({ errorMessage: null });
  }

  submit() {
    SoundService.playTab();
    this.setState({ loading: true });
    //console.log(this.pass1.current.value);
    // Change Password
    AuthService.changePassword(this.pass1.current.value).then(
      (res) => {
        // Log in with the new profile
        this.setState({ loading: false });
        AuthService.logout();
        this.props.history.replace("/");
      },
      (error) => {
        // creates a msg that cocatenates a string/html to render in errorModal
        if (error.response?.status === 400) {
          let msg =
            '<h4 style="margin-top: 0px">ERROR</h4><ul style="text-align: initial">';
          if (error.response?.data.email) {
            error.response.data.email.map(
              (m) => (msg = msg + "<li>" + m + "</li>")
            );
          }
          if (error.response?.data.password) {
            error.response.data.password.map(
              (m) => (msg = msg + "<li>" + m + "</li>")
            );
          }
          msg = msg + "</ul>";
          this.setState({
            loading: false,
            errorMessage: msg,
          });
        } else if (error.response?.status === 500) {
          this.setState({
            loading: false,
            errorMessage:
              '<h4 style="margin-top: 0px">ERROR</h4>Error del servidor, por favor, intente más tarde',
          });
        } else {
          this.setState({
            loading: false,
            errorMessage:
              '<h4 style="margin-top: 0px">ERROR</h4>Error desconocido, por favor, intente más tarde',
          });
        }

        console.log(error.response);
      }
    );
  }

  errorModal() {
    // Parser switch errorMessage string to HTML element
    return (
      <div className="login-error-modal">
        {Parser(this.state.errorMessage)}
        <div style={{ marginTop: "20px" }}>
          <button
            onClick={() => this.closeModal()}
            className="outline-button black-button"
          >
            Cerrar
          </button>
        </div>
      </div>
    );
  }

  spinner() {
    return (
      <div style={{ height: "58px" }}>
        <div style={{ top: "338px" }} className="spinner1 color-2">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div style={{ marginTop: "25px" }} className="lp-title lpb-1">
            Cambiar contraseña
          </div>
          <img
            className="lp-back back-with-bg"
            onClick={this.goBack}
            src={back}
            alt="back"
          ></img>
          <div
            style={{ marginTop: "70px", marginRight: 50, marginLeft: 50 }}
            className="inputs-container"
          >
            <input
              ref={this.pass1}
              onChange={() => this.matchPasswords()}
              className="landing-input-blue input-register"
              placeholder="NUEVA CONTRASEÑA"
              type="password"
              spellCheck="false"
            ></input>
            <div style={{ display: "flex" }}>
              <input
                ref={this.pass2}
                onChange={() => this.matchPasswords()}
                className="landing-input-blue input-register"
                placeholder="REPETIR CONTRASEÑA"
                type="password"
                spellCheck="false"
              ></input>
              {this.pass1.current?.value && this.pass2.current?.value && (
                <div style={{ marginLeft: 5 }}>
                  <img
                    src={this.state.matchedPasswords ? checkmark : cross}
                    alt={this.state.matchedPasswords ? "y" : "x"}
                    height={28}
                  />
                </div>
              )}
            </div>
          </div>
          {!this.state.loading ? (
            <button
              disabled={!this.state.matchedPasswords}
              onClick={() => this.submit()}
              className={
                "landing-button" +
                (this.state.matchedPasswords ? "" : " lb-disabled")
              }
            >
              aceptar
            </button>
          ) : (
            this.spinner()
          )}
          {this.state.errorMessage ? this.errorModal() : null}
        </div>
      </div>
    );
  }
}
export default ChangePassword;
