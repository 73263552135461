import API from "../api";
import secrets from "../secrets.json";
import axios from "axios";

class AuthService {
  setToken(token) {
    localStorage.setItem("laberinfo_user_token", token);
  }

  setRefreshToken(token) {
    localStorage.setItem("laberinfo_refresh_token", token);
  }

  login(email, password) {
    const payload = {
      grant_type: "password",
      client_id: secrets.client_id,
      username: email,
      password: password,
    };
    return API.post("oauth/token/", payload, {
      headers: { "Content-type": "application/json" },
    });
  }

  logout() {
    localStorage.removeItem("laberinfo_refresh_token");
    localStorage.removeItem("laberinfo_user_token");
    localStorage.removeItem("laberinfo_sound");
  }

  doRefreshToken() {
    const payload = {
      grant_type: "refresh_token",
      client_id: secrets.client_id,
      refresh_token: localStorage.getItem("laberinfo_refresh_token"),
    };
    return API.post("oauth/token/", payload, { headers: this.headers });
  }

  doConvertToken(token) {
    const payload = {
      grant_type: "convert_token",
      client_id: secrets.client_id,
      backend: "google-oauth2",
      token: token,
    };
    return API.post("oauth/convert-token/", payload, {
      headers: { "Content-type": "application/json" },
    });
  }
  doConvertTokenFB(token) {
    const payload = {
      grant_type: "convert_token",
      client_id: secrets.client_id,
      backend: "facebook",
      token: token,
    };
    return API.post("oauth/convert-token/", payload, {
      headers: { "Content-type": "application/json" },
    });
  }
  doConvertTokenTW(oauth_token, oauth_token_secret) {
    const payload = {
      grant_type: "convert_token",
      client_id: secrets.client_id,
      backend: "twitter",
      token: `oauth_token=${oauth_token}&oauth_token_secret=${oauth_token_secret}`,
    };
    return API.post("oauth/convert-token/", payload, {
      headers: { "Content-type": "application/json" },
    });
  }

  async loginWithFoco(username, password) {
    const params = new URLSearchParams();
    params.append("client_id", "laberinfo");
    params.append("username", username);
    params.append("password", password);
    params.append("grant_type", "password");
    let response = await axios.post(
      "https://mefin.reaccion.org.py/auth/realms/foco/protocol/openid-connect/token",
      params,
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    );
    return response;
  }

  doConvertTokenFOCO(token) {
    //console.log(FOCOResponse);
    /*const payload = {
      grant_type: "convert_token",
      client_id: secrets.client_id,
      backend: "keycloak",
      token: FOCOResponse.token,
    };*/

    const data = {
      grant_type: "convert_token",
      client_id: "56YRDs0oYnhIGlrSbm554HCfuMDuklHUlqGovMfD",
      backend: "keycloak",
      token: token,
    };
    return API.post(
      "https://laberinfo.reaccion.org.py/api/oauth/convert-token/",
      data,
      { headers: { "Content-type": "application/json" } }
    );
  }

  /*focoLogin() {
    return API.get("oauth/login/keycloak");
  }*/

  changePassword(newPass) {
    const payload = {
      password: newPass,
    };
    return API.post("v1/users/me/change-password/", payload, {
      headers: this.headers,
    });
  }
}

export default new AuthService();
