import React, { Component } from "react";
import Select from "react-select";
import "../../spinners.css";
import LevelsService from "../../services/levels";
import ProfileService from "../../services/profile";
import SoundService from "../../services/sound";
import { CSSTransition } from "react-transition-group";
import ShareSocialMediaModal from "../../components/shareModal";

// images
import monai from "../../assets/images/modulos/monai.svg";
import mykure from "../../assets/images/modulos/mykure.svg";
import ribbon from "../../assets/images/modulos/ribbon.svg";
import modulo1Gray from "../../assets/images/modulos/modulo-1gray.svg";
import modulo2Gray from "../../assets/images/modulos/modulo-2gray.svg";
import modulo3Gray from "../../assets/images/modulos/modulo-3gray.svg";

import modulo1Circle from "../../assets/images/modulos/modulo-1Circle.svg";
import modulo2Circle from "../../assets/images/modulos/modulo-2Circle.svg";
import modulo3Circle from "../../assets/images/modulos/modulo-3Circle.svg";
import modulo4Circle from "../../assets/images/modulos/modulo-4Circle.svg";
import modulo5Circle from "../../assets/images/modulos/modulo-5Circle.svg";
import modulo6Circle from "../../assets/images/modulos/modulo-6Circle.svg";
import modulo7Circle from "../../assets/images/modulos/modulo-7Circle.svg";

import howToPlay from "../../assets/images/modulos/howToPlay.svg";
import soundOn from "../../assets/images/modulos/soundOn.svg";
import soundOff from "../../assets/images/modulos/soundOff.svg";
import shareIcon from "../../assets/images/modulos/share.svg";
import closeModal from "../../assets/images/interface/closeModal.svg";
import closeModal2 from "../../assets/images/interface/closeModal2.svg";
import closeModal3 from "../../assets/images/interface/closeModal3.svg";
import closeModal4 from "../../assets/images/interface/closeModal4.svg";
import closeModal5 from "../../assets/images/interface/closeModal5.svg";
import closeModal6 from "../../assets/images/interface/closeModal6.svg";
import closeModal7 from "../../assets/images/interface/closeModal7.svg";
import arrowPrevious from "../../assets/images/interface/arrowPrevious.svg";
import arrowNext from "../../assets/images/interface/arrowNext.svg";

import screen1 from "../../assets/images/screen1.png";
import screen2 from "../../assets/images/screen2.png";
import screen3 from "../../assets/images/screen3.png";
import screen4 from "../../assets/images/screen4.png";
import screen5 from "../../assets/images/screen5.png";
import screen6 from "../../assets/images/screen6.png";
import screen7 from "../../assets/images/screen7.png";
import screen8 from "../../assets/images/screen8.png";
import screen9 from "../../assets/images/screen9.png";

import back1 from "../../assets/images/modulos/back1.svg";

import HomeChild from "../../assets/images/modulos/HomeChild.png";

import reaccionLogo from "../../assets/images/reaccionW.svg";
import {
  dinamicSVGPathColor,
  dinamicCircleSvgPathColor,
  dinamicCloseModalSvgColor,
} from "../../components/dinamicLvlSvgColor";
// import { Label } from 'recharts';

import locked from "../../assets/images/modulos/locked.svg";
import playNow from "../../assets/images/modulos/jugarAhora.png";

class Home extends Component {
  /*Extract lvls from backend and check amount of levels*/
  state = {
    errorMessage: null,
    loading: true,
    loadingCertificate: false,
    sound: localStorage.getItem("laberinfo_sound") === "on",
    modal: false,
    shareModal: false,
    lvlModal: false,
    lvl: 1,
    levelStates: [],
    howToPlayPage: 1,
    requiredData: false,
    rDataPage: 0,
    acepted: true,
    termsAndConditions: false,
    privacityPolicy: false,
    // inputs
    acceptTerms: null,
    birthDate: null,
    gender: null,
    department: null,
    district: null,
    role: null,
    roleName: null,
    institution: null,
    grade: null,
    avatar: null,
    // variable array of districts and schools
    districts: [],
    schools: [],
    difficulty: null,
  };

  screens = {
    s1: screen1,
    s2: screen2,
    s3: screen3,
    s4: screen4,
    s5: screen5,
    s6: screen6,
    s7: screen7,
    s8: screen8,
    s9: screen9,
  };

  requiredDataJSON = [
    {
      title: "¡Te damos la bienvenida a LaberINFO!", // Accept terms
      subtitle: "Para empezar...",
    },
    {
      title: "Tus datos", // Birth date
      subtitle: "¿Cuándo naciste?",
      body: "Dolor sit amet, consectetur adipiscing elit. Id placerat maecenas bibendum.",
    },
    {
      title: "Tus datos", // Gender or Sex
      subtitle: "¿Cuál es tu género?",
      body: "Dolor sit amet, consectetur adipiscing elit. Id placerat maecenas bibendum.",
    },
    {
      title: "Tus datos", // Department
      subtitle: "¿De qué departamento sos?",
      body: "Dolor sit amet, consectetur adipiscing elit. Id placerat maecenas bibendum.",
    },
    {
      title: "Tus datos", // District
      subtitle: "¿De qué ciudad sos?",
      body: "Dolor sit amet, consectetur adipiscing elit. Id placerat maecenas bibendum.",
    },
    {
      title: "Tus datos", // Role
      subtitle: "¿Cuál es tu rol?",
      body: "Dolor sit amet, consectetur adipiscing elit. Id placerat maecenas bibendum.",
    },
    {
      title: "Tus datos", // School
      subtitle: "¿De qué escuela o colegio sos?",
      body: "Dolor sit amet, consectetur adipiscing elit. Id placerat maecenas bibendum.",
    },
    {
      title: "Tus datos", // Grade
      subtitle: "¿En qué grado o curso estás?",
      body: "Dolor sit amet, consectetur adipiscing elit. Id placerat maecenas bibendum.",
    },
  ];

  // arrays of requested data options
  roles;
  schools;
  grades;
  departments;

  birthDateMax;

  componentDidMount() {
    const date = new Date();
    this.birthDateMax = `${date.getFullYear() - 8}-${
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
    // if user dont have Profile Data,
    ProfileService.getData().then((prof) => {
      if (!prof.data.profile) {
        this.setState({ requiredData: true });
        ProfileService.getUtils().then((utils) => {
          this.roles = utils.data.community_roles;
          this.grades = utils.data.grades;
          this.departments = utils.data.departments;
          this.districts = utils.data.districts;
        });
        ProfileService.getInstitutions().then((insts) => {
          this.schools = insts.data;
          this.schools.push({
            value: 0,
            label: "Otro",
          });
        });
      } else {
        LevelsService.getLevels().then((response) => {
          let unlock = true;
          let temp = [];
          response.data.forEach((l) => {
            const stat = unlock ? "available" : "locked";
            unlock = l.status === "completed";
            if (!l.hide)
              temp.push({
                id: l.id,
                order: l.order,
                title: l.name,
                state: stat,
                won: l.status === "completed",
                has_certificate: l.has_certificate,
                path_color: l.path_color,
                circle_color: l.circle_color,
                difficulty: l.difficulty,
              });
          });
          const orderLevel = temp.sort((a, b) => a.order - b.order);
          this.setState({
            levelStates: orderLevel,
            loading: false,
          });
        });
      }
    });
  }

  goToLevel = (route) => {
    SoundService.playTab();
    const lvl = this.state.lvl;
    this.props.history.push("/modulo/" + lvl);
  };

  goToCertificate() {
    SoundService.playTab();
    this.setState({
      loadingCertificate: true,
    });
    const id = this.state.levelStates[this.state.lvl - 1].id;
    LevelsService.getCertificates(id).then((cert) => {
      window.open(cert.data.certificate, "_blank");
      this.setState({
        loadingCertificate: false,
      });
    });
  }

  getCircle(lvl) {
    switch (lvl % 7) {
      case 0:
        return modulo7Circle;

      case 1:
        return modulo1Circle;

      case 2:
        return modulo2Circle;

      case 3:
        return modulo3Circle;

      case 4:
        return modulo4Circle;

      case 5:
        return modulo5Circle;

      case 6:
        return modulo6Circle;

      default:
        return modulo1Circle;
    }
  }

  getCloseImg(lvl) {
    switch (lvl % 7) {
      case 0:
        return closeModal7;

      case 1:
        return closeModal;

      case 2:
        return closeModal2;

      case 3:
        return closeModal3;

      case 4:
        return closeModal4;

      case 5:
        return closeModal5;

      case 6:
        return closeModal6;

      default:
        return closeModal;
    }
  }

  getLvlImg(lvl) {
    if (this.state.levelStates[lvl - 1].state === "locked") {
      switch (lvl % 3) {
        case 1:
          return modulo1Gray;
        case 2:
          return modulo2Gray;
        case 0:
          return modulo3Gray;
        default:
          return modulo1Gray;
      }
    } else {
      /* looks for one of 21 combinations of 3 shapes and 7 colors*/

      return dinamicSVGPathColor(
        lvl % 21 ? lvl % 21 : 21,
        this.state.levelStates[lvl - 1].path_color
      );
    }
  }

  previousRD = () => {
    SoundService.playTab();
    this.setState({ rDataPage: this.state.rDataPage - 1 });
  };

  previousPage() {
    SoundService.playTab();
    this.setState({ howToPlayPage: this.state.howToPlayPage - 1 });
  }

  nextPage() {
    SoundService.playTab();
    this.setState({ howToPlayPage: this.state.howToPlayPage + 1 });
  }

  /*enables or disables modal*/
  triggerModal() {
    SoundService.playTab();
    this.setState({ modal: !this.state.modal });
  }
  triggerShareModal() {
    SoundService.playTab();
    this.setState({ shareModal: !this.state.shareModal });
  }

  triggerLvlModal(lvl) {
    SoundService.playTab();
    this.setState({
      lvlModal: !this.state.lvlModal,
      lvl: lvl,
    });
  }

  triggerTermsAndConditions() {
    SoundService.playTab();
    this.setState({ termsAndConditions: !this.state.termsAndConditions });
  }

  triggerPrivacityPolicy() {
    SoundService.playTab();
    this.setState({ privacityPolicy: !this.state.privacityPolicy });
  }

  triggerSound() {
    if (localStorage.getItem("laberinfo_sound") === "on") {
      localStorage.setItem("laberinfo_sound", "off");
    } else {
      localStorage.setItem("laberinfo_sound", "on");
    }
    this.setState({ sound: !this.state.sound });
    SoundService.playTab();
  }

  setCurrentInput(event) {
    switch (this.state.rDataPage) {
      case 0: // terms and conditions
        if (event.target.value.toUpperCase() === "ACEPTO") {
          this.setState({ acepted: true });
        } else {
          this.setState({ acepted: false });
        }
        this.setState({ acceptTerms: event.target.value });
        break;
      case 1: // birth date
        this.setState({ birthDate: event.target.value });
        break;
      case 2: // sex or gender
        this.setState({
          gender: event.target.value === "null" ? null : event.target.value,
        });
        break;
      case 3: // department
        this.setState({
          department: event.target.value === "null" ? null : event.target.value,
        });
        // update districts
        if (event.target.value !== "null" && event.target.value !== null) {
          this.setState({
            districts: this.districts
              .filter((dist) => dist.department === +event.target.value)
              .map((d) => {
                return {
                  value: d.id,
                  label: d.name,
                };
              }),
          });
        }
        break;
      case 4: // district
        this.setState({ district: event.value });
        // update schools
        if (event.value) {
          this.setState({
            schools: this.schools
              .filter((school) => school.district === +event.value)
              .map((s) => {
                return {
                  value: s.id,
                  label: s.name,
                };
              }),
          });
        }
        break;
      case 5: // role
        this.setState({
          role: event.target.value === "null" ? null : event.target.value,
          roleName:
            event.target.value === "null"
              ? null
              : event.nativeEvent.target[event.target.selectedIndex].text,
        });
        break;
      case 6: // school or institution
        this.setState({ institution: event.value });
        break;
      case 7: // grade
        this.setState({
          grade: event.target.value === "null" ? null : event.target.value,
        });
        break;
      default:
        console.log("Input no contemplado");
    }
  }

  setInputType() {
    switch (this.state.rDataPage) {
      case 1: // birth date
        return (
          <input
            value={this.state.birthDate}
            onChange={(event) => this.setCurrentInput(event)}
            className="rd-input"
            type="date"
            max={this.birthDateMax}
          ></input>
        );
      case 2: // sex
        return (
          <select
            value={this.state.gender}
            onChange={(event) => this.setCurrentInput(event)}
            className="rd-input"
          >
            <option className="input-option" value="null">
              Seleccione una opción
            </option>
            <option className="input-option" value="m">
              Hombre
            </option>
            <option className="input-option" value="f">
              Mujer
            </option>
            <option className="input-option" value="x">
              Prefiero no responder
            </option>
          </select>
        );
      case 3: // department
        return (
          <select
            value={this.state.department}
            onChange={(event) => this.setCurrentInput(event)}
            className="rd-input"
          >
            <option className="input-option" value="null">
              Seleccione una opción
            </option>
            {this.departments.map((d) => (
              <option className="input-option" value={d.id}>
                {d.name}
              </option>
            ))}
          </select>
        );
      case 4: // district
        return (
          <Select
            options={this.state.districts}
            defaultValue={this.state.district}
            onChange={(event) => this.setCurrentInput(event)}
            className="rd-input"
            placeholder="Busca tu ciudad o distrito"
            noOptionsMessage={() => "Ciudad no encontrada"}
          ></Select>
        );
      case 5: // role
        return (
          <select
            value={this.state.role}
            onChange={(event) => this.setCurrentInput(event)}
            className="rd-input"
          >
            <option className="input-option" value="null">
              Seleccione una opción
            </option>
            {this.roles.map((r) => (
              <option className="input-option" value={r.id}>
                {r.name}
              </option>
            ))}
            <option className="input-option" value="0">
              Otro
            </option>
          </select>
        );
      case 6: // school
        return (
          <Select
            options={this.state.schools}
            defaultValue={this.state.institution}
            onChange={(event) => this.setCurrentInput(event)}
            className="rd-input"
            placeholder="Busca tu institución"
            noOptionsMessage={() => "Institución no encontrada"}
          ></Select>
        );
      case 7: // grade
        return (
          <select
            value={this.state.grade}
            onChange={(event) => this.setCurrentInput(event)}
            className="rd-input"
          >
            <option className="input-option" value="null">
              Seleccione una opción
            </option>
            {this.grades.map((r) => (
              <option className="input-option" value={r.id}>
                {r.name}
              </option>
            ))}
            <option className="input-option" value="0">
              Ninguno
            </option>
          </select>
        );
      default:
        return (
          <input
            onChange={() => this.setCurrentInput()}
            className="rd-input"
          ></input>
        );
    }
  }

  continue() {
    SoundService.playTab();
    if (
      this.state.rDataPage + 1 < this.requiredDataJSON.length && // there's required data still
      this.state.role !== "0" && // if u choose "other" role, skip school and grade (end required data)
      !(this.state.rDataPage === 6 && this.state.role !== "2") && // if u dont choose student, skip grade
      !(this.state.roleName === "Docente" && this.state.rDataPage === 7) // ??? (Euli?) looks unnecesary
    ) {
      // Skip grado e institucion si rol == otro
      // Skip grado si rol == Docente
      this.setState({ rDataPage: this.state.rDataPage + 1 });
    } else {
      // todos los inputs seteados
      this.setState({ loading: true });
      const payload = {
        has_accepted_terms: this.state.acepted,
        birth_date: this.state.birthDate,
        sex: this.state.gender,
        department: this.state.department,
        district: this.state.district,
        community_role: this.state.role !== "0" ? this.state.role : null,
        institution: this.state.role !== "0" ? this.state.institution : null,
        grade: this.state.role !== "0" ? this.state.grade : null,
        avatar: null,
      };
      ProfileService.updateProfile(payload).then(
        (res) => {
          this.setState({ requiredData: false });
          LevelsService.getLevels().then(
            (response) => {
              let unlock = true;
              let temp = [];
              response.data.forEach((l) => {
                const stat = unlock ? "available" : "locked";
                unlock = l.status === "completed";
                if (!l.hide)
                  temp.push({
                    id: l.id,
                    title: l.name,
                    state: stat,
                    won: l.status === "completed",
                    has_certificate: l.has_certificate,
                    path_color: l.path_color,
                    circle_color: l.circle_color,
                  });
              });
              const orderLevel = temp.sort((a, b) => a.order - b.order);
              this.setState({
                levelStates: orderLevel,
                loading: false,
              });
            },
            (err) => {
              //console.log(err.response);
              this.setState({
                errorMessage: `Hubo un error al traer los niveles: ${
                  err.response?.data ? err.response.data : ""
                }`,
                loading: false,
              });
            }
          );
        },
        (err) => {
          this.setState({
            errorMessage: `Hubo un error al guardar los datos de usuario: ${
              err.response?.data ? err.response.data : ""
            }`,
            loading: false,
          });
        }
      );
    }
  }

  disableButton() {
    switch (this.state.rDataPage) {
      case 1: // birth date
        return this.state.birthDate === null;
      case 2: // sex or gender
        return this.state.gender === null;
      case 3: // department
        return this.state.department === null;
      case 4: // district
        return this.state.district === null;
      case 5: // role
        return this.state.role === null;
      case 6: // school or institution
        return this.state.institution === null;
      case 7: // grade
        return this.state.grade === null;
      default:
        return true;
    }
  }

  closeModal() {
    this.setState({ errorMessage: null });
  }

  errorModal() {
    return (
      <div className="login-error-modal">
        {this.state.errorMessage}
        <div style={{ marginTop: "20px" }}>
          <button
            onClick={() => this.closeModal()}
            className="outline-button black-button"
          >
            Cerrar
          </button>
        </div>
      </div>
    );
  }

  openLevel() {
    //We change the modal default color to the custom one
    const custom_color =
      this.state.levelStates[this.state.lvl - 1].circle_color;

    const levelsColors =
      this.state.levelStates[this.state.lvl - 1].difficulty === 0
        ? "#D78951"
        : this.state.levelStates[this.state.lvl - 1].difficulty === 1
        ? "#8B8888"
        : "#FFBA35";
    return (
      <CSSTransition
        in={this.state.lvlModal && this.state.lvl}
        timeout={150}
        classNames="miniModalTransition"
        unmountOnExit
      >
        <div
          className={
            custom_color
              ? "level-modal"
              : "level-modal lmodal-" + (this.state.lvl % 7)
          }
          style={
            custom_color
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 8,
                  color: custom_color,
                }
              : {}
          }
        >
          <img
            height="20px"
            onClick={() => this.triggerLvlModal(this.state.lvl)}
            className="modal-closeButton"
            src={
              custom_color
                ? dinamicCloseModalSvgColor(custom_color)
                : this.getCloseImg(this.state.lvl)
            }
            alt="x"
          />
          <div className="modal-header small-header">
            Módulo {this.state.lvl}
          </div>
          <div className="lm-body">
            <div
              style={{
                display: "flex",
                borderRadius: 10,
                padding: 1,
                backgroundColor: levelsColors,
                justifyContent: "center",
                fontSize: 12,
                color: "#fff",
                width: 120,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Nivel{" "}
              {this.state.levelStates[this.state.lvl - 1].difficulty === 0
                ? "Bronce"
                : this.state.levelStates[this.state.lvl - 1].difficulty === 1
                ? "Plata"
                : "Oro"}
            </div>
            {/*this.state.levelStates[this.state.lvl - 1].title*/}
          </div>
          <div className="lm-footer">
            {this.state.levelStates[this.state.lvl - 1].state !== "locked" ? (
              <button
                onClick={() => this.goToLevel()}
                className={
                  custom_color
                    ? "lm-button"
                    : "lm-button lmb-" + (this.state.lvl % 7)
                }
                style={{ background: custom_color, width: "180px" }}
              >
                Jugar
              </button>
            ) : (
              <button
                disabled={true}
                className="lm-button locked"
                style={{ width: "180px" }}
              >
                Bloqueado
              </button>
            )}
            {this.state.levelStates[this.state.lvl - 1].won ? (
              this.state.loadingCertificate ? (
                <div style={{ width: "153px", height: "39px" }}>
                  <div
                    style={{ top: "165px", color: custom_color }}
                    className={
                      custom_color
                        ? "spinner1"
                        : "spinner1 color-" + (this.state.lvl % 7)
                    }
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => this.goToCertificate()}
                  style={
                    this.state.levelStates[this.state.lvl - 1].has_certificate
                      ? {
                          color: custom_color,
                          border: " 2px solid" + custom_color,
                          display: "block",
                        }
                      : { display: "none" }
                  }
                  className={
                    custom_color
                      ? "lm-certificate"
                      : "lm-certificate lmc-" + (this.state.lvl % 7)
                  }
                >
                  Ver Certificado
                </button>
              )
            ) : null}
          </div>
        </div>
      </CSSTransition>
    );
  }

  requiredData() {
    if (this.state.rDataPage === 0) {
      return (
        <div className="required-data">
          <div className="rd-title lp-level1 rd-title-fullWidth">
            {this.requiredDataJSON[0].title}
          </div>
          <div className="rd-question">{this.requiredDataJSON[0].subtitle}</div>
          <div className="rd-body">
            <div className="rd-text">
              ¡Te damos la bienvenida a LaberINFO! Antes de empezar a jugar,
              necesitamos que leas los{" "}
              <span
                onClick={() => this.triggerTermsAndConditions()}
                className="text-link"
              >
                términos y condiciones
              </span>{" "}
              y la{" "}
              <span
                onClick={() => this.triggerPrivacityPolicy()}
                className="text-link"
              >
                política de privacidad
              </span>
              . Si estás de acuerdo con nosotros, dale a ACEPTO
            </div>
            <button
              disabled={!this.state.acepted}
              onClick={() => this.continue()}
              className={
                "rd-button" + (this.state.acepted ? "" : " button-disabled")
              }
            >
              acepto
            </button>
            <img
              src={reaccionLogo}
              className="reaccion-logo"
              alt="reaccion"
            ></img>
          </div>
        </div>
      );
    } else {
      return (
        <div className="required-data">
          <div className="rd-title lp-title lp-level1">
            {this.requiredDataJSON[this.state.rDataPage]?.title}
          </div>
          <div className="rd-question">
            {this.requiredDataJSON[this.state.rDataPage]?.subtitle}
          </div>
          <img
            onClick={this.previousRD}
            className="lp-back lpb-1"
            src={back1}
            alt="x"
          />
          <div className="rd-body">
            {this.setInputType()}
            <button
              disabled={this.disableButton()}
              onClick={() => this.continue()}
              className={
                "rd-button" + (this.disableButton() ? " button-disabled" : "")
              }
            >
              continuar
            </button>
            <img
              src={reaccionLogo}
              className="reaccion-logo"
              alt="reaccion"
            ></img>
          </div>
        </div>
      );
    }
  }

  overlay() {
    return <div className="overlay" onClick={() => this.triggerModal()} />;
  }

  overlayLvl() {
    return (
      <div
        className="overlay"
        onClick={() => this.triggerLvlModal(this.state.lvl)}
      />
    );
  }

  overlayTC() {
    return (
      <div
        className="overlay"
        onClick={() => this.triggerTermsAndConditions()}
      />
    );
  }

  overlayPP() {
    return (
      <div className="overlay" onClick={() => this.triggerPrivacityPolicy()} />
    );
  }

  howToPlayContent() {
    let text;
    let image;
    switch (this.state.howToPlayPage) {
      case 1:
        text = "Elegí el primer nivel y dale “Empezar”.";
        image = this.screens.s1;
        break;
      case 2:
        text = "Leé la pregunta sin apuros y elegí la respuesta correcta.";
        image = this.screens.s2;
        break;
      case 3:
        text = "Dale “Continuar” y respondé todas las preguntas del nivel.";
        image = this.screens.s3;
        break;
      case 4:
        text =
          "Si fallás 4 preguntas, tenés que repetir el nivel completo ¡sí o sí!";
        image = this.screens.s4;
        break;
      case 5:
        text =
          "¡Respondé bien las preguntas en las que te equivocaste y aumenta tu puntaje!";
        image = this.screens.s5;
        break;
      case 6:
        text =
          "Ahora que ya dominás este nivel del LaberINFO, ¡podés jugar el “Bonus” para ganar más puntos!";
        image = this.screens.s6;
        break;
      case 7:
        text =
          "Podés recuperar la mitad de tus puntos perdidos, si en una repetición del nivel, respondés bien al 100% de las preguntas";
        image = this.screens.s7;
        break;
      case 8:
        text = "¡Desbloquea todos los niveles, ganá y accedé a tu certificado!";
        image = this.screens.s8;
        break;
      case 9:
        text =
          "Cuando repetís niveles, solo suman los puntos de las respuestas en las que antes te equivocaste. ¡Y solo hasta el tercer intento!";
        image = this.screens.s9;
        break;
      default:
        text = "";
        image = this.screens.s1;
    }
    return (
      <div className="modal-content">
        <h1 className="modal-content-page">{this.state.howToPlayPage}</h1>
        <span className="modal-description">{text}</span>
        <div className="modal-image-container">
          <img className="modal-image" src={image} alt="screen1"></img>
        </div>
      </div>
    );
  }

  modal() {
    return (
      <CSSTransition
        in={this.state.modal}
        timeout={150}
        classNames="modalTransition"
        unmountOnExit
      >
        <div className="home-modal">
          <div className="modal-header">
            ¿CÓMO JUGAR?
            <img
              onClick={() => this.triggerModal()}
              className="modal-closeButton"
              src={closeModal}
              alt="x"
            />
          </div>
          {this.howToPlayContent()}
          <div className="modal-footer">
            <span className="arrow-container">
              {this.state.howToPlayPage !== 1 ? (
                <img
                  onClick={() => this.previousPage()}
                  src={arrowPrevious}
                  alt="previous"
                ></img>
              ) : null}
            </span>
            {this.state.howToPlayPage === 9 ? (
              <button
                onClick={() => this.triggerModal()}
                className="modal-okButton"
              >
                OK
              </button>
            ) : (
              <div className="empty-button" />
            )}
            <span className="arrow-container">
              {this.state.howToPlayPage !== 9 ? (
                <img
                  onClick={() => this.nextPage()}
                  src={arrowNext}
                  alt="next"
                ></img>
              ) : null}
            </span>
            <div className="modal-pages">
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 1 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 2 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 3 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 4 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 5 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 6 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 7 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 8 ? "mp-enabled" : "")
                }
              ></div>
              <div
                className={
                  "modal-page " +
                  (this.state.howToPlayPage === 9 ? "mp-enabled" : "")
                }
              ></div>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
  shareModal() {
    return (
      <ShareSocialMediaModal
        open={this.state.shareModal}
        onClose={() => this.triggerShareModal()}
      />
    );
  }
  renderLvlPath(lvl) {
    if (this.state.levelStates[lvl - 1].state === "locked")
      return (
        <div>
          <img
            className={"level-resto-" + (lvl % 3)}
            src={this.getLvlImg(lvl)}
            alt={"modulo " + lvl}
            onClick={() => this.triggerLvlModal(lvl)}
          />
          <div className={"locked-resto-" + (lvl % 3)}>
            <img
              /*style={{
              width: 20,
              position: "absolute",
              bottom:
                this.getLvlImg(lvl) === modulo1Gray
                  ? 100
                  : this.getLvlImg(lvl) === modulo2Gray
                  ? 233
                  : 166,
              left:
                this.getLvlImg(lvl) === modulo1Gray
                  ? 363
                  : this.getLvlImg(lvl) === modulo2Gray
                  ? 398
                  : 350,
            }}*/
              width={20}
              src={locked}
              alt="bloqueado"
              onClick={() => this.triggerLvlModal(lvl)}
            />
          </div>
        </div>
      );
    else
      return (
        <img
          className={"level-resto-" + (lvl % 3)}
          src={`data:image/svg+xml;utf8,${this.getLvlImg(lvl)}`}
          alt={"modulo " + lvl}
          onClick={() => this.triggerLvlModal(lvl)}
        />
      );
  }

  renderLvlCircle(lvl) {
    return (
      <div className={"circle-resto-" + (lvl % 3)}>
        <img
          height="100%"
          // src={this.getCircle(lvl)}
          src={`data:image/svg+xml;utf8,${dinamicCircleSvgPathColor(
            lvl % 7 ? lvl % 7 : 7,
            this.state.levelStates[lvl - 1].circle_color
          )}`}
          alt={"circle " + lvl}
          onClick={() => this.triggerLvlModal(lvl)}
        ></img>
        <span
          className="circle-number"
          onClick={() => this.triggerLvlModal(lvl)}
        >
          {lvl}
        </span>
      </div>
    );
  }

  renderLvlRibbon(lvl) {
    //console.log(this.state.levelStates[this.state.lvl - 1].has_certificate);
    if (
      this.state.levelStates[lvl - 1].won &&
      this.state.levelStates[lvl - 1].has_certificate === true
    ) {
      return (
        <img
          className={"ribbon-resto-" + (lvl % 3)}
          src={ribbon}
          alt={"ribbon " + lvl}
          onClick={() => this.triggerLvlModal(lvl)}
        ></img>
      );
    } else {
      return null;
    }
  }

  renderPlayNow(lvl) {
    //console.log(this.state.levelStates[lvl - 1].has_certificate);
    if (
      /*(this.state.levelStates[lvl - 1]?.state === "available" &&
        this.state.levelStates[lvl]?.state === "locked") ||*/
      //this.state.levelStates[lvl]?.state === undefined
      this.state.levelStates[lvl - 1].state === "available" &&
      this.state.levelStates[lvl - 1].won === false
    ) {
      return (
        <img
          className={"play-resto-" + (lvl % 3)}
          src={playNow}
          alt="jugar ahora"
          onClick={() => this.triggerLvlModal(lvl)}
        ></img>
      );
    } else {
      return null;
    }
  }

  renderMonai() {
    return <img className="home-monai" src={monai} alt="monai"></img>;
  }

  renderMykure() {
    return <img className="home-mykure" src={mykure} alt="mykure"></img>;
  }

  renderChild() {
    return <img className="home-child" src={HomeChild} alt="child"></img>;
  }

  renderLvls() {
    /*Creates json element to handle render mapping*/
    let elementRender = [];
    let margineIndex = 0;
    for (let i = 1; i <= this.state.levelStates.length; i += 3) {
      elementRender.push({
        margine: margineIndex,
        lvl1: i,
        lvl2: i + 1 <= this.state.levelStates.length ? i + 1 : null,
        lvl3: i + 2 <= this.state.levelStates.length ? i + 2 : null,
      });
      margineIndex++;
    }
    // console.log(elementRender);
    return (
      <div className="levels-container-iterable">
        {elementRender.map((m) => (
          <div className={"margine-" + m.margine}>
            {this.renderLvlPath(m.lvl1)}
            {this.renderLvlCircle(m.lvl1)}
            {this.renderLvlRibbon(m.lvl1)}
            {this.renderPlayNow(m.lvl1)}
            {m.lvl2 ? this.renderLvlPath(m.lvl2) : null}
            {m.lvl2 ? this.renderLvlCircle(m.lvl2) : null}
            {m.lvl2 ? this.renderLvlRibbon(m.lvl2) : null}
            {m.lvl2 ? this.renderPlayNow(m.lvl2) : null}
            {m.lvl3 ? this.renderLvlPath(m.lvl3) : null}
            {m.lvl3 ? this.renderLvlCircle(m.lvl3) : null}
            {m.lvl3 ? this.renderLvlRibbon(m.lvl3) : null}
            {m.lvl3 ? this.renderPlayNow(m.lvl3) : null}
          </div>
        ))}
      </div>
    );
  }

  termsAndConditionsModal() {
    return (
      <div className="termsAndConditions">
        <div className="pa-title">Términos y Condiciones</div>
        <img
          height="25px"
          onClick={() => this.triggerTermsAndConditions()}
          className="pa-closeButton"
          src={closeModal}
          alt="x"
        />
        <div className="ps-scroll termsAndConditionsText">
          <h3>Información relevante</h3>
          <p>
            Es requisito necesario para la utilización de la herramienta virtual
            LaberINFO en su versión Web y móvil, que lea y acepte los siguientes
            Términos y Condiciones que a continuación se redactan. El uso de
            esta herramienta implicará que usted ha leído y aceptado los
            Términos y Condiciones de Uso en el presente documento. El usuario
            puede elegir y cambiar la clave para su acceso de administración de
            la cuenta en cualquier momento, en caso de que se haya registrado la
            Asociación Civil Con Capacidad Restringida reAcción Paraguay no
            asume la responsabilidad en caso de que entregue dicha clave a
            terceros.
          </p>
          <h3>Licencia</h3>
          <p>
            La organización reAcción Paraguay a través de su herramienta virtual
            LaberINFO en su versión Web y móvil concede una licencia Licencia
            Creative Commons (Reconocimiento - No Comercial - Sin Obra
            Derivada). Se puede copiar, distribuir y comunicar públicamente los
            datos y análisis así como el código del software. Siempre que se
            utilicen los contenidos de esta herramienta virtual (en su totalidad
            o en parte), estos deberán ir acompañados mencionando la autoría y
            la organización que la publica, junto con el nombre completo, el
            lugar y el año de publicación. Se puede utilizar la obra original
            siempre que no se haga con fines comerciales.
          </p>
          <h3>Uso no autorizado</h3>
          <p>
            No cargará, mostrará ni proporcionará de ningún otro modo en el
            Servicio ni a través de él ningún contenido que: (i) sea
            difamatorio, abusivo, amenazante, acosador, odioso, ofensivo o de
            otra manera viole cualquier ley o infrinja el derecho de un tercero
            (incluidos derechos de autor, marca registrada, privacidad,
            publicidad u otros derechos personales o de propiedad); o (ii) a
            juicio exclusivo de reAcción Paraguay, es objetable o que restringe
            o inhibe a cualquier otra persona del uso del Servicio o que puede
            exponer a reAcción Paraguay o sus usuarios a cualquier daño o
            responsabilidad de cualquier tipo.
          </p>
          <h3>Propiedad</h3>
          <p>
            Usted no puede declarar propiedad intelectual o exclusiva a ninguno
            de los resultados o información recabada expuesta en la herramienta.
            Todos los datos son propiedad intelectual de reAcción Paraguay. En
            ningún caso, reAcción Paraguay será responsable de ningún daño
            incluyendo, pero no limitado a, daños directos, indirectos,
            especiales, fortuitos o consecuentes u otras pérdidas resultantes
            del uso o de la imposibilidad de utilizar la herramienta.
          </p>
          <h3>Privacidad</h3>
          <p>
            La organización reAcción Paraguay a través de su herramienta virtual
            LaberINFO en su versión Web y Móvil garantiza que la información
            personal que usted envía cuenta con la seguridad necesaria. Los
            datos ingresados por usuario o en el caso de requerir una validación
            de los pedidos no serán entregados a terceros, salvo que deba ser
            revelada en cumplimiento a una orden judicial o requerimientos
            legales. reAcción Paraguay reserva los derechos de cambiar o de
            modificar estos términos sin previo aviso.
          </p>
        </div>
        <div className="ps-button-container">
          <button
            onClick={() => this.triggerTermsAndConditions()}
            className="ps-button"
          >
            Aceptar
          </button>
        </div>
      </div>
    );
  }

  privacityPolicyModal() {
    return (
      <div className="privacityPolicy">
        <div className="pa-title">Política de privacidad</div>
        <img
          height="25px"
          onClick={() => this.triggerPrivacityPolicy()}
          className="pa-closeButton"
          src={closeModal}
          alt="x"
        />
        <div className="ps-scroll termsAndConditionsText">
          <h3>General</h3>
          <p>
            La presente Política de Privacidad establece los términos en que la
            Asociación Civil Sin Fines de Lucro con Capacidad Restringida
            reAcción Paraguay usa y protege la información que es proporcionada
            por sus usuarios al momento de utilizar la herramienta virtual
            LaberINFO en su versiones Web y móvil. Esta organización está
            comprometida con la seguridad de los datos de sus usuarios. Cuando
            le pedimos llenar los campos de información personal con la cual
            usted pueda ser identificado, lo hacemos asegurando que sólo se
            empleará de acuerdo con los términos de este documento. Sin embargo
            esta Política de Privacidad puede cambiar con el tiempo o ser
            actualizada por lo que le recomendamos y enfatizamos revisar
            continuamente esta página para asegurarse que está de acuerdo con
            dichos cambios.
          </p>
          <h3>Información que es recogida</h3>
          <p>
            La herraminta virtual LaberINFO en su versión Web y móvil podrá
            recoger información personal por ejemplo: Nombre, sexo, edad, foto
            de perfil, su dirección de correo electrónico, credenciales de
            acceso, entre otros. Así mismo cuando sea necesario podrá ser
            requerida información específica para procesar algún pedido o
            realizar alguna actualización de la herramienta.
          </p>
          <h3>Uso de la información recogida</h3>
          <p>
            LaberINFO en su versión Web y móvil emplea la información con el fin
            de mantener un registro de usuarios y uso de la herramienta. La
            organización reAcción Paraguay está altamente comprometida a cumplir
            con el compromiso de mantener su información segura y anónima a
            agentes externos a la misma.
          </p>
          <h3>Seguridad de la Información recogida</h3>
          <p>
            reAcción Paraguay ha implementado salvaguardas administrativas y
            técnicas que considera apropiadas para proteger la confidencialidad,
            integridad y disponibilidad de sus datos personales, foto de perfil,
            credenciales de acceso e información demográfica entre otros. Sin
            embargo, dados los recursos suficientes, un atacante determinado
            podría vencer esas salvaguardas y, como resultado, puede obtener
            acceso a los datos que buscamos proteger.
          </p>
          <h3>Control de su información personal</h3>
          <p>
            En cualquier momento usted puede restringir la recopilación o el uso
            de la información personal que es proporcionada a la herramienta
            virtual LaberINFO. La organización reAcción Paraguay se reserva el
            derecho de cambiar los términos de la presente Política de
            Privacidad en cualquier momento.
          </p>
        </div>
        <div className="ps-button-container">
          <button
            onClick={() => this.triggerPrivacityPolicy()}
            className="ps-button"
          >
            Aceptar
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
        }}
      >
        <div className="Home">
          {/*!this.state.loading ? this.renderMonai() : null*/}
          {/*!this.state.loading ? this.renderMykure() : null*/}
          {!this.state.loading && this.renderChild()}
          {!this.state.loading ? (
            this.renderLvls()
          ) : (
            <div className="spinner1 color-default">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
          <div
            className="share-button"
            onClick={() => this.triggerShareModal()}
          >
            <img src={shareIcon} alt="shareModal" />
          </div>
          <div
            className="how-to-play-button"
            onClick={() => this.triggerModal()}
          >
            <img src={howToPlay} alt="?" />
          </div>
          <div
            className="trigger-sound-button"
            onClick={() => this.triggerSound()}
          >
            <img src={this.state.sound ? soundOn : soundOff} alt="?" />
          </div>
          {this.state.errorMessage && this.errorModal()}
          {this.modal()}
          {this.shareModal()}
          {this.state.modal ? this.overlay() : null}
          {this.state.levelStates.length > 0 ? this.openLevel() : null}
          {this.state.lvlModal ? this.overlayLvl() : null}
          {this.state.termsAndConditions
            ? this.termsAndConditionsModal()
            : null}
          {this.state.termsAndConditions ? this.overlayTC() : null}
          {this.state.privacityPolicy ? this.overlayPP() : null}
          {this.state.privacityPolicy ? this.privacityPolicyModal() : null}
          {this.state.requiredData ? this.requiredData() : null}
        </div>
      </div>
    );
  }
}
export default Home;
