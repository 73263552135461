import API from '../api';
import AuthService from './auth';

class LevelsService {

    getLevels() {
        return API.get('v1/levels/', { headers: AuthService.headers });
    }

    newRound(lvlID) {
        return API.post('v1/rounds/', { level: lvlID }, { headers: AuthService.headers });
    }

    attempt(payload) {
        return API.post('v1/attempts/', payload, { headers: AuthService.headers });
    }

    getCertificates(id) {
        return API.get('v1/certificates/' + id, { headers: AuthService.headers })
    }
}

export default new LevelsService();
